import classes from './Footer.module.css';
import { Container } from 'react-bootstrap';
import BeansLogoType from '../BeanLogoType';
import Link from '../Link';

const privacyLink = (
  <Link text='Privacy Policy' url='/privacy' leavesPage={false} />
);
const termsLink = (
  <Link text='Terms of Service' url='/terms' leavesPage={false} />
);
const operatingLink = (
  <Link text='Operating Agreement' url='/operating' leavesPage={false} />
);

const Footer = () => {
  return (
    <div className={classes.wrapper}>
      <Container className={classes.container} fluid>
        <div className={classes.linkWrapper}>
          <div className='mx-2'>{privacyLink}</div>
          <div className='mx-2'>{termsLink}</div>
          <div className='mx-2'>{operatingLink}</div>
        </div>
        <footer className={classes.footerSignature}>
          <BeansLogoType
            colorStart={'var(--beans-color-primary)'}
            colorStop={'var(--beans-color-secondary)'}
            key={1}
          />
        </footer>
      </Container>
    </div>
  );
};
export default Footer;
