/**
 * Converts an svg string to a base64 encoded png
 * @param svgString string representation of svg
 * @param newWidth width to scale the png to
 * @param newHeight height to scale the png to
 */
export const svg2png = (
  svgString: string,
  newWidth: number = 320,
  newHeight: number = 320
): Promise<string | null> => {
  return new Promise((resolve) => {
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = 320;
    canvas.height = 320;
    var img = new Image();

    img.onload = () => {
      if (!ctx) return;
      ctx.scale(newWidth / 500, newHeight / 500);
      ctx.drawImage(img, 0, 0);
      const png = canvas.toDataURL('image/png');
      try {
        resolve(png);
      } catch (e) {
        console.log('error converting svg to png: ', e);
        resolve(null);
      }
    };
    img.src = svgString;
  });
};
