import classes from './ProposalStatus.module.css';
import { ProposalState } from '../../wrappers/beansDao';
import React from 'react';
import clsx from 'clsx';

const statusVariant = (status: ProposalState | undefined) => {
  switch (status) {
    case ProposalState.PENDING:
    case ProposalState.ACTIVE:
      return classes.primary;
    case ProposalState.SUCCEEDED:
    case ProposalState.EXECUTED:
      return classes.success;
    case ProposalState.DEFEATED:
    case ProposalState.VETOED:
      return classes.danger;
    case ProposalState.QUEUED:
    case ProposalState.CANCELED:
    case ProposalState.EXPIRED:
    default:
      return classes.secondary;
  }
};

const statusText = (status: ProposalState | undefined) => {
  switch (status) {
    case ProposalState.PENDING:
      return 'PENDING';
    case ProposalState.ACTIVE:
      return 'ACTIVE';
    case ProposalState.SUCCEEDED:
      return 'SUCCEEDED';
    case ProposalState.EXECUTED:
      return 'EXECUTED';
    case ProposalState.DEFEATED:
      return 'DEFEATED';
    case ProposalState.QUEUED:
      return 'Queued';
    case ProposalState.CANCELED:
      return 'CANCELED';
    case ProposalState.VETOED:
      return 'VETOED';
    case ProposalState.EXPIRED:
      return 'EXPIRED';
    default:
      return 'UNDETERMINED';
  }
};

interface ProposalStateProps {
  status?: ProposalState;
  className?: string;
}

const ProposalStatus: React.FC<ProposalStateProps> = (props) => {
  const { status, className } = props;
  return (
    <div
      className={clsx(statusVariant(status), classes.proposalStatus, className)}
    >
      {statusText(status)}
    </div>
  );
};

export default ProposalStatus;
