import React from 'react';

// function BeanLogoMark({colorStart= "#A41FAF", colorStop = "#A41FAF"}) {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 900 774.67">
//       <defs>
//         <linearGradient
//           id="linear-gradient"
//           x2="900"
//           y1="387.33"
//           y2="387.33"
//           gradientUnits="userSpaceOnUse"
//           gradientTransform="rotate(52.5)"
//         >
//           <stop offset="0" stopColor={colorStart}></stop>
//           {/* <stop offset="0.5" stopColor="#FF0000"></stop> */}
//           <stop offset="1" stopColor={colorStop}></stop>
//         </linearGradient>
//       </defs>
//       <g>
//         <path
//           fill="url(#linear-gradient)"
//           d="M689.15.13C802.53.13 900 79.48 900 193.77A193.26 193.26 0 01706.37 387.4C812.86 387.4 900 473.81 900 581c0 114.32-97.47 193.63-210.85 193.63H193.63C70.74 774.67 0 709.14 0 581V193.72C0 65.62 70.74 0 193.63 0c106.5 0 389.6.13 495.52.13z"
//         ></path>
//       </g>
//     </svg>
//   );
// }

function BeanLogoMark({ colorStart = '#A41FAF', colorStop = '#A41FAF' }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1243.46 1005.66'>
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='96.63'
          y1='407.5'
          x2='957.84'
          y2='407.5'
          gradientUnits='userSpaceOnUse'
          gradientTransform='rotate(52.5)'
        >
          <stop offset='0' stopColor={colorStart}></stop>
          <stop offset='1' stopColor={colorStop}></stop>
        </linearGradient>
      </defs>
      <g id='BEANSLOGOB_DROPSHADOW'>
        <path
          fill='#fff'
          d='m1200.99,279.57c-42.83-44.63-203.56-209.59-203.56-209.59v.08C953.85,24.71,889.25,0,812.67,0h-378.74c-71.51,0-138.5,22.92-193.73,66.3-49.02,38.49-86.74,91.3-109.11,152.74L17.98,529.81c-28.32,77.82-23.05,150.49,14.87,204.63,3.44,4.9,7.67,10.36,12.78,16.04l186,188.92c25.81,29.48,75.63,66.25,163.61,66.25h398.96c76.14,0,145.79-19.18,201.43-55.46,60.74-39.61,103.71-98.38,124.27-169.95,14.51-50.52,12.27-103.73-5.21-151.08,57.92-38.27,98.68-93.09,118.14-159.04,19.6-66.45,13.89-142.93-31.83-190.56Z'
        />
      </g>
      <g id='BEANSLOGOB_COLOR'>
        <path
          fill='url(#linear-gradient)'
          d='m952.51,252.11c-28.86,97.86-123.45,144.33-211.94,144.33,67.43,0,123.38,81,98.83,166.44-29.06,101.17-117.74,155.39-232.73,155.39H207.7c-97.32,0-132.02-64.2-98.83-155.39,33.19-91.18,113.11-310.77,113.11-310.77h0c31.11-85.46,106.11-155.39,211.94-155.39h378.74c128.75,0,159.19,89.78,139.83,155.39Z'
        />
      </g>
    </svg>
  );
}

export default BeanLogoMark;
