export const grey = '#000000';
export const beige = '#e1d7d5';
export const allColors: string[] = GetTwoRandom([
  ['#ffb141', '#ff9829', '#ff6c58', '#f95353'],
  ['#fff952', '#caff4a', '#66ff7c', '#5eff52'],
  ['#52ffa3', '#53f5d8', '#52e0ff', '#609cff'],
  ['#fc6694', '#f763e9', '#d87cff', '#a270ff'],
]);

function GetTwoRandom(MultiDimArray: string[][]): string[] {
  for (let i = MultiDimArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [MultiDimArray[i], MultiDimArray[j]] = [MultiDimArray[j], MultiDimArray[i]];
  }
  var array1 = ShuffleArray(MultiDimArray[0]);
  var array2 = ShuffleArray(MultiDimArray[1]);
  return [array1[0], array2[1]];
}

function ShuffleArray(array: string[]): string[] {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
