import { Col, Image } from 'react-bootstrap';
import Section from '../../layout/Section';
import _404 from '../../assets/404.svg';

const NotFoundPage = () => {
  return (
    <Section fullWidth={true}>
      <Col lg={12}>
        <Image src={_404} fluid />
      </Col>
    </Section>
  );
};
export default NotFoundPage;
