import { Container, Row, Col, Table } from 'react-bootstrap';
import Section from '../../layout/Section';
import classes from './Privacy.module.css';
import React from 'react';

const Privacy: React.FC = () => {
  return (
    <>
      <Container fluid='lg' className='privacyContainer'>
        <Row className='justify-content-center'>
          <Col lg={8} className={classes.headerRow}>
            <h1>PRIVACY POLICY</h1>
            <p>Last Updated: January 15, 2024</p>
          </Col>
        </Row>
        <Section fullWidth={false} className={classes.sectionClass}>
          <Col lg={{ span: 10, offset: 1 }}>
            <div className={classes.headerWrapper}>
              <p className={classes.paragraphText}>
                This privacy policy (“<b>Privacy Policy</b>”) describes how
                Beans Foundation DAO, LLC (“
                <b>our</b>”, “<b>us</b>” “<b>we</b>” or “<b>Beans</b>”)
                collects, uses, shares, and store personal information of
                clients and users of our website beans.wtf and related web
                application, products and services (collectively, the “
                <b>Services</b>”) on or in which it is posted, linked, or
                referenced.
              </p>
              <p
                className={classes.paragraphText}
                style={{ paddingBottom: '1rem' }}
              >
                By using the Services, you accept the terms of this Privacy
                Policy and our Terms of Service, and consent to our collection,
                use, disclosure, and retention of your information as described
                in this Privacy Policy. Any defined terms in the Terms of
                Service apply herein. If you have not done so already, please
                also review our Terms of Service. The Terms of Service contain
                provisions that limit our liability to you and require you to
                resolve any dispute with us on an individual basis and not as
                part of any class or representative action. IF YOU DO NOT AGREE
                WITH ANY PART OF THIS PRIVACY POLICY OR OUR TERMS OF SERVICE,
                THEN PLEASE DO NOT USE ANY OF THE SERVICES.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>What We Collect</h1>
              </p>
              <p className={classes.paragraphText}>
                <b>Information You Give Us.</b> Information we collect from you
                may include:
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  Contact information, such as your email address or social
                  media information (including Discord);{' '}
                </li>
                <li>
                  Profile information, such as your username, password,
                  preferences, feedback and survey responses, blockchain public
                  key;
                </li>
                <li>
                  Feedback and correspondence, such as information you provide
                  in your responses to surveys;
                </li>
                <li>
                  Transaction information, such as details about purchases you
                  make through the Services and preferences with respect to
                  blockchain transaction labeling;
                </li>
                <li>
                  Marketing information, such as your preferences for receiving
                  marketing communications and details about how you engage with
                  them;{' '}
                </li>
                <li>
                  Information about plugins you might be using, such as those
                  related to the management of cryptocurrency assets and any
                  information provided by them; and
                </li>
                <li>
                  Other information not specifically listed here, which we will
                  use as described in this Privacy Policy or as otherwise
                  disclosed at the time of collection.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                <b>Third-Party Sources. </b>We may receive personal information
                about you from third-party sources. For example, third-party
                wallet providers provide us with your blockchain public key and
                certain information you choose to share with those wallet
                providers. We may add this to the data we have already collected
                from or about you through our Services. Public blockchain
                provide transparency into transactions and we are not
                responsible for preventing or managing information broadcast on
                a blockchain.
              </p>
              <p className={classes.paragraphText}>
                <b>Automatic Data Collection. </b> We may automatically record
                certain information about how you use our Services (we refer to
                this information as “<b>Log Data</b>”). Log Data may include
                information such as a user’s Internet Protocol (IP) address,
                device and browser type, operating systems, the pages or
                features available through our Services to which a user browsed
                and the time spent on those pages or features, the frequency
                with which the Services are used by a user, search terms, the
                links made available through the Services that a user clicked on
                or used, and other statistics. We use this information to
                improve and enhance the Services by expanding its features and
                functionality and tailoring it to our users’ needs and
                preferences.
              </p>
              <p className={classes.paragraphText}>
                We may use cookies, local storage or similar technologies to
                analyze trends, administer the pages made available through the
                Services, track users’ movements around the pages made available
                through the Services, and to gather demographic information
                about our user base as a whole. Users can control the use of
                cookies and local storage at the individual browser level. For
                more information, please see our Cookies Policy.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  Use of Personal Information
                </h1>
              </p>
              <p className={classes.paragraphText}>
                We use the collected data for various purposes or as otherwise
                described at the time of collection:
              </p>
              <p className={classes.paragraphText}>
                <b>Service Delivery. </b> We use your personal information to:
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  provide, operate and improve the Services and our business;
                </li>
                <li>
                  facilitate the execution of transactions authorized by you;{' '}
                </li>
                <li>
                  communicate with you about the Services, including by sending
                  announcements, updates, security alerts, and support and
                  administrative messages;{' '}
                </li>
                <li>
                  allow you to send messages to another user through the
                  Services if you choose to do so; and
                </li>
                <li>
                  provide support for the Services, and respond to your
                  requests, questions and feedback.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                <b>Research and Development. </b> We may use your personal
                information for research and development purposes, including to
                analyze and improve the Services and our business. As part of
                these activities, we may create aggregated, de-identified or
                other anonymous data from personal information we collect. We
                make personal information into anonymous data by removing
                information that makes the data personally identifiable to you.
              </p>
              <p className={classes.paragraphText}>
                <b>Compliance and Protection. </b> We may use your personal
                information to:
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  comply with applicable laws, lawful requests, and legal
                  processes, such as to respond to subpoenas or requests from
                  government authorities;
                </li>
                <li>
                  protect our, your or others’ rights, privacy, safety or
                  property (including by making and defending legal claims);
                </li>
                <li>
                  audit our internal processes for compliance with legal and
                  contractual requirements and internal policies;
                </li>
                <li>
                  enforce the Terms of Service that govern the Services; and
                </li>
                <li>
                  prevent, identify, investigate and deter fraudulent, harmful,
                  unauthorized, unethical or illegal activity, including
                  cyberattacks and identity theft.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                <b>With Your Consent. </b> We may use, share or collect your
                personal information with your consent, such as when required by
                law.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  Disclosures of Your Data
                </h1>
              </p>
              <p className={classes.paragraphText}>
                We may share your data with the following parties and as
                otherwise described in this Privacy Policy or at the time of
                collection.
              </p>
              <p className={classes.paragraphText}>
                <b>Affiliates.</b> We may disclose your personal information to
                our subsidiaries and corporate affiliates (i.e., our family of
                companies that are related by common ownership or control) for
                purposes consistent with this Privacy Policy.
              </p>
              <p className={classes.paragraphText}>
                <b>Authorities and Others.</b> Law enforcement, government
                authorities, and private parties, as we believe in good faith to
                be necessary or appropriate for the “Compliance and Protection”
                purposes described above.
              </p>
              <p className={classes.paragraphText}>
                <b>Blockchain.</b> Given that the blockchain is a public record,
                the transaction records associated with any transaction you make
                using the Services will be publicly available on the blockchain.
              </p>
              <p className={classes.paragraphText}>
                <b>Business Transfers.</b> We may share personal information
                when we do a business deal, or negotiate a business deal,
                involving the sale or transfer of all or a part of our business
                or assets. These deals can include any merger, financing,
                acquisition, reorganization, divestiture, or in the event of
                bankruptcy or dissolution.
              </p>
              <p className={classes.paragraphText}>
                <b>General Public.</b> While using messaging services, chat,
                profile pages, marketplace, and other services to which you are
                able to post information and content. Please note that any
                information you post or disclose through these Services will
                become public and may be available to other users and the
                general public.
              </p>
              <p className={classes.paragraphText}>
                <b>Professional Advisors and Service Providers.</b> We may share
                information with those who need it to work for us. These
                recipients may include third-party companies and individuals to
                administer and provide the Services on our behalf, as well as
                lawyers, bankers, auditors, and insurers.
              </p>
              <p className={classes.paragraphText}>
                <b>
                  Third-Party Service Providers for Transaction Facilitation.
                </b>{' '}
                In order to provide seamless automatic transactions, we may
                share your data with third-party service providers who
                facilitate these transactions. This may include payment
                processors, NFT marketplaces, or other service providers. These
                third-party service providers have their own privacy policies
                and security measures. We recommend reviewing their policies as
                we are not responsible for their practices.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>Data Retention</h1>
              </p>
              <p className={classes.paragraphText}>
                We retain information we collect as long as it is necessary and
                relevant to fulfill the purposes outlined in this Privacy
                Policy. In addition, we retain personal information to comply
                with applicable law where required, prevent fraud, resolve
                disputes, troubleshoot problems, assist with any investigation,
                enforce our Terms of Service, and other actions permitted by
                law. To determine the appropriate retention period for personal
                information, we consider the amount, nature, and sensitivity of
                the personal information, the potential risk of harm from
                unauthorized use or disclosure of your personal information, the
                purposes for which we process your personal information and
                whether we can achieve those purposes through other means, and
                the applicable legal requirements.
              </p>
              <p className={classes.paragraphText}>
                In some circumstances we may anonymize your personal information
                (so that it can no longer be associated with you) in which case
                we may use this information indefinitely without further notice
                to you.
              </p>
              <p className={classes.paragraphText}>
                We employ robust security measures designed to protect the
                security of all information submitted through the Services.
                However, the security of information transmitted through the
                Internet can never be guaranteed. We are not responsible for any
                interception or interruption of any communications through the
                Internet or for changes to or losses of data. Users of the
                Services are responsible for maintaining the security of any
                password, blockchain private key, biometrics, user ID or other
                form of authentication involved in obtaining access to password
                protected or secure areas of any of our digital services. In
                order to protect you and your data, we may suspend your use of
                any of the Services, without notice, pending an investigation,
                if any breach of security is suspected.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>Your Rights</h1>
              </p>
              <p className={classes.paragraphText}>
                You have the following rights with respect to your personal
                information:
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  You have the right to ask us for an overview of your personal
                  information that we process;
                </li>
                <li>
                  You have the right to ask us to transfer your personal
                  information directly to you or to another entity. This applies
                  to personal information that we process by automated means and
                  with your consent or on the basis of a contract with you. We
                  will transfer your personal information where it is
                  technically feasible;
                </li>
                <li>
                  You may ask us to delete your personal information if we no
                  longer need it for its original purpose, if you object to us
                  processing your personal information for our own legitimate
                  interests or for personalized commercial messages, if you
                  withdraw your consent for processing your personal
                  information, if we unlawfully process your personal
                  information, or if a law requires us to erase your personal
                  information;
                </li>
                <li>
                  You can object to us using your personal information for our
                  own legitimate interests. We will consider your objection and
                  whether processing your personal information has any undue
                  impact on you that requires us to stop doing so. You cannot
                  object to us processing your personal information if we are
                  legally required to do so (for example if we are obliged to
                  fulfill a contract with you);
                </li>
                <li>
                  If your personal information is incorrect, you have the right
                  to ask us to rectify it. If we shared such data about you with
                  a third party in compliance with this Policy, it is our
                  obligation to notify this change to the third party;
                </li>
                <li>
                  You have the right to ask us to restrict using your personal
                  information if in your opinion the information is inaccurate,
                  if we are processing the data unlawfully, or if you have
                  objected to us processing your personal information for our
                  own legitimate interests; and
                </li>
                <li>
                  Should you not be satisfied with the way we have responded to
                  your concerns, you have the right to submit a complaint to us
                  using the contact information further up in this Policy. If
                  you are still unhappy with our reaction to your complaint, you
                  can also contact the data protection authority in your
                  country.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                Please note that deletion of your personal information may make
                it impossible for you to use the websites or the Services.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>Cookies Policy</h1>
              </p>
              <p className={classes.paragraphText}>
                <b>What Are Cookies?</b> Cookies are small files that are stored
                on your device when you visit a website. These files often
                contain information that can be read by the web server that
                issued the cookie to you. The purpose of the cookie is to tell
                the server that a visitor has returned to a specific webpage or
                to pass on information about browsing patterns and preferences.
              </p>
              <p className={classes.paragraphText}>
                <b>How We Use Cookies.</b> We use cookies to enhance your
                experience while using our Services, such as:
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  Remembering User Preferences: To remember settings and
                  preferences, so you don't have to set them up every time you
                  visit.
                </li>
                <li>
                  Analytical Purposes: To gather statistical information to
                  understand how users interact with our platform, which helps
                  us improve our Services.
                </li>
                <li>
                  Authentication: To identify registered users and ensure they
                  can access member-only resources.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                <b>Types of Cookies We Use.</b>
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  Session Cookies: These are temporary and expire when you close
                  your browser.
                </li>
                <li>
                  Persistent Cookies: These stay on your device until they
                  expire or you delete them, allowing us to remember your
                  preferences for future visits.
                </li>
                <li>
                  Third-Party Cookies: We use third-party services like Google
                  Analytics, which set their own cookies to provide us with
                  analytics services or to display ads.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                <b>Third-Party Service Providers and Cookies. </b> We may also
                share limited information with third-party service providers who
                help us analyze our user base and improve the functionality of
                our Services. These third parties may also use cookies to
                collect information directly from you. While we strive to work
                only with providers that offer the highest level of user privacy
                and security, we cannot control or be held liable for third
                parties’ privacy practices.
              </p>
              <p className={classes.paragraphText}>
                <b>How to Manage Cookies.</b> You have the ability to manage
                cookies through your browser settings. However, please note that
                by deleting or disabling cookies, some features of our Services
                may not work as intended, affecting your user experience.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  Third Party Payment Service
                </h1>
              </p>
              <p className={classes.paragraphText}>
                The Services may provide functionality allowing you to make
                payments using third-party payment services with which you have
                connected your wallet. When you use such a service to make a
                payment to us, your personal information will be collected by
                such third party and not by us and will be subject to the third
                party’s privacy policy, rather than this Privacy Policy. We have
                no control over, and are not responsible for, this third party’s
                collection, use, and disclosure of your personal information.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>Minors</h1>
              </p>
              <p className={classes.paragraphText}>
                We do not intentionally gather personal information from
                visitors who are under the age of 18. Our Terms of Service
                require all users to be over the age of 18. If you are not at
                least 18 years of age or have not reached the age of majority in
                your jurisdiction, you are prohibited from using the Service,
                and you must cease all activities and usage of the Service
                immediately. Creating an account or otherwise utilizing the
                Service represents your statement and confirmation that you meet
                this age requirement.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  International Data Transfers
                </h1>
              </p>
              <p className={classes.paragraphText}>
                We have offices outside of the EU and your personal information
                may be transferred to, and processed in or from, the USA or
                other locations outside of your state, province, country or
                other governmental jurisdiction where privacy laws may not be as
                protective as those in your jurisdiction.
              </p>
              <p className={classes.paragraphText}>
                EU users should read the important information provided below
                about the transfer of personal information outside of the
                European Economic Area.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  Changes to this Privacy Policy
                </h1>
              </p>
              <p className={classes.paragraphText}>
                We may change this privacy at any time. We encourage you to
                periodically review this page for the latest information about
                our privacy practices. If we make any changes, we will change
                the Last Updated date above.
              </p>
              <p className={classes.paragraphText}>
                Any modifications to this Privacy Policy will be effective upon
                our posting of the new terms and/or upon the implementation of
                the changes to the Services (or as otherwise indicated at the
                time of posting). In all cases, your continued use of the
                Services after the posting of any modified Privacy Policy
                indicates your acceptance of the terms of the modified Privacy
                Policy.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>Contact us</h1>
              </p>
              <p className={classes.paragraphText}>
                If you have any question or comment about this Privacy Policy,
                please do not hesitate to contact us at beansdaowtf@gmail.com.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  Notice to California Residents
                </h1>
              </p>
              <p className={classes.paragraphText}>
                Under California Civil Code Section 1789.3, California users are
                entitled to the following consumer rights notice: California
                residents may reach the Complaint Assistance Unit of the
                Division of Consumer Services of the California Department of
                Consumer Affairs by mail at 1625 North Market Blvd., Sacramento,
                CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.
              </p>
              <p className={classes.paragraphText}>
                This section provides additional details about the personal
                information we collect about California consumers and the rights
                afforded to them under the California Consumer Privacy Act or
                “CCPA”.
              </p>
              <p className={classes.paragraphText}>
                For more details about the personal information we collect from
                you, please see the “What we Collect” section above. We collect
                this information for the business and commercial purposes
                described in the “Use of Personal Information” section above. We
                share this information with the categories of third parties
                described in the “Disclosures of Your Data” section above. We do
                not sell (as such term is defined in the CCPA) the personal
                information we collect (and will not sell it without providing a
                right to opt out). Please refer to our Cookies Policy for more
                information regarding the types of third-party cookies, if any,
                that we use.
              </p>
              <p className={classes.paragraphText}>
                Subject to certain limitations, the CCPA provides California
                consumers the right to request to know more details about the
                categories or specific pieces of personal information we collect
                (including how we use and disclose this information), to delete
                their personal information, to opt out of any “sales” that may
                be occurring, and to not be discriminated against for exercising
                these rights.
              </p>
              <p className={classes.paragraphText}>
                California consumers may make a request pursuant to their rights
                under the CCPA by contacting us at beansdaowtf@gmail.com Please
                note that you must verify your identity and request before
                further action is taken. As a part of this process, government
                identification may be required. Consistent with California law,
                you may designate an authorized agent to make a request on your
                behalf. In order to designate an authorized agent to make a
                request on your behalf, you must provide a valid power of
                attorney, the requesters’ valid government issued
                identification, and the authorized agent’s valid government
                issued identification.
              </p>
              <p className={classes.paragraphText}>
                <h1 className={classes.paragraphHeader}>
                  Notice to European Users
                </h1>
              </p>
              <p className={classes.paragraphText}>
                The information provided in this notice applies only to
                individuals in the European Economic Area, Switzerland, and the
                United Kingdom (collectively, “EU”) and “personal information”
                as used in this Privacy Policy is equivalent to “personal data”
                as defined in the European Union General Data Protection
                Regulation (GDPR).
              </p>
              <p className={classes.paragraphText}>
                Sensitive Data. Some of the information you provide us may
                constitute sensitive data as defined in the GDPR (also referred
                to as special categories of personal data).
              </p>
              <p className={classes.paragraphText}>
                Legal Basis for Processing. We only use your personal
                information as permitted by law. We are required to inform you
                of the legal basis of our processing of your personal
                information, which are described in the table below. If you have
                any questions about the legal basis under which we process your
                personal information, contact us at beansdaowtf@gmail.com.{' '}
              </p>

              <p className={classes.paragraphText}>
                <Table bordered size='sm' variant='dark'>
                  <thead>
                    <tr>
                      <th>
                        <b>Processing Purpose</b>
                      </th>
                      <th>
                        <b>Legal Basis</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>To provide our Service</td>
                      <td>
                        Our processing of your personal information is necessary
                        to perform the contract governing our provision of the
                        Services or to take steps that you request prior to
                        signing up for the Services.
                      </td>
                    </tr>
                    <tr>
                      <td>
                        - To communicate with you
                        <br></br>- To optimize our Services<br></br>- For
                        compliance, fraud prevention, and safety<br></br>- For
                        research and development
                      </td>
                      <td>
                        These processing activities constitute our legitimate
                        interests. We make sure we consider and balance any
                        potential impacts on you (both positive and negative)
                        and your rights before we process your personal
                        information for our legitimate interests. We do not use
                        your personal information for activities where our
                        interests are overridden by any adverse impact on you
                        (unless we have your consent or are otherwise required
                        or permitted to by law).
                      </td>
                    </tr>
                    <tr>
                      <td>To comply with law</td>
                      <td>
                        We use your personal information to comply with
                        applicable laws and our legal obligations.
                      </td>
                    </tr>
                    <tr>
                      <td>With your consent</td>
                      <td>
                        Where our use of your personal information is based upon
                        your consent, you have the right to withdraw it anytime
                        by contacting us at beansdaowtf@gmail.com.
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </p>

              <p className={classes.paragraphText}>
                <b>Use for New Purposes. </b> We may use your personal
                information for reasons not described in this Privacy Policy,
                where we are permitted by law to do so and where the reason is
                compatible with the purpose for which we collected it. If we
                need to use your personal information for a related purpose, we
                will notify you and explain the applicable legal basis for that
                use. If we have relied upon your consent for a particular use of
                your personal information, we will seek your consent for any
                unrelated purpose.
              </p>
              <p className={classes.paragraphText}>
                <b>Your Rights. </b> Under the GDPR, you have certain rights
                regarding your personal information. You may ask us to take the
                following actions in relation to your personal information that
                we hold:
              </p>
              <ul className={classes.paragraphList}>
                <li>
                  <b>Opt-out. </b> Stop sending you direct marketing
                  communications which you have previously consented to receive.
                  We may continue to send you service-related and other
                  non-marketing communications.
                </li>
                <li>
                  <b>Access. </b> Provide you with information about our
                  processing of your personal information and give you access to
                  your personal information. Notwithstanding the foregoing, we
                  will not provide private key information to anyone outside of
                  the scope of processing automatic transactions, even you.{' '}
                </li>
                <li>
                  <b>Correct. </b> Update or correct inaccuracies in your
                  personal information.
                </li>
                <li>
                  <b>Delete. </b> Delete your personal information.
                </li>
                <li>
                  <b>Transfer. </b> Transfer a machine-readable copy of your
                  personal information to you or a third party of your choice.
                </li>
                <li>
                  <b>Restrict. </b> Restrict the processing of your personal
                  information.
                </li>
                <li>
                  <b>Object. </b> Object to our reliance on our legitimate
                  interests as the basis of our processing of your personal
                  information that impacts your rights.
                </li>
              </ul>
              <p className={classes.paragraphText}>
                You can submit these requests by email to beansdaowtf@gmail.com.
                We may request specific information from you to help us confirm
                your identity and process your request. Applicable law may
                require or permit us to decline your request. If we decline your
                request, we will tell you why, subject to legal restrictions. If
                you would like to submit a complaint about our use of your
                personal information or response to your requests regarding your
                personal information, you may contact us at
                beansdaowtf@gmail.com or submit a complaint to the data
                protection regulator in your jurisdiction.
              </p>
              <p className={classes.paragraphText}>
                <b>Cross-Border Data Transfer. </b> We process personal data in
                the USA and data protection laws may be different from those in
                your country of residence. You consent to the transfer of your
                information, including personal information, to the USA as set
                forth in this Privacy Policy by using our Services.
              </p>
            </div>
          </Col>
        </Section>
      </Container>
    </>
  );
};
export default Privacy;
