import bidBtnClasses from './BidHistoryBtn.module.css';

const BidHistoryBtn: React.FC<{ onClick: () => void }> = (props) => {
  const { onClick } = props;
  return (
    <div className={bidBtnClasses.bidHistoryWrapperCool} onClick={onClick}>
      <div className={bidBtnClasses.bidHistory}>VIEW ALL BIDS</div>
    </div>
  );
};
export default BidHistoryBtn;
