import classes from './Banner.module.css';
import { Row, Container } from 'react-bootstrap';

import clsx from 'clsx';
const Banner = () => {
  return (
    <div className={clsx(classes.bannerSection, classes.coloranimator)}>
      <Container>
        <Row className={clsx(classes.wrapper)}>
          <div>
            <h1>
              BEANSDAO
              <br />
              SUPPORTS
              <br />
              THE ARTS
            </h1>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Banner;
