import { Button, Form } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import classes from './VerifySignature.module.css';
import { useState } from 'react';
import clsx from 'clsx';
import { Hex, getAddress } from 'viem';
import { useVerifyMessage } from 'wagmi';

const VerifySignature: React.FC<{}> = (props) => {
  const activeAccount = useAppSelector((state) => state.account.activeAccount);
  const [messageToVerify, setMessageToVerify] = useState('');
  const [signature, setSignature] = useState('');
  const [signerAddress, setSignerAddress] = useState('');
  const [validSignature, setValidSignature] = useState<boolean | undefined>(
    undefined
  );

  const { data: verificationSuccessful } = useVerifyMessage({
    address: signerAddress.includes('0x') ? getAddress(signerAddress) : '0x',
    message: messageToVerify,
    signature: signature as Hex,
    query: {
      enabled:
        signerAddress.includes('0x') &&
        messageToVerify !== '' &&
        signature.includes('0x'),
    },
  });

  const attemptToVerify = () => {
    setValidSignature(verificationSuccessful ?? false);
  };

  const verificationResult = () => {
    switch (validSignature) {
      case undefined:
        break;
      case true:
        return (
          <div className={clsx(classes.verifyMessage, classes.verifySuccess)}>
            VERIFICATION SUCCESS
          </div>
        );
      case false:
        return (
          <div className={clsx(classes.verifyMessage, classes.verifyFailure)}>
            VERIFICATION FAILED
          </div>
        );
    }
  };

  return (
    <div className={classes.section}>
      <h2>VERIFY SIGNED MESSAGE</h2>
      {verificationResult()}
      <Form.Group className='mb-3' controlId='verifyForm.ControlAddress'>
        <Form.Label>ADDRESS OF SIGNER</Form.Label>
        <Form.Control
          className={classes.verifyInput}
          onChange={(e) => setSignerAddress(e.target.value)}
        />
      </Form.Group>
      <Form.Group className='mb-3' controlId='verifyForm.Signature'>
        <Form.Label>SIGNATURE</Form.Label>
        <Form.Control
          className={classes.verifyInput}
          onChange={(e) => setSignature(e.target.value)}
        />
      </Form.Group>
      <Form.Group
        className='mb-3'
        controlId='verifyForm.ControlTextAreaMessage'
      >
        <Form.Label>SIGNED MESSAGE</Form.Label>
        <Form.Control
          className={classes.verifyInput}
          as='textarea'
          rows={5}
          onChange={(e) => setMessageToVerify(e.target.value)}
        />
      </Form.Group>
      {activeAccount ? (
        <Button
          className={classes.verifyButton}
          onClick={() => attemptToVerify()}
        >
          VERIFY
        </Button>
      ) : (
        <div>CONNECT WALLET TO VERIFY MESSAGE</div>
      )}
    </div>
  );
};

export default VerifySignature;
