import { useAppSelector } from '../../hooks';
import classes from './NavBar.module.css';
import clsx from 'clsx';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { CHAIN_CONFIG } from '../../config';
import { buildEtherscanHoldingsLink } from '../../utils/etherscan';
import { ExternalURL, externalURL } from '../../utils/externalURL';
import NavBarButton, { NavBarButtonStyle } from '../NavBarButton';
import NavBarTreasury from '../NavBarTreasury';
import twitterLogo from '../../assets/icons/Twitter.svg';
import discordLogo from '../../assets/icons/Discord.svg';
import farcasterLogo from '../../assets/icons/Farcaster.svg';
import openseaLogo from '../../assets/icons/Opensea.svg';
import BeanLogoMark from '../BeanLogoMark';
import ConnectWalletButton from '../ConnectWalletButton';
import { formatEther, getAddress } from 'viem';
import { useBalance } from 'wagmi';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const stateBgColor = useAppSelector(
    (state) => state.application.stateBackgroundColor
  );
  const isCool = useAppSelector((state) => state.application.isCoolBackground);
  const { data: treasuryBalanceData } = useBalance({
    address: getAddress(CHAIN_CONFIG.addresses.beansDaoExecutor),
  });

  const daoEtherscanLink = buildEtherscanHoldingsLink(
    CHAIN_CONFIG.addresses.beansDaoExecutor
  );

  const useStateBg = true;

  const nonWalletButtonStyle = !useStateBg
    ? NavBarButtonStyle.WHITE_INFO
    : isCool
      ? NavBarButtonStyle.COOL_INFO
      : NavBarButtonStyle.WARM_INFO;

  return (
    <>
      <Navbar
        expand='lg'
        style={{
          backgroundColor: `${useStateBg ? stateBgColor : 'white'}`,
        }}
        className={classes.navBarCustom}
      >
        <Container style={{ maxWidth: 'unset' }} fluid>
          <div className={classes.brandAndTreasuryWrapper}>
            <Link to='/' className={classes.navBarBrand}>
              <div className={classes.navBarLogo}>
                <BeanLogoMark
                  colorStart={'var(--beans-color-primary)'}
                  colorStop={'var(--beans-color-secondary)'}
                  key={1}
                />
              </div>
            </Link>
            <Nav.Item>
              {treasuryBalanceData && (
                <Nav.Link
                  href={daoEtherscanLink}
                  className={classes.beansNavLink}
                  target='_blank'
                  rel='noreferrer'
                >
                  <NavBarTreasury
                    treasuryBalance={Number(
                      formatEther(treasuryBalanceData.value)
                    ).toFixed(0)}
                    treasuryStyle={nonWalletButtonStyle}
                  />
                </Nav.Link>
              )}
            </Nav.Item>
          </div>
          <Navbar.Toggle
            className={classes.navBarToggle}
            aria-controls='basic-navbar-nav'
          />
          <Navbar.Collapse
            className={clsx(
              'justify-content-center',
              classes.collapseAdjust,
              classes.beansNavCollapse
            )}
          >
            {/* <Navbar.Collapse className={"justify-content-center"}> */}
            <Link to='/vote' className={classes.beansNavLink}>
              <NavBarButton
                buttonText={'DAO'}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <Link to='/playground' className={classes.beansNavLink}>
              <NavBarButton
                buttonText={'PLAYGROUND '}
                buttonStyle={nonWalletButtonStyle}
              />
            </Link>
            <div className='d-flex justify-content-center d-lg-none mx-auto pb-3 pt-1'>
              <Nav.Link
                className={classes.linkSpace}
                href={externalURL(ExternalURL.twitter)}
                target='_blank'
                rel='noreferrer'
              >
                <img src={twitterLogo} alt='twitter' />
              </Nav.Link>
              <Nav.Link
                className={classes.linkSpace}
                href={externalURL(ExternalURL.discord)}
                target='_blank'
                rel='noreferrer'
              >
                <img src={discordLogo} alt='discord' />
              </Nav.Link>
              <Nav.Link
                className={classes.linkSpace}
                href={externalURL(ExternalURL.farcaster)}
                target='_blank'
                rel='noreferrer'
              >
                <img src={farcasterLogo} alt='farcaster' />
              </Nav.Link>
              <Nav.Link
                className={classes.linkSpace}
                href={externalURL(ExternalURL.opensea)}
                target='_blank'
                rel='noreferrer'
              >
                <img src={openseaLogo} alt='opensea' />
              </Nav.Link>
            </div>
            <div className='d-lg-none'>
              <ConnectWalletButton />
            </div>
          </Navbar.Collapse>
          <div className='d-none d-lg-flex'>
            <Nav.Link
              href={externalURL(ExternalURL.twitter)}
              target='_blank'
              rel='noreferrer'
              className={classes.navBarSocial}
            >
              <img src={twitterLogo} alt='twitter' />
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.discord)}
              target='_blank'
              rel='noreferrer'
              className={classes.navBarSocial}
            >
              <img src={discordLogo} alt='discord' />
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.farcaster)}
              target='_blank'
              rel='noreferrer'
              className={classes.navBarSocial}
            >
              <img src={farcasterLogo} alt='farcaster' />
            </Nav.Link>
            <Nav.Link
              href={externalURL(ExternalURL.opensea)}
              target='_blank'
              rel='noreferrer'
              className={classes.navBarSocial}
            >
              <img src={openseaLogo} alt='opensea' />
            </Nav.Link>
            <ConnectWalletButton />
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default NavBar;
