import { useAccount } from 'wagmi';
import {
  useReadBeansTokenDataUri,
  useReadBeansTokenGetCurrentVotes,
  useReadBeansTokenGetPriorVotes,
} from '../generated/wagmiGenerated';

interface BeanToken {
  name: string;
  description: string;
  image: string;
  attributes: Array<any>;
}

export interface IBeanSeed {
  accessory: number;
  background: number;
  body: number;
  glasses: number;
  head: number;
}

export const useBeanToken = (beanId: bigint) => {
  const {
    data: bean,
    error,
    status,
  } = useReadBeansTokenDataUri({ args: [beanId] });

  if (!bean) {
    return;
  }

  const beanImgData = bean.split(';base64,').pop() as string;
  const json: BeanToken = JSON.parse(atob(beanImgData));

  return json;
};

export const useUserVotes = (): number | undefined => {
  const { address } = useAccount();
  const { data: currentVotes } = useReadBeansTokenGetCurrentVotes({
    args: [address ?? '0x'],
    query: { enabled: address !== undefined },
  });

  return currentVotes !== undefined ? Number(currentVotes) : undefined;
};

export const useUserVotesAsOfBlock = (
  block: bigint | undefined
): number | undefined => {
  const { address } = useAccount();
  const { data: priorVotes } = useReadBeansTokenGetPriorVotes({
    args: [address ?? '0x', block ?? 0n],
    query: { enabled: address !== undefined && block !== undefined },
  });

  return priorVotes ? Number(priorVotes) : undefined;
};
