import { Col, Row } from 'react-bootstrap';
import AuctionActivityWrapper from '../AuctionActivityWrapper';
import AuctionNavigation from '../AuctionNavigation';
import AuctionActivityBeanTitle from '../AuctionActivityBeanTitle';
import AuctionTitleAndNavWrapper from '../AuctionTitleAndNavWrapper';
// import { Link } from 'react-router-dom';
import Link from '../Link';
import beanContentClasses from './OGBeanContent.module.css';
import auctionBidClasses from '../AuctionActivity/BidHistory.module.css';
import bidBtnClasses from '../BidHistoryBtn/BidHistoryBtn.module.css';
import auctionActivityClasses from '../AuctionActivity/AuctionActivity.module.css';
import CurrentBid, { BID_N_A } from '../CurrentBid';
import Winner from '../Winner';

const OGBeanContent: React.FC<{
  mintTimestamp: bigint;
  beanId: bigint;
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
}> = (props) => {
  const {
    mintTimestamp,
    beanId,
    isFirstAuction,
    isLastAuction,
    onPrevAuctionClick,
    onNextAuctionClick,
  } = props;

  return (
    <AuctionActivityWrapper>
      <div className={auctionActivityClasses.informationRow}>
        <Row className={auctionActivityClasses.activityRow}>
          <AuctionTitleAndNavWrapper>
            <AuctionNavigation
              isFirstAuction={isFirstAuction}
              isLastAuction={isLastAuction}
              onNextAuctionClick={onNextAuctionClick}
              onPrevAuctionClick={onPrevAuctionClick}
              startTime={mintTimestamp}
            />
          </AuctionTitleAndNavWrapper>
          <Col lg={12}>
            <AuctionActivityBeanTitle beanId={beanId} />
          </Col>
        </Row>
        <Row className={auctionActivityClasses.activityRow}>
          <Col lg={4} className={auctionActivityClasses.currentBidCol}>
            <CurrentBid currentBid={BID_N_A} auctionEnded={true} />
          </Col>
          <Col
            lg={5}
            className={`${auctionActivityClasses.currentBidCol} ${beanContentClasses.currentBidCol} ${auctionActivityClasses.auctionTimerCol}`}
          >
            <div className={auctionActivityClasses.section}>
              <Winner winner={'0x'} isOg={true} />
            </div>
          </Col>
        </Row>
      </div>
      <Row className={auctionActivityClasses.activityRow}>
        <Col lg={12}>
          <ul className={auctionBidClasses.bidCollection}>
            <li
              className={`${auctionBidClasses.bidRowCool} ${beanContentClasses.bidRow}`}
            >
              Every 3rd Bean is given to an address that was holding a Bean from
              our{' '}
              <Link
                text='original collection'
                url='https://opensea.io/collection/cryptobeanz'
                leavesPage={true}
              />{' '}
              on April 15th, 2022. In the case of overlap with Bean Team (30,
              60, 90, etc) it will skip and resume again on every 3rd (33, 36,
              39, etc). This process is limited to 85 Beans and will take less
              than a year to complete.
            </li>
          </ul>
          <div className={bidBtnClasses.bidHistoryWrapperCool}></div>
        </Col>
      </Row>
    </AuctionActivityWrapper>
  );
};
export default OGBeanContent;
