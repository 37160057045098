import Banner from '../../components/Banner';
import Auction from '../../components/Auction';
import Documentation from '../../components/Documentation';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setOnDisplayAuctionBeanId } from '../../state/slices/onDisplayAuction';
import { push } from 'redux-first-history';
import { beanPath } from '../../utils/history';
import useOnDisplayAuction from '../../wrappers/onDisplayAuction';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export default function AuctionPage() {
  const params = useParams();
  const initialAuctionId = params.id ? Number(params.id) : undefined;
  const onDisplayAuction = useOnDisplayAuction();
  const lastAuctionBeanId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionBeanId
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!lastAuctionBeanId) return;

    if (initialAuctionId !== undefined) {
      // handle out of bounds bean path ids
      if (initialAuctionId > lastAuctionBeanId || initialAuctionId < 0) {
        dispatch(setOnDisplayAuctionBeanId(lastAuctionBeanId));
        dispatch(push(beanPath(lastAuctionBeanId)) as any);
      } else {
        if (onDisplayAuction === undefined) {
          // handle regular bean path ids on first load
          dispatch(setOnDisplayAuctionBeanId(initialAuctionId));
        }
      }
    } else {
      // no bean path id set
      if (lastAuctionBeanId) {
        dispatch(setOnDisplayAuctionBeanId(lastAuctionBeanId));
      }
    }
  }, [lastAuctionBeanId, dispatch, initialAuctionId, onDisplayAuction]);

  return (
    <>
      <Auction auction={onDisplayAuction} />
      {/* {onDisplayAuctionBeanId && onDisplayAuctionBeanId !== lastAuctionBeanId ? (
        <ProfileActivityFeed beanId={onDisplayAuctionBeanId} />
      ) : (
        <Banner />
      )} */}
      <Banner />
      <Documentation />
    </>
  );
}
