import classes from './AuctionActivityBeanTitle.module.css';

const AuctionActivityBeanTitle: React.FC<{ beanId: bigint }> = (props) => {
  const { beanId } = props;
  const beanIdContent = `BEAN #${beanId.toString()}`;
  return (
    <div className={classes.wrapper}>
      <h1 style={{ color: 'white' }}>{beanIdContent}</h1>
    </div>
  );
};
export default AuctionActivityBeanTitle;
