import React from 'react';

function BeanLogoType({ colorStart = '#A41FAF', colorStop = '#A41FAF' }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1920 446.07'>
      <defs>
        <linearGradient
          // id="linear-gradient2"
          id='linear-gradient2'
          x1='41.62'
          y1='182.28'
          x2='1796.18'
          y2='182.28'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0' stopColor={colorStart}></stop>
          <stop offset='1' stopColor={colorStop}></stop>
        </linearGradient>
      </defs>
      {/* <path
        fill="none"
        stroke="url(#linear-gradient2)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="10"
        d="M721.53 22.39c117.9 0 219.24 82.5 219.24 201.33a200.94 200.94 0 01-201.33 201.33c110.73 0 201.33 89.84 201.33 201.33 0 118.87-101.34 201.33-219.24 201.33h-515.2C78.55 827.71 5 759.57 5 626.38V223.67C5 90.48 78.55 22.25 206.33 22.25c110.73 0 405.08.14 515.2.14zm1030.15 533.23h-23.31c69.46 0 134.2-50 134.2-131.37s-64.74-131.37-134.2-131.37c14.61.19 0 0 23.31 0 86.73 0 146.69-55.44 146.69-135.29S1834.44 22.3 1744.52 22.3c-73.6 0-417-.05-527.71-.05-127.77 0-201.32 68.23-201.32 201.42v402.71c0 133.19 73.55 201.33 201.33 201.33 110.73 0 454.1-.05 527.7-.05 89.92 0 153.85-58.34 153.85-136s-59.96-136.04-146.69-136.04zm687.19 49.71c-23.48 0-63.19 11.41-73.25 67.89-20.56 129.1-106.5 167.39-197.87 167.39-62.6 0-193.37-44.26-205-226.08-1.3-20.26-.65-101.46-.65-117.77C1962.06 178.31 2183.69 5 2438.87 5s476.81 173.31 476.81 491.76c0 16.31.65 97.51-.65 117.77-11.67 181.82-142.43 226.08-205 226.08-91.38 0-177.31-38.29-197.87-167.39-10.09-56.48-49.81-67.89-73.29-67.89zM4343 22.25c-162.32 0-304 95.64-304 270.27 0 169.85 153.63 241.47 246.74 248.77h-68c-118.17 0-171.54 66.9-171.54 143.19s62.92 143.18 186.3 143.18c73.6 0 244.85.05 407.18.05s304-95.64 304-270.27c0-169.85-153.63-241.47-246.74-248.77h68c118.17 0 171.55-66.9 171.55-143.19S4873.58 22.3 4750.2 22.3c-73.6 0-259.2-.05-407.2-.05zm-375 592.28c0 180.23-126.54 226.08-201.46 226.08-38.54 0-85-8.45-161.87-57.69C3472.4 698.15 3373 631.84 3373 631.84c0 153.48-102.25 208.77-187 208.77S2999 782 2999 628.54V231.09C2999 50.85 3125.54 5 3200.46 5c38.54 0 85 8.46 161.88 57.69 132.32 84.77 231.76 151.09 231.76 151.09C3594.1 60.29 3696.35 5 3781.08 5s187 58.59 187 212.07z"
      ></path> */}
      <g id='BEANSFULLLOGO_DROPSHADOW'>
        <path
          fill='#fff'
          d='m1906,120.51c-3.11-4.41-6.67-8.38-10.57-11.94l-76.61-76.34c-14.27-16.14-35.07-25.26-58.28-25.26h-174.4c-.29,0-.58,0-.87,0-.26,0-.53,0-.79,0-35.77,0-70.42,11.98-100.2,34.65-4.73,3.6-9.27,7.44-13.63,11.5-14.79-22.97-40.47-37.06-72.34-39.69-30.38-2.5-56.92,3.57-78.91,18.04-9.4,6.19-17.87,13.75-25.47,22.78l-8.44-8.59-.48-.48c-20.92-20.62-47.89-31.98-75.94-31.98-45.62,0-83.67,20.23-108.75,56.71-1.98-3.44-4.03-6.64-6.11-9.6C1067.37,21.98,1020.29,0,965.03,0s-107.97,16.53-152.11,47.8c-6.84,4.84-13.44,10.02-19.79,15.52-2.67-7.84-6.64-15.12-11.88-21.64-14.83-18.45-37.8-28.61-64.69-28.61h-210.16c-26.98,0-54.34,12.74-74.44,32.71-12.68-14.2-37.7-32.7-81.9-32.7h-163.15c-30.8,0-59.66,9.87-83.45,28.56-21.12,16.58-37.37,39.33-47,65.79L7.74,241.29c-12.2,33.52-9.93,64.82,6.4,88.15,2.03,2.9,91.1,92.48,92.63,93.89l.07.07h0c12.21,11.23,32.54,22.68,64.76,22.68h171.86c32.8,0,62.8-8.26,86.76-23.89,1.16-.75,2.3-1.53,3.43-2.31,13.47,16.71,34.14,26.2,57.44,26.2h225.43c24.72,0,47.88-8.19,66.98-23.67,1.05-.85,2.07-1.71,3.08-2.59,11.63,12.25,32.66,26.27,67.98,26.27,50.08,0,80.29-21.12,100.74-45.02,3.27,6.61,7.41,12.68,12.39,18.09,11.32,12.29,32.24,26.94,67.68,26.94s66.27-11.55,90.2-32.95c15.23,18.9,38.82,30.46,67.38,32.81,4.44.37,8.8.55,13.08.55,24.96,0,47.06-6.23,65.83-18.58,9.4-6.19,17.87-13.75,25.47-22.78l8.44,8.6.48.48c20.92,20.62,47.89,31.98,75.94,31.98,34.34,0,64.4-11.46,87.86-32.7,14.29,16.92,35.58,26.5,59.39,26.5h174.4c.28,0,.55,0,.85,0,.27,0,.54,0,.81,0,35.77,0,70.42-11.98,100.2-34.65,27.44-20.88,48.96-50.01,60.61-82.01,9.26-25.44,9.94-51.52,2.41-74.78,3.42-1.85,6.72-3.89,9.88-6.12,16.72-11.79,29.32-28.53,36.43-48.39,9.02-25.19,5.65-52.67-9.03-73.51Z'
        />
      </g>
      <g id='BEANSFULLLOGO_COLOR'>
        <path
          fill='url(#linear-gradient2)'
          id='BEANS'
          d='m410.3,121.67c-12.43,42.15-53.18,62.17-91.3,62.17,29.04,0,53.14,34.89,42.57,71.7-12.52,43.58-50.72,66.93-100.25,66.93H89.47c-41.92,0-56.87-27.66-42.57-66.93,14.3-39.28,48.72-133.87,48.72-133.87h0c13.4-36.81,45.71-66.93,91.3-66.93h163.15c55.46,0,68.57,38.67,60.23,66.93Zm889.76,200.9c47.49,0,75.27-30,88.67-66.81l48.72-133.87c13.4-36.81-3.87-63.74-42.57-66.93-38.83-3.2-70.96,11.07-91.3,66.93l-.44,1.22-47.39-48.26c-13.99-13.79-31.02-19.99-46.68-19.99-47.49,0-75.27,30-88.67,66.81l-48.72,133.87c-13.4,36.81,3.87,63.74,42.57,66.93,38.83,3.2,70.96-11.07,91.3-66.93l.44-1.22,47.39,48.26c13.99,13.79,31.02,19.99,46.68,19.99ZM716.57,54.74h-210.16c-24.54,0-51.93,20.08-60.86,44.62h0s-32.48,89.25-32.48,89.25h0s-32.48,89.25-32.48,89.25h0c-8.93,24.54,3.84,44.62,28.38,44.62h225.43c32.64,0,55.68-23.44,60.86-44.62,6.06-24.78-6.04-47.64-44.96-47.64,34.19,0,53.62-14.21,60.86-38.04,8.07-26.58-11.8-48.19-28.38-48.19,42.8,0,65.7-18.3,71.49-44.62,6.3-28.61-11.72-44.62-37.7-44.62Zm248.47-13.07c-92.67,0-177.2,54.57-214.6,157.34l-20.58,56.53c-13.4,36.81-.59,66.93,42.57,66.93h0c43.27,0,63.79-19.97,81.85-47.83h0c7.34-11.55,20.86-20.03,33.25-20.03s19.66,8.4,18.68,19.86h0c-2.31,27.94,13.22,48,47.02,48h0c49.51,0,77.9-30.12,91.3-66.93l20.58-56.53c37.4-102.77-22.13-157.34-100.07-157.34Zm497.78,96.22c-17.58,48.31,13.01,88.1,54.43,89.21h0s.02,0,.02,0c.79.02,1.59.03,2.39.03h-44.64c-32.63,0-52.76,17.26-60.85,39.85-8.09,22.58,3.41,49.4,33.13,49.4h174.4c.28,0,.55,0,.83-.01.28,0,.55.01.83.01,55.64,0,103.72-39.96,121.66-89.25,17.58-48.31-13.01-88.1-54.43-89.21h0s-.02,0-.02,0c-.79-.02-1.59-.03-2.39-.03h44.64c32.63,0,52.76-17.26,60.85-39.85,8.09-22.58-3.41-49.4-33.13-49.4h-174.4c-.28,0-.55,0-.83.01-.28,0-.55-.01-.83-.01-55.64,0-103.72,39.96-121.66,89.25Z'
        />
      </g>
    </svg>
  );
}

export default BeanLogoType;
