import { CHAIN_CONFIG } from '../config';

export const buildEtherscanTxLink = (txHash: string): string => {
  const path = `tx/${txHash}`;
  return new URL(path, CHAIN_CONFIG.scannerBaseUrl).toString();
};

export const buildEtherscanAddressLink = (address: string): string => {
  const path = `address/${address}`;
  return new URL(path, CHAIN_CONFIG.scannerBaseUrl).toString();
};

export const buildEtherscanHoldingsLink = (address: string): string => {
  const path = `address/${address}`;
  return new URL(path, CHAIN_CONFIG.scannerBaseUrl).toString();
};

export const buildEtherscanApiQuery = (
  address: string,
  module = 'contract',
  action = 'getsourcecode'
): string => {
  const params = new URLSearchParams({
    module,
    action,
    address,
    apikey: process.env.REACT_APP_ETHERSCAN_API_KEY ?? '',
  });
  const path = `api?${params.toString()}`;
  return new URL(path, CHAIN_CONFIG.etherscanApiBaseUrl).toString();
};
