import React from 'react';
import classes from './AuctionNavigation.module.css';
import AuctionActivityDateHeadline from '../AuctionActivityDateHeadline';
import arrowLeftCircle from '../../assets/icons/ArrowLeftCircle.svg';

const AuctionNavigation: React.FC<{
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
  startTime: bigint;
}> = (props) => {
  const {
    isFirstAuction,
    isLastAuction,
    onPrevAuctionClick,
    onNextAuctionClick,
    startTime,
  } = props;
  return (
    <div
      className={
        classes.navArrowsContainer +
        ' flex flex-row items-center justify-center'
      }
    >
      <button
        onClick={() => onPrevAuctionClick()}
        className={classes.leftArrowCool}
        disabled={isFirstAuction}
      >
        <img src={arrowLeftCircle} alt='right arrow' />
      </button>
      <AuctionActivityDateHeadline startTime={startTime} />
      <button
        onClick={() => onNextAuctionClick()}
        className={classes.rightArrowCool}
        disabled={isLastAuction}
      >
        <img src={arrowLeftCircle} alt='right arrow' />
      </button>
    </div>
  );
};
export default AuctionNavigation;
