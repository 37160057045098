import React from 'react';
import classes from './BeanInfoRowButton.module.css';

interface BeanInfoRowButtonProps {
  btnText: string;
  onClickHandler: () => void;
}

const BeanInfoRowButton: React.FC<BeanInfoRowButtonProps> = (props) => {
  const { btnText, onClickHandler } = props;
  return (
    <div className={classes.beanButtonCool} onClick={onClickHandler}>
      <div className={classes.beanButtonContents}>{btnText}</div>
    </div>
  );
};

export default BeanInfoRowButton;
