import { Col, Row } from 'react-bootstrap';
import AuctionActivityWrapper from '../AuctionActivityWrapper';
import AuctionNavigation from '../AuctionNavigation';
import AuctionActivityBeanTitle from '../AuctionActivityBeanTitle';
import AuctionTitleAndNavWrapper from '../AuctionTitleAndNavWrapper';
import beanContentClasses from './MigratedBeanContent.module.css';
import auctionBidClasses from '../AuctionActivity/BidHistory.module.css';
import bidBtnClasses from '../BidHistoryBtn/BidHistoryBtn.module.css';
import auctionActivityClasses from '../AuctionActivity/AuctionActivity.module.css';
import CurrentBid from '../CurrentBid';
import Winner from '../Winner';
import { Auction } from '../../wrappers/beansAuction';

const MigratedBeanContent: React.FC<{
  auction: Auction;
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
}> = (props) => {
  const {
    auction,
    isFirstAuction,
    isLastAuction,
    onPrevAuctionClick,
    onNextAuctionClick,
  } = props;

  return (
    <AuctionActivityWrapper>
      <div className={auctionActivityClasses.informationRow}>
        <Row className={auctionActivityClasses.activityRow}>
          <AuctionTitleAndNavWrapper>
            <AuctionNavigation
              isFirstAuction={isFirstAuction}
              isLastAuction={isLastAuction}
              onNextAuctionClick={onNextAuctionClick}
              onPrevAuctionClick={onPrevAuctionClick}
              startTime={auction.endTime}
            />
          </AuctionTitleAndNavWrapper>
          <Col lg={12}>
            <AuctionActivityBeanTitle beanId={auction.beanId} />
          </Col>
        </Row>
        <Row className={auctionActivityClasses.activityRow}>
          <Col lg={4} className={auctionActivityClasses.currentBidCol}>
            <CurrentBid currentBid={auction.amount} auctionEnded={true} />
          </Col>
          <Col
            lg={5}
            className={`${auctionActivityClasses.currentBidCol} ${beanContentClasses.currentBidCol} ${auctionActivityClasses.auctionTimerCol}`}
          >
            <div className={auctionActivityClasses.section}>
              <Winner winner={auction.bidder} isMigrated={false} />
            </div>
          </Col>
        </Row>
      </div>
      <Row className={auctionActivityClasses.activityRow}>
        <Col lg={12}>
          <ul className={auctionBidClasses.bidCollection}>
            <li
              className={`${auctionBidClasses.bidRowCool} ${beanContentClasses.bidRow}`}
            >
              This bean has been migrated from the original Layer 1 ETH
              contract.
            </li>
          </ul>
          <div className={bidBtnClasses.bidHistoryWrapperCool}>
            {/* <Link
              to="/beanteam"
              className={bidBtnClasses.bidHistoryCool}
            >
              Learn more →
            </Link> */}
          </div>
        </Col>
      </Row>
    </AuctionActivityWrapper>
  );
};
export default MigratedBeanContent;
