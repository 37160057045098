import { Proposal, useProposalThreshold } from '../../wrappers/beansDao';
import { Alert, Button } from 'react-bootstrap';
import ProposalStatus from '../ProposalStatus';
import classes from './Proposals.module.css';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { useUserVotes } from '../../wrappers/beanToken';
import { useAccount } from 'wagmi';

const Proposals = ({ proposals }: { proposals: Proposal[] }) => {
  const navigate = useNavigate();
  const currentVotes = useUserVotes();
  const proposalThreshold = useProposalThreshold();
  const { address } = useAccount();

  const nullStateCopy = () => {
    if (!address) {
      return 'CONNECT WALLET TO MAKE A PROPOSAL.';
    }
    return `YOU NEED ${(proposalThreshold ?? 0n) + 1n} VOTES TO MAKE A PROPOSAL!`;
  };

  const formatProposalId = (id: string | undefined) => {
    const num = Number(id);
    return ('0' + num).slice(-2).toString() + '.';
  };

  return (
    <div className={classes.proposals}>
      <div>
        {currentVotes &&
        proposalThreshold &&
        currentVotes > proposalThreshold ? (
          <Row className={classes.submitProposalButtonWrapper}>
            <Col xs={2}>
              <h3 className={classes.heading}>PROPOSALS</h3>
            </Col>
            <Col>
              <div className={classes.btnWrapper}>
                <Button
                  className={classes.generateBtn}
                  onClick={() => navigate('/create-proposal')}
                >
                  CREATE PROPOSAL
                </Button>
              </div>
            </Col>
          </Row>
        ) : (
          <Row className={classes.submitProposalButtonWrapper}>
            <Col>
              <h3 className={classes.heading}>PROPOSALS</h3>
            </Col>
            <Col>
              <div className={classes.nullBtnWrapper}>
                <Button className={classes.generateBtnDisabled}>
                  CREATE PROPOSAL
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
      {<div className={classes.nullStateCopy}>{nullStateCopy()}</div>}
      {proposals?.length ? (
        proposals
          .slice(0)
          .reverse()
          .map((p, i) => {
            return (
              <div
                className={classes.proposalLink}
                onClick={() => navigate(`/vote/${p.id}`)}
                key={i}
              >
                <span className={classes.proposalTitle}>
                  <span className={classes.proposalId}>
                    {formatProposalId(p.id.toString())}
                  </span>{' '}
                  <span>{p.title.toUpperCase()}</span>
                </span>
                <div className={classes.proposalStatusWrapper}>
                  <ProposalStatus status={p.status}></ProposalStatus>
                </div>
              </div>
            );
          })
      ) : (
        <Alert variant='secondary' className={classes.noProposalsFound}>
          <Alert.Heading style={{ marginBottom: '0rem' }}>
            No proposals found.
          </Alert.Heading>
          <p>Proposals submitted by community members will appear here.</p>
        </Alert>
      )}
    </div>
  );
};
export default Proposals;
