import { ApolloClient, InMemoryCache, gql } from '@apollo/client';
import { Address } from 'viem';

export interface IBid {
  id: string;
  bidder: {
    id: Address;
  };
  amount: bigint;
  blockNumber: bigint;
  blockTimestamp: bigint;
  txIndex?: number;
  bean: {
    id: bigint;
    startTime?: bigint;
    endTime?: bigint;
    settled?: boolean;
  };
}

// we still need to figure out what is going on for owner in some cases

// works, removed seed
export const auctionQuery = (auctionId: number) => gql`
{
	auction(id: ${auctionId}) {
		id
		amount
		settled
		bidder {
			id
		}
		startTime
		endTime
		bean {
		  id
		  owner {
			id
		  }
		}
		bids {
		  id
		  blockNumber
		  txIndex
		  amount
		}
	  }
  }
  `;

// works
export const bidsByAuctionQuery = (auctionId: string) => gql`
 {
	bids(where:{auction: "${auctionId}"}) {
		id
		amount
		blockNumber
		blockTimestamp
		txIndex
		bidder {
			id
		}
		bean {
		  id
		}
	  }
  }
 `;

// typeref -> tells the type of bean that is it aka beanteam, auction, ogbean
// tokenid -> this is the id for the subgraph "guid" // aka the subgraph identifier
export const beanQuery = (id: string) => gql`
 {
	bean(id:"${id}") {
	  id
	  owner {
		id
	  }
	}
  }
 `;

export const beansIndex = () => gql`
  {
    beans {
      id
      owner {
        id
      }
    }
  }
`;

// works
export const latestAuctionsQuery = () => gql`
  {
    auctions(orderBy: startTime, orderDirection: desc, first: 1000) {
      id
      amount
      settled
      bidder {
        id
      }
      startTime
      endTime
      bean {
        id
        owner {
          id
        }
      }
      bids {
        id
        amount
        blockNumber
        blockTimestamp
        txIndex
        bidder {
          id
        }
      }
    }
  }
`;

// works
export const latestBidsQuery = (first: number = 10) => gql`
{
	bids(first: ${first}, orderBy: blockTimestamp, orderDirection: desc) {
		id
		bidder {
			id
		}
		amount
		blockTimestamp
		txIndex
		blockNumber
		auction {
			id
			startTime
			endTime
			settled
		}
	}
  }  
`;

// works
export const beanVotingHistoryQuery = (beanId: number) => gql`
{
	beanCreated(id: ${beanId}) {
		id
		votes {
		proposal {
			id
		}
		support
		supportDetailed
		}
	}
}
`;

// works // need to double check for proposalid if id doesnt work
export const createTimestampAllProposals = () => gql`
  {
    proposals(orderBy: createdTimestamp, orderDirection: asc, first: 1000) {
      id
      createdTimestamp
    }
  }
`;

export const clientFactory = (uri: string) =>
  new ApolloClient({
    uri,
    cache: new InMemoryCache(),
  });
