import '@rainbow-me/rainbowkit/styles.css';
import { RainbowKitProvider, midnightTheme } from '@rainbow-me/rainbowkit';
import { WagmiProvider, createConfig, createStorage, fallback } from 'wagmi';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { createPublicClient, http, webSocket } from 'viem';
import { CHAIN_CONFIG } from '../config';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  coinbaseWallet,
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
} from '@rainbow-me/rainbowkit/wallets';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Popular',
      wallets: [
        rainbowWallet,
        walletConnectWallet,
        coinbaseWallet,
        metaMaskWallet,
      ],
    },
  ],
  {
    appName: 'BEANS DAO',
    projectId: 'dc7f96ef59ca2128a3c225f2eae8cdd5',
  }
);

export const publicWsClient = createPublicClient({
  chain: CHAIN_CONFIG.chain,
  transport: fallback([
    webSocket(CHAIN_CONFIG.rpcWebSocketUrl.main),
    ...(CHAIN_CONFIG.rpcWebSocketUrl.fallback ? [webSocket(CHAIN_CONFIG.rpcWebSocketUrl.fallback)] : []),
  ]) 
});

const config = createConfig({
  connectors,
  chains: [CHAIN_CONFIG.chain],
  transports: {
    [CHAIN_CONFIG.chain.id]: fallback([
      http(CHAIN_CONFIG.rpcHttpUrl.main),
      ...(CHAIN_CONFIG.rpcHttpUrl.fallback ? [http(CHAIN_CONFIG.rpcHttpUrl.fallback)] : []),
    ])
  },
  storage: createStorage({
    storage: localStorage,
    key: 'wagmi' + CHAIN_CONFIG.chain.id,
  }),
});

const queryClient = new QueryClient();

export default function WalletProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={midnightTheme()}>
          {children}
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}
