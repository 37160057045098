import {
  Row,
  Col,
  Alert,
  Button,
  Card,
  ProgressBar,
  Spinner,
} from 'react-bootstrap';
import Section from '../../layout/Section';
import {
  ProposalState,
  useHasVotedOnProposal,
  useProposal,
  Vote,
} from '../../wrappers/beansDao';
import { useUserVotesAsOfBlock } from '../../wrappers/beanToken';
import classes from './Vote.module.css';
import { Link, useParams } from 'react-router-dom';
import {
  buildEtherscanAddressLink,
  buildEtherscanTxLink,
} from '../../utils/etherscan';
import { AlertModal, setAlertModal } from '../../state/slices/application';
import ProposalStatus from '../../components/ProposalStatus';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import VoteModal from '../../components/VoteModal';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import { useAppDispatch } from '../../hooks';
import { useAccount, useBlock } from 'wagmi';
import {
  useWriteBeansDaoCancel,
  useWriteBeansDaoCastVote,
  useWriteBeansDaoExecute,
  useWriteBeansDaoQueue,
} from '../../generated/wagmiGenerated';
import { type UseWriteContractReturnType } from 'wagmi';
import { isAddress, isAddressEqual, zeroAddress } from 'viem';
import { CHAIN_CONFIG } from '../../config';
import RequireCorrectChainButtonContainer from '../../components/RequireCorrectChainButtonContainer';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const AVERAGE_BLOCK_TIME_IN_SECS = 2;

export default function VotePage() {
  const params = useParams();
  const id = params.id;
  const proposal = useProposal(id ? BigInt(id) : 0n);

  const { address } = useAccount();

  const [vote, setVote] = useState<Vote>();

  const [showVoteModal, setShowVoteModal] = useState<boolean>(false);
  const [isVotePending, setVotePending] = useState<boolean>(false);

  const [isQueuePending, setQueuePending] = useState<boolean>(false);
  const [isExecutePending, setExecutePending] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const setModal = useCallback(
    (modal: AlertModal) => dispatch(setAlertModal(modal)),
    [dispatch]
  );

  const {
    writeContract: castVote,
    status: castVoteStatus,
    error: castVoteError,
  } = useWriteBeansDaoCastVote();

  const {
    writeContract: queueProposal,
    status: queueProposalStatus,
    error: queueProposalError,
  } = useWriteBeansDaoQueue();

  const {
    writeContract: cancelProposal,
    status: cancelProposalStatus,
    error: cancelProposalError,
  } = useWriteBeansDaoCancel();

  const {
    writeContract: executeProposal,
    status: executeProposalStatus,
    error: executeProposalError,
  } = useWriteBeansDaoExecute();

  // Get and format date from data
  const timestamp = Date.now();
  const { data: blockData } = useBlock();
  const currentBlock = blockData?.number;
  const startDate =
    proposal && timestamp && currentBlock
      ? dayjs(timestamp).add(
          AVERAGE_BLOCK_TIME_IN_SECS *
            Number(proposal.startBlock - currentBlock),
          'seconds'
        )
      : undefined;

  const endDate =
    proposal && timestamp && currentBlock
      ? dayjs(timestamp).add(
          AVERAGE_BLOCK_TIME_IN_SECS * Number(proposal.endBlock - currentBlock),
          'seconds'
        )
      : undefined;
  const now = dayjs();

  // Get total votes and format percentages for UI
  const totalVotes = proposal
    ? proposal.forCount + proposal.againstCount + proposal.abstainCount
    : undefined;
  const forPercentage =
    proposal && totalVotes
      ? Number(proposal.forCount * 100n) / Number(totalVotes)
      : 0;
  const againstPercentage =
    proposal && totalVotes
      ? Number(proposal.againstCount * 100n) / Number(totalVotes)
      : 0;
  const abstainPercentage =
    proposal && totalVotes
      ? Number(proposal.abstainCount * 100n) / Number(totalVotes)
      : 0;

  const proposalActive = proposal?.status === ProposalState.ACTIVE;

  // Only count available votes as of the proposal created block
  const availableVotes = useUserVotesAsOfBlock(
    proposal?.createdBlock ?? undefined
  );
  const hasVoted = useHasVotedOnProposal(proposal?.id);

  const showBlockRestriction = proposalActive;

  // Only show voting if user has > 0 votes at proposal created block and proposal is active
  const showVotingButtons = availableVotes && !hasVoted && proposalActive;
  // const showVotingButtons = true;
  const linkIfAddress = (content: string) => {
    if (isAddress(content)) {
      return (
        <a
          href={buildEtherscanAddressLink(content)}
          target='_blank'
          rel='noreferrer'
        >
          {content}
        </a>
      );
    }
    return <span>{content}</span>;
  };

  const transactionLink = (content: string) => {
    return (
      <a href={buildEtherscanTxLink(content)} target='_blank' rel='noreferrer'>
        {content.substring(0, 7)}
      </a>
    );
  };

  const { forCount = 0, againstCount = 0, quorumVotes = 0 } = proposal || {};
  const quorumReached = forCount > againstCount && forCount >= quorumVotes;

  const [
    moveStateButtonLabel,
    moveStateAction,
    stateChangePermitted,
    moveButtonDisabled,
  ] = useMemo(() => {
    switch (proposal?.status) {
      case ProposalState.ACTIVE:
        return [
          'Cancel',
          () =>
            cancelProposal({
              args: [proposal?.id],
              chainId: CHAIN_CONFIG.chain.id,
            }),
          proposal && address
            ? isAddressEqual(proposal.proposer ?? zeroAddress, address)
            : false,
          !address,
        ];
      case ProposalState.SUCCEEDED:
        return [
          'Queue',
          () =>
            queueProposal({
              args: [proposal?.id],
              chainId: CHAIN_CONFIG.chain.id,
            }),
          true,
          isQueuePending || !address,
        ];
      case ProposalState.QUEUED:
        return [
          'Execute',
          () =>
            executeProposal({
              args: [proposal?.id],
              chainId: CHAIN_CONFIG.chain.id,
            }),
          new Date() >= (proposal?.eta ?? Number.MAX_SAFE_INTEGER),
          isExecutePending || !address,
        ];
      default:
        return ['', () => {}, false, false];
    }
  }, [
    cancelProposal,
    queueProposal,
    isQueuePending,
    executeProposal,
    isExecutePending,
    address,
    proposal,
  ]);

  const onTransactionStateChange = useCallback(
    (
      status: UseWriteContractReturnType['status'],
      successMessage?: string,
      errorMessage?: string | undefined,
      setPending?: (isPending: boolean) => void,
      onFinalState?: () => void
    ) => {
      switch (status) {
        case 'idle':
          setPending?.(false);
          break;
        case 'pending':
          setPending?.(true);
          break;
        case 'success':
          setModal({
            title: 'SUCCESS',
            message: successMessage || 'TRANSACTION SUCCESSFUL!',
            show: true,
          });
          setPending?.(false);
          onFinalState?.();
          break;
        case 'error':
          const userReject = errorMessage?.includes('User rejected');
          const alreadyVoted = errorMessage?.includes('voter already voted');
          setModal({
            title: 'TRANSACTION FAILED',
            message: userReject
              ? 'User rejected request'
              : alreadyVoted
                ? 'Already voted'
                : 'Please try again.',
            show: true,
          });
          setPending?.(false);
          onFinalState?.();
          break;
      }
    },
    [setModal]
  );

  useEffect(
    () =>
      onTransactionStateChange(
        castVoteStatus,
        castVoteError?.message,
        'Vote Successful!',
        setVotePending,
        () => setShowVoteModal(false)
      ),
    [castVoteStatus, castVoteError, onTransactionStateChange, setModal]
  );

  useEffect(
    () =>
      onTransactionStateChange(
        queueProposalStatus,
        'PROPOSAL QUEUED!',
        queueProposalError?.message,
        setQueuePending
      ),
    [
      queueProposalStatus,
      queueProposalError,
      onTransactionStateChange,
      setModal,
    ]
  );

  useEffect(
    () =>
      onTransactionStateChange(
        executeProposalStatus,
        'PROPOSAL EXECUTED!',
        executeProposalError?.message,
        setExecutePending
      ),
    [
      executeProposalStatus,
      executeProposalError,
      onTransactionStateChange,
      setModal,
    ]
  );

  useEffect(
    () =>
      onTransactionStateChange(
        cancelProposalStatus,
        'PROPOSAL CANCELED!',
        cancelProposalError?.message,
        undefined
      ),
    [
      cancelProposalStatus,
      cancelProposalError,
      onTransactionStateChange,
      setModal,
    ]
  );

  return (
    <Section fullWidth={false} className={classes.votePage}>
      <VoteModal
        show={showVoteModal}
        onHide={() => setShowVoteModal(false)}
        onVote={() =>
          castVote({
            args: [proposal?.id!, vote!],
            chainId: CHAIN_CONFIG.chain.id,
          })
        }
        isLoading={isVotePending}
        proposalId={proposal?.id}
        availableVotes={availableVotes}
        vote={vote}
      />
      <Col lg={{ span: 8, offset: 2 }}>
        <Link to='/vote'>← ALL PROPOSALS</Link>
      </Col>
      <Col lg={{ span: 8, offset: 2 }} className={classes.proposal}>
        <div className='d-flex justify-content-between align-items-center'>
          <h3 className={classes.proposalId}>
            PROPOSAL {proposal?.id.toString()}
          </h3>
          <ProposalStatus status={proposal?.status}></ProposalStatus>
        </div>
        <div>
          {startDate && startDate.isBefore(now) ? null : proposal ? (
            <span className={classes.votingText}>
              Voting starts approximately{' '}
              {startDate?.format('MMMM D, YYYY h:mm A z')}{' '}
              {startDate && `(${(startDate as any).fromNow()})`}{' '}
            </span>
          ) : (
            ''
          )}
        </div>
        <div>
          {endDate && endDate.isBefore(now) ? (
            <>
              <div className={classes.votingText}>
                Voting ended {endDate.format('MMMM D, YYYY h:mm A z')}
              </div>
              <div className={classes.votingText}>
                This proposal has{' '}
                {quorumReached ? 'reached' : 'failed to reach'} quorum{' '}
                {proposal?.quorumVotes !== undefined &&
                  `(${proposal.quorumVotes} votes)`}
              </div>
            </>
          ) : proposal ? (
            <>
              <div className={classes.votingText}>
                Voting ends approximately{' '}
                {endDate?.format('MMMM D, YYYY h:mm A z')}{' '}
                {endDate && `(${(endDate as any).fromNow()})`}{' '}
              </div>
              {proposal?.quorumVotes !== undefined && (
                <div className={classes.votingText}>
                  A total of {proposal.quorumVotes.toString()} votes are
                  required to reach quorum
                </div>
              )}
            </>
          ) : (
            ''
          )}
        </div>
        {proposal && proposalActive && (
          <>
            {showBlockRestriction && !hasVoted && (
              <Alert
                variant='secondary'
                className={classes.blockRestrictionAlert}
              >
                Only BEAN votes that were self delegated or delegated to another
                address before block {proposal.createdBlock.toString()} are
                eligible for voting.
              </Alert>
            )}
            {hasVoted && (
              <Alert variant='success' className={classes.voterIneligibleAlert}>
                THANK YOU FOR YOUR VOTE!
              </Alert>
            )}
          </>
        )}

        {stateChangePermitted && (
          <RequireCorrectChainButtonContainer>
            <Button
              onClick={moveStateAction}
              disabled={moveButtonDisabled}
              variant='dark'
              style={{ marginTop: '16px' }}
            >
              {isQueuePending || isExecutePending ? (
                <Spinner
                  animation='border'
                  className={classes.spinner}
                  size='sm'
                />
              ) : (
                `${moveStateButtonLabel} Proposal`
              )}
            </Button>
          </RequireCorrectChainButtonContainer>
        )}
        <Row>
          <Col>
            <Card className={classes.voteCountCard}>
              <Card.Body className='p-3'>
                <Card.Text className='m-0 py-2 pt-0'>
                  <Row>
                    <Col sm={6} className='text-lg-start text-center'>
                      <span style={{ color: '#49D782' }}>FOR</span>
                    </Col>
                    <Col sm={6} className='text-lg-end text-center'>
                      <span>{proposal?.forCount.toString()}</span>
                    </Col>
                  </Row>
                </Card.Text>
                <ProgressBar variant='success' now={forPercentage} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className={classes.voteCountCard}>
              <Card.Body className='p-3'>
                <Card.Text className='m-0 py-2 pt-0'>
                  <Row>
                    <Col sm={6} className='text-lg-start text-center'>
                      <span style={{ color: '#FC6666' }}>AGAINST</span>
                    </Col>
                    <Col sm={6} className='text-lg-end text-center'>
                      <span>{proposal?.againstCount.toString()}</span>
                    </Col>
                  </Row>
                </Card.Text>
                <ProgressBar variant='danger' now={againstPercentage} />
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card className={classes.voteCountCard}>
              <Card.Body className='p-3'>
                <Card.Text className='m-0 py-2 pt-0'>
                  <Row>
                    <Col sm={6} className='text-lg-start text-center'>
                      <span style={{ color: '#8A90A3' }}>ABSTAIN</span>
                    </Col>
                    <Col sm={6} className='text-lg-end text-center'>
                      <span>{proposal?.abstainCount.toString()}</span>
                    </Col>
                  </Row>
                </Card.Text>
                <ProgressBar variant='info' now={abstainPercentage} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {showVotingButtons ? (
          <Row>
            <Col>
              <div className={classes.numberVotes}>
                YOU HAVE <span>{availableVotes}</span>{' '}
                {availableVotes && Number(availableVotes) === 1 ? (
                  <span>VOTE</span>
                ) : (
                  <span>VOTES</span>
                )}
              </div>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {showVotingButtons ? (
          <Row>
            <Col lg={4} className='d-grid gap-2'>
              <Button
                className={classes.votingButton}
                onClick={() => {
                  setVote(Vote.FOR);
                  setShowVoteModal(true);
                }}
              >
                FOR
              </Button>
            </Col>
            <Col lg={4} className='d-grid gap-2'>
              <Button
                className={classes.votingButton}
                onClick={() => {
                  setVote(Vote.AGAINST);
                  setShowVoteModal(true);
                }}
              >
                AGAINST
              </Button>
            </Col>
            <Col lg={4} className='d-grid gap-2'>
              <Button
                className={classes.votingButton}
                onClick={() => {
                  setVote(Vote.ABSTAIN);
                  setShowVoteModal(true);
                }}
              >
                ABSTAIN
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
        <Row>
          <Col className={classes.section}>
            <h5>Description</h5>
            {proposal?.description && (
              <ReactMarkdown
                className={classes.markdown}
                children={proposal.description}
                remarkPlugins={[remarkBreaks]}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col className={classes.section}>
            <h5>PROPOSED TRANSACTIONS</h5>
            <ol>
              {proposal?.details?.map((d, i) => {
                return (
                  <li key={i} className='m-0'>
                    {linkIfAddress(d.target)}.{d.functionSig}
                    {d.value}({/* <br /> */}
                    {d.callData.split(',').map((content, i) => {
                      return (
                        <Fragment key={i}>
                          <span key={i}>
                            {/* &emsp; */}
                            {linkIfAddress(content)}
                            {d.callData.split(',').length - 1 === i ? '' : ','}
                          </span>
                          {/* <br /> */}
                        </Fragment>
                      );
                    })}
                    )
                  </li>
                );
              })}
            </ol>
          </Col>
        </Row>
        <Row>
          <Col className={classes.section}>
            <h5>PROPOSER</h5>
            {proposal?.proposer && proposal?.transactionHash && (
              <>
                {linkIfAddress(proposal.proposer)} <span>at</span>{' '}
                {transactionLink(proposal.transactionHash)}
              </>
            )}
          </Col>
        </Row>
      </Col>
    </Section>
  );
}
