import { IBeanSeed, useBeanToken } from '../../wrappers/beanToken';
import Bean from '../Bean';
import { Link } from 'react-router-dom';
import classes from './StandaloneBean.module.css';
import { useDispatch } from 'react-redux';
import { setOnDisplayAuctionBeanId } from '../../state/slices/onDisplayAuction';
import { useQuery } from '@apollo/client';
import { beanQuery } from '../../wrappers/subgraph';
import burnt_bean from '../../assets/burnt_bean.svg';
import BeanAttributes from '../../pages/Playground/beans-attributes.json';

interface StandaloneBeanProps {
  beanId: bigint;
}

interface StandaloneBeanWithSeedProps {
  beanId: bigint;
  onLoadSeed?: (seed: IBeanSeed) => void;
  shouldLinkToProfile: boolean;
}

const getPerceptualBrightness = (color: string) => {
  var r = parseInt(color.substring(0, 2), 16);
  var g = parseInt(color.substring(2, 4), 16);
  var b = parseInt(color.substring(4, 6), 16);

  return r * 2 + g * 3 + b;
};

const getBean = (
  beanId: string | bigint,
  seed: string,
  attributes: Array<any>
) => {
  const id = beanId.toString();
  const name = `BEAN ${id}`;
  const description = `Bean ${id} is a member of the BEANSDAO`;
  const image = seed;

  var class1Results = BeanAttributes.beanparts.class1.filter((obj) => {
    return obj.name === attributes[3].value;
  });
  var class1Color = class1Results[0].value;

  var class2results = BeanAttributes.beanparts.class2.filter((obj) => {
    return obj.name === attributes[4].value;
  });
  var class2Color = class2results[0].value;

  var class1Brightness = getPerceptualBrightness(class1Color);
  var class2Brightness = getPerceptualBrightness(class2Color);

  var primary = class1Color;
  var secondary = class2Color;
  if (class1Brightness < class2Brightness) {
    primary = class2Color;
    secondary = class1Color;
  }

  document.documentElement.style.setProperty(
    '--beans-color-primary',
    '#' + primary
  );
  document.documentElement.style.setProperty(
    '--beans-color-secondary',
    '#' + secondary
  );
  return {
    name,
    description,
    image,
    attributes,
  };
};

const StandaloneBean: React.FC<StandaloneBeanProps> = (
  props: StandaloneBeanProps
) => {
  const { beanId } = props;
  const seed = useBeanToken(beanId);
  const bean = seed;
  const dispatch = useDispatch();

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionBeanId(Number(beanId.toString())));
  };

  return (
    <Link
      to={'/bean/' + beanId.toString()}
      className={classes.clickableBean}
      onClick={onClickHandler}
    >
      <Bean
        imgPath={bean ? bean.image : ''}
        alt={bean ? bean.description : 'BEAN'}
      />
    </Link>
  );
};

export const StandaloneBeanWithSeed: React.FC<StandaloneBeanWithSeedProps> = (
  props: StandaloneBeanWithSeedProps
) => {
  const { beanId, onLoadSeed, shouldLinkToProfile } = props;
  const { data } = useQuery(beanQuery(beanId.toString()));
  const dispatch = useDispatch();
  const seed = useBeanToken(beanId);

  if (data && data.bean && data.bean.owner && data.bean.owner.id) {
    if (data.bean.owner.id === '0x0000000000000000000000000000000000000000') {
      document.documentElement.style.setProperty(
        '--beans-color-primary',
        '#f85353'
      );
      document.documentElement.style.setProperty(
        '--beans-color-secondary',
        '#ff9828'
      );
      return <Bean imgPath={burnt_bean} alt='BURNT BEAN' />;
    }
  }

  if (!seed || !beanId || !onLoadSeed || !data) {
    return <Bean imgPath='' alt='BEAN' />;
  }

  const onClickHandler = () => {
    dispatch(setOnDisplayAuctionBeanId(Number(beanId.toString())));
  };

  const { image, description, attributes } = getBean(
    beanId,
    seed.image,
    seed.attributes
  );

  const bean = (
    <Bean imgPath={image} alt={description} attributes={attributes} />
  );
  const beanWithLink = (
    <Link
      to={'/bean/' + beanId.toString()}
      className={classes.clickableBean}
      onClick={onClickHandler}
    >
      {bean}
    </Link>
  );
  return shouldLinkToProfile ? beanWithLink : bean;
};

export default StandaloneBean;
