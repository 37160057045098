import { Col } from 'react-bootstrap';
import Section from '../../layout/Section';
import { useAllProposals, useProposalThreshold } from '../../wrappers/beansDao';
import Proposals from '../../components/Proposals';
import classes from './Governance.module.css';

const GovernancePage = () => {
  const { data: proposals } = useAllProposals();
  const threshold = useProposalThreshold();
  const beansRequired = threshold !== undefined ? threshold + 1n : '...';
  const beanThresholdCopy = `${beansRequired} ${threshold === 0n ? 'Bean' : 'Beans'}`;

  return (
    <Section fullWidth={false} className={classes.section}>
      <Col lg={10} className={classes.wrapper}>
        <h1 className={classes.heading}>BEANSDAO GOVERNANCE</h1>
        <p className={classes.subheading}>
          Beans govern BEANSDAO. Beans can vote on proposals or delegate their
          vote to a third party. A minimum of {beanThresholdCopy} is required to
          submit proposals.
        </p>
        <Proposals proposals={proposals} />
      </Col>
    </Section>
  );
};
export default GovernancePage;
