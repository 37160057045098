import React from 'react';
import { isBeanderBean } from '../../utils/beanderBean';

import _GerminateIcon from '../../assets/icons/Germinate.svg';

import { Image } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import { AuctionState } from '../../state/slices/auction';

interface BeanInfoRowBirthdayProps {
  beanId: bigint;
}

const BeanInfoRowBirthday: React.FC<BeanInfoRowBirthdayProps> = (props) => {
  const { beanId } = props;

  // If the bean is a beanteam bean, use the next bean to get the mint date.
  // We do this because we use the auction start time to get the mint date and
  // beanteam beans do not have an auction start time.
  const beanIdForQuery = isBeanderBean(beanId) ? beanId + 1n : beanId;

  const pastAuctions = useAppSelector(
    (state) => state.pastAuctions.pastAuctions
  );
  if (!pastAuctions || !pastAuctions.length) {
    return <></>;
  }

  const startTime =
    pastAuctions.find((auction: AuctionState, i: number) => {
      const maybeBeanId = auction.activeAuction?.beanId;
      return maybeBeanId ? maybeBeanId === beanIdForQuery : false;
    })?.activeAuction?.startTime || 0n;

  if (!startTime) {
    return <>Error fetching bean birthday</>;
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const birthday = new Date(Number(startTime) * 1000);
  return (
    <div className={'flex flex-row items-center justify-center gap-2'}>
      <Image src={_GerminateIcon} />
      GERMINATED {monthNames[birthday.getUTCMonth()]} {birthday.getUTCDate()},{' '}
      {birthday.getUTCFullYear()}
    </div>
  );
};

export default BeanInfoRowBirthday;
