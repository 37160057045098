import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuctionState } from './auction';
import { getAddress, Hex } from 'viem';

interface PastAuctionsState {
  pastAuctions: AuctionState[];
}

const initialState: PastAuctionsState = {
  pastAuctions: [],
};

const reduxSafePastAuctions = (data: any): AuctionState[] => {
  const auctions = data.data.auctions as any[];
  if (auctions.length < 0) return [];
  const pastAuctions: AuctionState[] = auctions.map((auction) => {
    return {
      activeAuction: {
        amount: BigInt(auction.amount),
        bidder: auction.bidder ? getAddress(auction.bidder.id) : '0x',
        startTime: BigInt(auction.startTime),
        endTime: BigInt(auction.endTime),
        beanId: BigInt(auction.id),
        settled: false,
      },
      bids: auction.bids.map((bid: any) => {
        return {
          beanId: BigInt(auction.id),
          sender: getAddress(bid.bidder.id),
          value: BigInt(bid.amount),
          extended: false,
          transactionHash: bid.id as Hex,
          timestamp: BigInt(bid.blockTimestamp),
        };
      }),
    };
  });
  return pastAuctions;
};

const pastAuctionsSlice = createSlice({
  name: 'pastAuctions',
  initialState: initialState,
  reducers: {
    addPastAuctions: (state, action: PayloadAction<any>) => {
      state.pastAuctions = reduxSafePastAuctions(action.payload);
    },
  },
});

export const { addPastAuctions } = pastAuctionsSlice.actions;

export default pastAuctionsSlice.reducer;
