import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { allColors } from '../../utils/beanBgColors';

export interface AlertModal {
  show: boolean;
  title?: string;
  message?: string;
}

interface ApplicationState {
  stateBackgroundColor: string;
  isCoolBackground: boolean;
  alertModal: AlertModal;
  colorStart: string;
  colorStop: string;
}

const initialState: ApplicationState = {
  stateBackgroundColor: 'black',
  isCoolBackground: true,
  colorStart: allColors[0],
  colorStop: allColors[1],
  // colorStart: '#ffffff',
  // colorStop: '#ffffff',
  alertModal: {
    show: false,
  },
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setStateBackgroundColor: (state, action: PayloadAction<string>) => {
      state.stateBackgroundColor = 'black';
      state.isCoolBackground = true;
    },
    setAlertModal: (state, action: PayloadAction<AlertModal>) => {
      state.alertModal = action.payload;
    },
  },
});

export const { setStateBackgroundColor, setAlertModal } =
  applicationSlice.actions;

export default applicationSlice.reducer;
