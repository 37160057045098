import { Container, Row, Col } from 'react-bootstrap';
import Section from '../../layout/Section';
import classes from './Terms.module.css';
import React from 'react';
import Link from '../../components/Link';

const privacyLink = <Link text='here' url='/privacy' leavesPage={false} />;
const creativeCommonsLink = (
  <Link
    text='https://creativecommons.org/publicdomain/zero/1.0/legalcode'
    url='https://creativecommons.org/publicdomain/zero/1.0/legalcode'
    leavesPage={true}
  />
);

const Terms: React.FC = () => {
  return (
    <>
      <Container fluid='lg' className='privacyContainer'>
        <Row className='justify-content-center'>
          <Col lg={8} className={classes.headerRow}>
            <h1>TERMS OF SERVICE</h1>
            <p>Last Updated: January 15, 2024</p>
          </Col>
        </Row>
        <Section fullWidth={false} className={classes.sectionClass}>
          <Col lg={{ span: 10, offset: 1 }}>
            <div className={classes.headerWrapper}>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <b>Project Overview</b>
                  <p className={classes.paragraphText}>
                    The following terms and conditions constitute a legally
                    binding agreement (together with any terms and conditions
                    incorporated herein by reference, this “Agreement” or the
                    “Terms”) between you (referred to herein as “you”, “your”,
                    or “user”) and, Beans Foundation DAO LLC the sponsor of the
                    Beans NFT Project (“Beans”, “we”, “us”, or “our”), governing
                    your interaction with the Beans NFT (as defined below)
                    collection. By entering into this Agreement, participating
                    in the minting, purchasing, or sale of a Beans NFT (either
                    directly from us or on resale), using our Site,
                    participating in the Beans Discord server, and/or otherwise
                    participating in the Project (as such term is defined
                    below), you expressly acknowledge that you understand this
                    Agreement and accept all of its terms. If you do not agree
                    to the terms set forth in this Agreement, you must not use
                    our Site or participate in the Project.
                  </p>
                  <p className={classes.paragraphText}>
                    PLEASE READ SECTION 15 OF THIS AGREEMENT CAREFULLY, AS IT
                    CONTAINS AN ARBITRATION AGREEMENT WHICH WILL, WITH LIMITED
                    EXCEPTIONS, REQUIRE DISPUTES BETWEEN US TO BE SUBMITTED TO
                    BINDING AND FINAL ARBITRATION UNLESS YOU OPT OUT OF THE
                    ARBITRATION AGREEMENT. YOU WILL ONLY BE PERMITTED TO PURSUE
                    CLAIMS AND SEEK RELIEF AGAINST US ON AN INDIVIDUAL BASIS,
                    NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR
                    REPRESENTATIVE ACTION OR PROCEEDING; AND YOU ARE AGREEING TO
                    MANDATORY INDIVIDUAL ARBITRATION FOR THE RESOLUTION OF
                    DISPUTES AND WAIVING YOUR RIGHT TO A JURY TRIAL ON YOUR
                    CLAIMS. 
                  </p>
                  <p className={classes.paragraphText}>
                    The Beans NFT Project consists of the various collection of
                    Beans NFTs (each, a “Beans NFT”), running on the Ethereum
                    network, named on the Site (now or in the future).
                  </p>
                  <p className={classes.paragraphText}>
                    If you have any questions about these Terms, please contact
                    us by email at beansdaowtf@gmail.com. If you access any
                    Beans Site on a Social Media Platform (e.g., Facebook,
                    Twitter, Discord, or Instagram), you also consent to and
                    agree to abide by the terms and conditions of that social
                    media platform.
                  </p>
                </li>
                <li>
                  <b>NFT Acquisition and Distribution</b>
                  <p className={classes.paragraphText}>
                    <ol className={classes.paragraphListNumbered} type='a'>
                      <p className={classes.paragraphText}>
                        <li>
                          The Beans NFTs are minted through and recorded on the
                          Ethereum Blockchain, and we use smart contracts to
                          allow you to buy, claim, sell, send and receive Beans
                          NFTs. You acknowledge and agree that certain
                          information, including your cryptocurrency wallet
                          address and the transactions you conduct through that
                          cryptocurrency wallet address, may be publicly
                          available and viewable on the Ethereum Blockchain.
                          Your Beans NFTs can be transferred on the Ethereum
                          Blockchain (or another blockchain as technology
                          permits) from one cryptocurrency wallet address to
                          another cryptocurrency wallet address.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          One Beans NFT will be trustlessly auctioned every 24
                          hours, indefinitely. This will be governed by a smart
                          contract that operates as a self-sufficient Bean NFT
                          generation and distribution mechanism. Each time an
                          auction is settled, the settlement transaction will
                          also cause a new Bean NFT to be minted and a new 24
                          hour auction to begin. 100% of auction proceeds are
                          automatically deposited in the Beans DAO treasury.
                          Every tenth Bean NFT will be rewarded to a founding
                          member of the Bean NFT Project (the “Bean Team
                          Members”) on an alternating basis for the five years
                          period beginning on the date the first Bean NFT is
                          auctioned.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          Beans NFTs are minted directly into the cryptocurrency
                          wallet you have connected to our Site and through
                          which you made your payment in ETH. We never hold
                          custody nor take ownership or possession of your Beans
                          NFT. You acknowledge and agree that if you decide to
                          purchase a Beans NFT outside of our Site, such
                          purchases will be entirely at your sole risk.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You are responsible for ensuring your cryptocurrency
                          wallet address has a sufficient amount of the ETH to
                          cover your purchase as well as any gas fees.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You are permitted to sell, trade, or distribute your
                          Beans NFT on any smart contract enabled secondary
                          marketplaces, platforms and exchanges operated by
                          third parties where users can sell, purchase,
                          transfer, list for auction and bid on NFTs (“Secondary
                          Marketplaces”). Note that Beans NFTs may not be
                          compatible with all Secondary Marketplaces, and we
                          make no guarantee about the availability or
                          functionality of any Secondary Marketplace.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          This Agreement only relates to your use of the Site,
                          and does not relate to any other website or
                          Internet-based services, including, but not limited
                          to, Secondary Marketplaces or other websites or
                          browser extensions to which the Site may link
                          (collectively, “Third-Party Sites”). References or
                          links to any Third-Party Site are provided for your
                          convenience and information only, for example, to make
                          it convenient for you to trade your Beans NFT. Such
                          links should not be interpreted as endorsements by us
                          of any Third-Party Site. When you click such link, we
                          may not warn you that you have left our Site and are
                          subject to the terms and conditions and privacy
                          policies of a Third-Party Site. We are not responsible
                          or liable, directly or indirectly, for any damage or
                          loss caused or alleged to be caused by or in
                          connection with your use of or reliance on any
                          content, advertising, products, services, information
                          or other materials on Third-Party Sites.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          Our Site contains Content that we create and may also
                          include Content provided by third parties. We do not
                          monitor, we do not endorse, and we are not liable for
                          any third-party content. There may be some inadvertent
                          accuracies or errors in the Content and we do not
                          guarantee the accuracy, integrity, completeness or
                          quality of the content on our Site or located at third
                          party URLs that may be posted on our Site. Beans is
                          not responsible for the content on any linked site or
                          any link contained in a linked site. We do not endorse
                          or accept responsibility for the content of such
                          third-party sites.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You acknowledge and agree that we are not a party to
                          any agreement or transaction where you trade on any
                          Secondary Marketplace a Beans NFT which was initially
                          purchased via our Site, whether or not a commission or
                          fee is received by us as a consequence of the
                          transaction. We are not liable for any loss incurred
                          by you in connection with any transaction that takes
                          place on Secondary Marketplaces or on any other
                          third-party sites or services.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          We may provide experiences on social media platforms
                          that enable online sharing and collaboration among
                          users who have registered to use them. Any content you
                          post is subject to the terms of use and privacy
                          policies of those platforms and related services. We
                          have no control over such social media platforms or
                          related services.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You are solely responsible for determining and paying
                          (or reimbursing for the payment of) any and all sales,
                          use, value-added and other taxes, duties, and
                          assessments (excluding taxes imposed on our net
                          income) now or hereafter claimed or imposed by any tax
                          or other Governmental Authority associated with your
                          use of our website (collectively, the “Taxes”). You
                          will pay or reimburse us for all Taxes of any
                          jurisdiction (whether national, federal, state, local,
                          foreign or other), including value added taxes and
                          taxes as required by international tax treaties,
                          customs or other import or export taxes, and amounts
                          levied in lieu thereof based on charges set, services
                          performed or payments made hereunder, as are now or
                          hereafter may be imposed under the authority of any
                          national, federal, state, local, foreign or other
                          taxing jurisdiction; and will not be entitled to
                          deduct the amount of any such Taxes or amounts levied
                          in lieu thereof from payments (including, without
                          limitation, gas fees) made to us pursuant to this
                          Agreement.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          If you sell your Beans NFT via a secondary
                          marketplace, such transaction may be subject to a
                          transaction fee determined by us, in our sole
                          discretion (a “Transaction Fee”) which Transaction Fee
                          may be automatically deducted from the sale proceeds
                          you receive for your Beans NFT. All Transaction Fees
                          are applied to the final sale price of the Beans NFT
                          and are collected and distributed to us at the time of
                          sale through smart contracts on the Ethereum
                          Blockchain.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          The Site may contain a description of anticipated
                          future milestones for the Project (the “Roadmap”). We
                          will endeavor to implement the milestones listed in
                          the Roadmap in accordance with the corresponding
                          dates. However, the Roadmap is merely suggestive and
                          there are no guarantees that all milestones listed in
                          the Roadmap will be fulfilled, nor are there any
                          guarantees that such milestones will be accomplished
                          by the indicated dates. We may delay, defer, or choose
                          not to implement certain milestones on the Roadmap, in
                          our sole and absolute discretion and without any
                          recourse to you. You recognize that the Project
                          remains in “beta” and the blockchain industry and
                          regulatory landscape is unsettled and constantly
                          evolving and that certain milestones may become
                          unfeasible or undesirable to implement.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          All purchases of Beans NFTs, as well as any associated
                          charges, are non-refundable. This no-refund policy
                          shall apply at all times regardless of your decision
                          to terminate usage of the Beans NFT, any disruption to
                          the operations of any components of the Beans NFT, or
                          any other reason whatsoever.
                        </li>
                      </p>
                    </ol>
                  </p>
                </li>
                <li>
                  <b>Intellectual Property Rights</b>
                  <p className={classes.paragraphText}>
                    <ol className={classes.paragraphListNumbered} type='a'>
                      <p className={classes.paragraphText}>
                        <li>
                          By acquiring lawful ownership to a Beans NFT, or by
                          using, distributing, reproducing, displaying,
                          performing, modifying, or creating derivative works of
                          any associated artwork, images, video, content or
                          other works of authorship linked to a specific Beans
                          NFT which was developed by Beans (“NFT Media”), you
                          agree to these NFT license terms and conditions (the
                          “License Terms”). When you lawfully own and possess a
                          Beans NFT, you own all personal property rights to the
                          NFT underlying the Beans NFT (e.g., the right to
                          freely sell, transfer, or otherwise dispose of that
                          Beans NFT, as well as the right to exercise associated
                          DAO membership rights).{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          Beans hereby makes all NFT Media linked to Beans NFTs
                          freely available for use by the public under the terms
                          of the Creative Commons CC0 1.0 Universal, a copy of
                          which is available here – {creativeCommonsLink} (“CC0
                          Agreement”).
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          By using our Site and participating in the Project,
                          you expressly acknowledge and agree that the Beans
                          NFTs and all content, photographs, computer code,
                          smart contract code, sound or videos, media, images,
                          formulas, graphics, webinars, training materials,
                          products, services and/or other information and
                          materials, and selection and arrangements associated
                          with the Project and/or located on our Site is
                          copyrighted work under the United States and other
                          copyright laws, and is the property of or licensed by
                          Beans Foundation DAO LLC, made available on our Site
                          or any information, materials, and content transferred
                          via a downloadable file or link by us or other third
                          parties (the “Content”). All trademarks, service
                          marks, and trade names (collectively, the “Marks”) are
                          trademarks or registered trademarks of and are
                          proprietary to Beans Foundation DAO LLC or other
                          respective owners that have granted Beans Foundation
                          DAO LLC the right to use such Marks. We reserve all
                          rights that are not specifically granted to users.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          Other than the rights to the Beans NFT and NFT Media,
                          nothing herein gives you any rights to any other
                          trademarks or other intellectual property rights
                          belonging to Beans Foundation DAO LLC including,
                          without limitation, the Beans Foundation DAO LLC names
                          and marks, and the associated logos. All of these
                          rights are expressly reserved in the name of Beans
                          Foundation DAO LLC. Nothing herein shall restrict our
                          right to use, copy, and display any individual Beans
                          NFT or NFT Media for our own uses.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You may choose to submit comments, bug reports, ideas
                          or other feedback about the Site or the Beans NFT
                          project, including, without limitation, about how to
                          improve the Site (collectively, “Feedback”). By
                          submitting any Feedback, you agree that we are free to
                          use such Feedback in any way we choose without
                          additional compensation to you and you hereby grant us
                          a perpetual, irrevocable, nonexclusive, worldwide
                          license to incorporate and use the Feedback for any
                          purpose. You shall not, under any circumstances or for
                          any reason, modify, publish, transmit, participate in
                          the transfer or sale of, reproduce, create derivative
                          works of, distribute, publicly perform, publicly
                          display, or in any way exploit any of the materials or
                          Content in whole or in part, except as set forth in
                          these Terms of Service or without our express prior
                          written consent, which consent may be granted,
                          withheld, conditioned and/or delayed in our sole and
                          absolute discretion. Modification of the Content or
                          use of the Content for any other purpose is a
                          violation of the copyright and other proprietary
                          rights of Beans, as well as other authors who created
                          the materials, and may be subject to monetary damages
                          and penalties.
                        </li>
                      </p>
                    </ol>
                  </p>
                </li>
                <li>
                  <b>DAO Membership</b>
                  <p className={classes.paragraphText}>
                    <ol className={classes.paragraphListNumbered} type='a'>
                      <p className={classes.paragraphText}>
                        <li>
                          Your ownership of a Beans NFT will provide you with
                          membership to the Beans DAO, subject to your agreement
                          to and compliance with the Beans Foundation DAO LLC
                          Operating Agreement and other agreements, charters,
                          and terms which may be implemented from time to time.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          The licenses and membership granted to you hereunder
                          shall automatically terminate and all rights shall
                          return to Beans if: (i) at any time you sell, trade,
                          donate, give away, transfer, burn, or otherwise
                          dispose of your Beans NFT for any reason; (ii) you
                          breach this Agreement, the Beans Foundation DAO LLC
                          Operating Agreement, or any other agreements,
                          charters, and terms which may be implemented from time
                          to time.; (iii) you have a trustee, receiver or
                          similar party appointed for your property, become
                          insolvent, acknowledge your insolvency in any manner,
                          make an assignment for the benefit of your creditors,
                          or file a petition of bankruptcy; (iv) you engage in
                          any unlawful business practice related to the Beans
                          NFT; (iv) you initiate any legal actions against the
                          Beans Foundation DAO LLC and/or its members, officers,
                          directors, affiliates, agents, attorneys and
                          employees.
                        </li>
                      </p>
                    </ol>
                  </p>
                </li>
                <li>
                  <b>Representations and Warranties</b>
                  <p className={classes.paragraphText}>
                    By participating in the Project, you expressly represent,
                    warrant and acknowledge the following:
                  </p>
                  <p className={classes.paragraphText}>
                    <ol className={classes.paragraphListNumbered} type='a'>
                      <p className={classes.paragraphText}>
                        <li>
                          You are not a citizen or resident of a state, country,
                          territory or other jurisdiction that is embargoed by
                          the United States or where your use of the Site would
                          be illegal or otherwise violate any applicable law.
                          Specifically, you represent that you are not located
                          in, organized in, or a resident of Cuba, Iran, Syria,
                          North Korea, Russia, the Crimea region, Venezuela, or
                          any other jurisdiction where Applicable Law prohibits
                          you from accessing or using the Site; and you
                          represent that you are not, and have never been, named
                          on the Office of Foreign Asset Control of the U.S.
                          Department of the Treasury’s Specially Designated and
                          Blocked Persons List.{' '}
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You have the necessary technical expertise and ability
                          to review and evaluate the security, integrity and
                          operation of any transactions that you engage in on
                          the Site. Further, you acknowledge that you have a
                          sufficient understanding of the functionality, usage,
                          storage, transmission mechanisms and other material
                          characteristics of, blockchain technology, NFTs,
                          digital assets, digital wallets and cryptocurrencies
                          to understand this Agreement and to understand the
                          risks and implications of purchasing a Beans NFT.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You have the knowledge, experience, understanding,
                          professional advice and information to make your own
                          evaluation of the merits, risks and applicable
                          compliance requirements under Applicable Law of
                          acquiring an NFT, and assume all financial risks
                          associated with acquiring NFTs and/or otherwise
                          engaging in transactions on the Ethereum Blockchain.
                          You know, understand and accept the risks associated
                          with your Ethereum Address, the Ethereum Blockchain,
                          ETH and NFTs.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          You agree not to circumvent, disable or otherwise
                          interfere with security-related features of the Site
                          that prevent or restrict use or copying of any Content
                          or enforce limitations on use of the Content. By
                          accessing our Site, you agree not to use any data
                          mining, robots, scraping or similar data gathering or
                          extraction methods.
                        </li>
                      </p>
                      <p className={classes.paragraphText}>
                        <li>
                          BY ACCESSING THE SITE, YOU EXPRESSLY REPRESENT AND
                          WARRANT THAT YOU (i) HAVE READ AND UNDERSTAND THIS
                          AGREEMENT, (ii) ARE AT LEAST 18 YEARS OF AGE AND OF
                          LEGAL AGE TO ENTER INTO A BINDING AGREEMENT, AND (iii)
                          THAT YOU ACCEPT THIS AGREEMENT AND AGREE THAT YOU ARE
                          LEGALLY BOUND BY ITS TERMS.
                        </li>
                      </p>
                    </ol>
                  </p>
                </li>
                <li>
                  <b>Restrictions</b>
                  <p className={classes.paragraphText}>
                    You are solely responsible for your own conduct while
                    accessing or using the Site and using our Discord, and for
                    any consequences thereof. You agree to use the Site and our
                    Discord only for purposes that are legal, proper and in
                    accordance with this Agreement and any Applicable Laws or
                    regulations. By way of example, and not as a limitation, you
                    may not, and may not allow any third party acting on your
                    behalf to: (i) send, upload, distribute or disseminate any
                    unlawful, defamatory, harassing, abusive, fraudulent,
                    hateful, violent, obscene, or otherwise objectionable
                    content; (ii) distribute viruses, worms, defects, Trojan
                    horses, corrupted files, hoaxes, or any other items of a
                    destructive or deceptive nature; (iii) impersonate another
                    person; (iv) upload, post, transmit or otherwise make
                    available through the Site or Discord any content that
                    infringes the intellectual property or proprietary rights of
                    any party or otherwise violates the legal rights of others;
                    (v) engage in, promote, or encourage illegal activity
                    (including, without limitation, money laundering); (vi)
                    interfere with other users’ use of the Site or Discord;
                    (vii) use the Site or Discord for any unauthorized
                    commercial purpose; (viii) modify, adapt, translate, or
                    reverse engineer any portion of the Site or Discord; (ix)
                    remove any copyright, trademark or other proprietary rights
                    notices contained in or on the Site or Discord or any part
                    of the Site and/or Discord; (x) use any technology to
                    collect information about the Site or Discord for any
                    unauthorized purpose; (xi) access or use the Site or Discord
                    for the purpose of creating a product or service that is
                    competitive with any of our products or services; (xii) use
                    any Content in movies, videos or other forms of media,
                    except to the limited extent that such use is expressly
                    permitted by this Agreement or solely for your own personal,
                    non-commercial use; (xiii) sell, distribute for commercial
                    gain (including, without limitation, giving away in the
                    hopes of eventual commercial gain), or otherwise
                    commercialize merchandise that includes, contains, or
                    consists of any Content; (xiv) attempt to trademark,
                    copyright or otherwise acquire any intellectual property
                    rights in or to any Content; and/or (xv) otherwise utilize
                    any Content for your or any third party’s commercial
                    benefit. If you engage in any of the activities prohibited
                    by this section, we may, at our sole and absolute
                    discretion, without notice to you, and without limiting any
                    of our other rights or remedies at law or in equity,
                    immediately suspend or terminate your user account.
                  </p>
                </li>
                <li>
                  <b>User Contributions, Content Standards, and Copyright</b>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <li>
                      <p className={classes.paragraphText}>
                        User Contributions:
                      </p>
                      <ol className={classes.paragraphListNumbered} type='a'>
                        <p className={classes.paragraphText}>
                          <li>
                            The Project allows for the use of interactive
                            features (collectively, "Interactive Services") that
                            allow users to post, submit, publish, display, or
                            transmit to other users or other persons
                            (hereinafter, "post") content or materials
                            (collectively, "User Contributions") on or through
                            the Site, Beans Discord server, and/or other means.
                            All User Contributions must comply with the Content
                            Standards set out in these Terms of Service.{' '}
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Any User Contribution you post to the Site will be
                            considered non-confidential and non-proprietary. By
                            providing any User Contribution, you grant us and
                            our affiliates and service providers, and each of
                            their and our licensees, successors, and assigns the
                            right to use, reproduce, modify, perform, display,
                            distribute, and otherwise disclose to third parties
                            any such material for any purpose.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            You represent and warrant that:
                            <ul className={classes.paragraphList}>
                              <li>
                                You own or control all rights in and to the User
                                Contributions and have the right to grant the
                                license granted above to us and our affiliates
                                and service providers, and each of their and our
                                respective licensees, successors, and assigns.
                              </li>
                              <li>
                                All of your User Contributions do and will
                                comply with these Terms of Service.
                              </li>
                            </ul>
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            You understand and acknowledge that you are
                            responsible for any User Contributions you submit or
                            contribute, and you, not Beans, have full
                            responsibility for such content, including its
                            legality, reliability, accuracy, and
                            appropriateness.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            We are not responsible or liable to any third party
                            for the content or accuracy of any User
                            Contributions posted by you or any other user of the
                            Site, Discord, or Social Media Platforms.
                          </li>
                        </p>
                      </ol>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Monitoring and Enforcement; Termination. We have the
                        right to:
                      </p>
                      <ol className={classes.paragraphListNumbered} type='a'>
                        <p className={classes.paragraphText}>
                          <li>
                            Remove or refuse to post any User Contributions for
                            any or no reason in our sole discretion.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Take any action with respect to any User
                            Contribution that we deem necessary or appropriate
                            in our sole discretion, including if we believe that
                            such User Contribution violates the Terms, including
                            the Content Standards, infringes any intellectual
                            property right or other right of any person or
                            entity, threatens the personal safety of users of
                            the Site or the public, or could create liability
                            for Beans Foundation DAO LLC.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Disclose information about you to any third party
                            who claims that material posted by you violates
                            their rights, including their intellectual property
                            rights or their right to privacy.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Take appropriate legal action, including without
                            limitation, referral to law enforcement, for any
                            illegal or unauthorized use of the Site, Discord, or
                            Social Media Platforms.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Terminate or suspend your access to all or part of
                            the Site, Discord, or Social Media Platforms for any
                            or no reason, including without limitation, any
                            violation of these Terms.
                          </li>
                        </p>
                      </ol>
                      <p className={classes.paragraphText}>
                        Without limiting the foregoing, we have the right to
                        cooperate fully with any law enforcement authorities or
                        court order requesting or directing us to disclose the
                        identity or other information of anyone posting any
                        materials on or through the Website. YOU WAIVE AND HOLD
                        HARMLESS BEANS FOUNDATION DAO LLC AND ITS AFFILIATES,
                        LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS
                        RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
                        PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF,
                        INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
                        AUTHORITIES.
                      </p>
                      <p className={classes.paragraphText}>
                        Note, we cannot review material before it is posted on
                        the Site, Discord, or Social Media Platforms and cannot
                        ensure prompt removal of objectionable material after it
                        has been posted. Accordingly, we assume no liability for
                        any action or inaction regarding transmissions,
                        communications, or content provided by any user or third
                        party. We have no liability or responsibility to anyone
                        for performance or nonperformance of the activities
                        described in this section.
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Content Standards. These content standards apply to any
                        and all User Contributions and use of Interactive
                        Services. User Contributions must in their entirety
                        comply with all applicable federal, state, local, and
                        international laws and regulations. Without limiting the
                        foregoing, User Contributions must not:
                      </p>
                      <ol className={classes.paragraphListNumbered} type='a'>
                        <p className={classes.paragraphText}>
                          <li>
                            Contain any material that is defamatory, obscene,
                            indecent, abusive, offensive, harassing, violent,
                            hateful, inflammatory, or otherwise objectionable.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Promote sexually explicit or pornographic material,
                            violence, or discrimination based on race, sex,
                            religion, nationality, disability, sexual
                            orientation, or age.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Infringe any patent, trademark, trade secret,
                            copyright, or other intellectual property or other
                            rights of any other person.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Violate the legal rights (including the rights of
                            publicity and privacy) of others or contain any
                            material that could give rise to any civil or
                            criminal liability under applicable laws or
                            regulations or that otherwise may be in conflict
                            with these Terms and our Privacy Policy.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>Be likely to deceive any person.</li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Promote any illegal activity, or advocate, promote,
                            or assist any unlawful act.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Cause annoyance, inconvenience, or needless anxiety
                            or be likely to upset, embarrass, alarm, or annoy
                            any other person.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Impersonate any person, or misrepresent your
                            identity or affiliation with any person or
                            organization.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Involve commercial activities or sales, such as
                            contests, sweepstakes, and other sales promotions,
                            barter, or advertising.
                          </li>
                        </p>
                        <p className={classes.paragraphText}>
                          <li>
                            Give the impression that they emanate from or are
                            endorsed by us or any other person or entity, if
                            this is not the case.
                          </li>
                        </p>
                      </ol>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Copyright Infringement. If you believe that any User
                        Contributions violate your copyright, please submit a
                        notification to beansdaowtf@gmail.com for instructions
                        on sending us a notice of copyright infringement. It is
                        the policy of Beans to terminate the user accounts of
                        repeat infringers.
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <b>Disclaimers and Limitation of Liability</b>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <p className={classes.paragraphText}>
                      <li>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR ACCESS TO
                        AND USE OF THE SITE, AND PARTICIPATION IN THE PROJECT IS
                        AT YOUR SOLE RISK, AND THAT THE SITE IS PROVIDED “AS IS”
                        AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND,
                        WHETHER EXPRESS OR IMPLIED. TO THE FULLEST EXTENT
                        PERMISSIBLE PURSUANT TO APPLICABLE LAW, WE MAKE NO
                        EXPRESS WARRANTIES AND HEREBY DISCLAIM ALL IMPLIED
                        WARRANTIES REGARDING THE SITE, DISCORD AND/OR THE
                        PROJECT AS A WHOLE, AND ANY PART OF EACH (INCLUDING,
                        WITHOUT LIMITATION, THE SITE, ANY SMART CONTRACT, OR ANY
                        EXTERNAL WEBSITES), INCLUDING THE IMPLIED WARRANTIES OF
                        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                        NON-INFRINGEMENT, CORRECTNESS, ACCURACY, OR RELIABILITY.
                        WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE,
                        OUR SUBSIDIARIES, AFFILIATES, AND LICENSORS DO NOT
                        REPRESENT OR WARRANT TO YOU THAT: (i) YOUR ACCESS TO OR
                        USE OF THE SITE WILL MEET YOUR REQUIREMENTS, (ii) YOUR
                        ACCESS TO OR USE OF THE SITE WILL BE UNINTERRUPTED,
                        TIMELY, SECURE OR FREE FROM ERROR, (iii) USAGE DATA
                        PROVIDED THROUGH THE SITE WILL BE ACCURATE, (iv) THE
                        SITE OR ANY CONTENT OR FEATURES MADE AVAILABLE ON OR
                        THROUGH THE SITE AND/OR THE DISCORD ARE FREE OF VIRUSES
                        OR OTHER HARMFUL COMPONENTS, OR (IV) THAT ANY DATA THAT
                        YOU DISCLOSE WHEN YOU USE THE SITE AND/OR DISCORD WILL
                        BE SECURE. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
                        OF IMPLIED WARRANTIES IN CONTRACTS WITH CONSUMERS, SO
                        SOME OR ALL OF THE ABOVE EXCLUSIONS MAY NOT APPLY TO
                        YOU.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        YOU ACCEPT THE INHERENT SECURITY RISKS OF PROVIDING
                        INFORMATION AND DEALING ONLINE OVER THE INTERNET AND
                        AGREE THAT WE HAVE NO LIABILITY OR RESPONSIBILITY FOR
                        ANY BREACH OF SECURITY UNLESS IT IS DUE TO OUR WILLFUL
                        MISCONDUCT.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY
                        LOSSES YOU INCUR AS THE RESULT OF YOUR USE OF OUR SITE
                        OR THE ETHEREUM NETWORK NOR DO WE HAVE ANY CONTROL OVER,
                        AND MAKE NO GUARANTEES REGARDING, ANY SMART CONTRACTS,
                        INCLUDING, WITHOUT LIMITATION, THE FUNCTIONALITY OF OUR
                        SMART CONTRACT
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        YOU UNDERSTAND AND AGREE THAT WE WILL NOT BE LIABLE TO
                        YOU OR TO ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL,
                        SPECIAL, CONSEQUENTIAL, PUNITIVE OR EXEMPLARY DAMAGES
                        WHICH YOU MAY INCUR, HOWSOEVER CAUSED AND UNDER ANY
                        THEORY OF LIABILITY, INCLUDING, WITHOUT LIMITATION, ANY
                        LOSS OF PROFITS (WHETHER INCURRED DIRECTLY OR
                        INDIRECTLY), LOSS OF GOODWILL OR BUSINESS REPUTATION,
                        LOSS OF DATA, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR
                        SERVICES, OR ANY OTHER INTANGIBLE LOSS, EVEN IF WE HAVE
                        BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        YOU AGREE THAT OUR TOTAL, AGGREGATE LIABILITY TO YOU FOR
                        ANY AND ALL CLAIMS ARISING OUT OF OR RELATING TO THIS
                        AGREEMENT OR YOUR ACCESS TO OR USE OF (OR YOUR INABILITY
                        TO ACCESS OR USE) ANY PORTION OF THE SITE, WHETHER IN
                        CONTRACT, TORT, STRICT LIABILITY, OR ANY OTHER LEGAL
                        THEORY, IS LIMITED TO THE GREATER OF (a) THE AMOUNTS YOU
                        ACTUALLY PAID US UNDER THIS AGREEMENT IN THE 12 MONTH
                        PERIOD PRECEDING THE DATE THE CLAIM AROSE, OR (b) $500.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE SITE AND
                        DISCORD AVAILABLE TO YOU AND ENTERED INTO THIS AGREEMENT
                        IN RELIANCE UPON YOUR REPRESENTATIONS AND WARRANTIES,
                        THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY
                        SET FORTH HEREIN. WE WOULD NOT BE ABLE TO PROVIDE THE
                        SITE AND DISCORD TO YOU WITHOUT THESE LIMITATIONS. WE
                        WILL NOT BE RESPONSIBLE FOR THE LOSS OF ANY
                        CRYPTOCURRENCY OR NFTS IN THE EVENT OUR SITE, DISCORD,
                        OR ANY MESSAGING OR SOCIAL MEDIA PLATFORM UTILIZED BY
                        BEANS IS COMPROMISED. YOU ACKNOWLEDGE THAT YOU ARE
                        RESPONSIBLE FOR TAKING DILIGENT STEPS TO INDEPENDENTLY
                        VERIFY THE LEGITIMACY OF HYPERLINKS OR OTHER
                        COMMUNICATIONS BEFORE CLICKING OR LINKING YOUR
                        CRYPTOCURRENCY WALLET.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU OR ANY OTHER
                        PARTY FOR ERRORS OR FAILURES TO EXECUTE ANY PURCHASE OF
                        YOUR BEANS NFT, INCLUDING, WITHOUT LIMITATION, ERRORS OR
                        FAILURES CAUSED BY: (i) YOUR FAILURE TO FOLLOW OUR
                        INSTRUCTIONS; (ii) ANY LOSS OF CONNECTION TO OUR SITE
                        UNLESS CAUSED BY OUR GROSS NEGLIGENCE; (iii) A FAILURE
                        OF ANY SOFTWARE OR DEVICE USED BY YOU TO PURCHASE YOUR
                        BEANS NFT; OR (iv) FOR ANY OTHER FAILURE TO EXECUTE YOUR
                        BEANS NFT PURCHASE OR FOR ERRORS OR OMISSIONS IN
                        CONNECTION WITH THIS ACTIVITY UNLESS CAUSED BY OUR GROSS
                        NEGLIGENCE. BEANS MAKES NO REPRESENTATION THAT BEANS
                        NFTS ARE LEGAL OR APPROPRIATE FOR USE OUTSIDE OF THE
                        UNITED STATES OR THAT BEANS NFTS OR MAY BE EXPORTED FROM
                        THE UNITED STATES OR FOR IMPORT INTO ANY FOREIGN
                        COUNTRY. YOU ARE SOLELY RESPONSIBLE FOR COMPLYING WITH
                        ALL IMPORT AND EXPORT LAWS AND REGULATIONS AND ALL
                        APPLICABLE LAWS OF ANY JURISDICTION INSIDE AND OUTSIDE
                        OF THE UNITED STATES FROM WHICH YOU MAY ACCESS THE SITE.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        THE SITE HAS BEEN COMPILED IN GOOD FAITH BY BEANS
                        FOUNDATION DAO, LLC HOWEVER, NO REPRESENTATION IS MADE
                        AS TO THE COMPLETENESS OR ACCURACY OF THE INFORMATION IT
                        CONTAINS. IN PARTICULAR, YOU SHOULD BE AWARE THAT THIS
                        INFORMATION MAY BE INCOMPLETE, MAY CONTAIN ERRORS OR MAY
                        HAVE BECOME OUT OF DATE.
                      </li>
                    </p>
                  </ol>
                </li>
                <li>
                  <b>Assumption of Risk</b>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <p className={classes.paragraphText}>
                      <li>
                        Please be aware that prices of NFTs are extremely
                        volatile and fluctuations in the prices of other NFTs
                        and/or cryptocurrencies may impact the price of your
                        NFT. NFTs have no extrinsic value and should NOT be
                        considered an investment. Ethereum-based NFTs may become
                        worthless or obsolete. We cannot guarantee that any
                        purchasers of Beans NFTs will retain their original
                        value, as their value is inherently subjective and
                        factors occurring outside of the Site may materially
                        impact the value and desirability of any particular NFT.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        By purchasing, holding and using a Beans NFT, you
                        expressly acknowledge and assume all risks associated
                        therewith including, but not limited to: (i) forgotten
                        passwords; (ii) inability to access or use your digital
                        wallet for any reason; (iii) mistyped addresses or
                        improperly constructed instructions when transmitting or
                        receiving Beans NFTs or any cryptocurrency; (iv) errors
                        in the smart contract that mints Beans NFTs; (v) errors
                        in the Beans NFTs; (vi) errors in our Site; (vii)
                        inability to access or transfer a Beans NFT; (viii)
                        inability to use, access, copy, or display any Content;
                        (ix) blockchain malfunctions or other technical errors;
                        (x) server failure or data loss; (xi) telecommunications
                        failures; (xii) unfavorable regulatory determinations or
                        actions (including with respect to NFTs or
                        cryptocurrencies in general); (xiii) taxation of NFTs or
                        cryptocurrencies; (xiv) uninsured losses; (xv)
                        unanticipated risks; (xvi) volatility risks in the value
                        of Beans NFTs and cryptocurrencies; (xvii) cybersecurity
                        attacks; (xviii) weaknesses in Beans’ security; (xix)
                        personal information disclosure; (xx) unauthorized
                        access to applications; and/or (xxi) unauthorized
                        third-party activities, including, without limitation,
                        the introduction of viruses or other malicious code; and
                        the use of phishing, sybil attacks, 51% attacks,
                        bruteforcing, changes to the protocol rules of the
                        Ethereum Blockchain (i.e., “forks”), or other means of
                        attack that affect, in any way, the NFTs or the Content.{' '}
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        Any payments made to us via the Site will be effectuated
                        through the Ethereum Blockchain. We have no control over
                        these payments or transactions, nor do we have the
                        ability to reverse any payments or transactions on the
                        Ethereum Blockchain. You acknowledge that Beans has no
                        liability to you or to any third party for any claims or
                        damages that may arise as a result of your payment not
                        reaching us, including, but not limited to, instances
                        where your payment was not properly processed on the
                        Ethereum Blockchain. It is solely your responsibility to
                        confirm that your payment for a Beans NFT has been
                        accepted. We do not provide refunds for any purchases
                        that you might make on or through the Site. This
                        no-refund policy shall apply at all times regardless of
                        your decision to terminate usage of any Beans NFT
                        purchased through our Site, any disruption to the
                        operations of any components of the NFT, or any other
                        reason whatsoever. By your continued use of our Site,
                        you expressly release us, and any of our Affiliates,
                        from any and all liability or responsibility for any
                        purchases made by you, or by someone acting on your
                        behalf, with respect to your Beans NFT(s).
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        Nothing on this Site, and nothing pertaining to your
                        participation in the Project, is intended to be an
                        offering of securities in any jurisdiction nor does it
                        constitute an offer to purchase shares, securities, or
                        other financial products. It remains your sole
                        responsibility to assure that the purchase of a Beans
                        NFT is in compliance with laws and regulations in your
                        jurisdiction.{' '}
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        You assume all risks associated with using an
                        Internet-based currency, including, but not limited to,
                        the risk of hardware, software and Internet connections,
                        the risk of malicious software introduction, and the
                        risk that third parties may obtain unauthorized access
                        to information stored within your wallet.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        NFTs, cryptocurrencies and blockchain technology are
                        relatively new and the regulatory landscape is
                        unsettled.  New regulations could negatively impact such
                        technologies impacting the value of your Beans NFT.  You
                        understand and accept all risk in that regard, and
                        further understand that we bear no responsibility to
                        notify you of any changes with respect to the regulatory
                        landscape of NFTs, cryptocurrencies and/or blockchain
                        technology.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        Beans is not responsible for any transaction between you
                        and a third party (including, but not limited to,
                        Third-Party Site or Secondary Marketplaces) and shall
                        have no liability in connection with any such
                        transaction.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        You assume all risk for any disruptions or other issues
                        that may occur on the Ethereum Blockchain and/or impacts
                        Ethereum or NFT functionality.{' '}
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        Beans cannot and does not represent or warrant that any
                        Beans NFT, or its supporting systems or technology, is
                        reliable, current, or error-free, meets your
                        requirements, or that defects in the Beans NFT, or its
                        supporting systems or technology, will be corrected.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        We are not responsible if your Beans NFT or Content
                        becomes inaccessible to you for any reason, or for any
                        modifications or changes to your Beans NFT or any
                        Content including any deletion, removal, or
                        inaccessibility on our Site or otherwise.{' '}
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        In addition to assuming all the above risks, you
                        acknowledge that you have obtained sufficient
                        information to make an informed decision to license the
                        Beans NFT and that you understand and agree that you are
                        solely responsible for determining the nature, potential
                        value, suitability, and appropriateness of these risks
                        for yourself.
                      </li>
                    </p>
                  </ol>
                </li>
                <li>
                  <b>Indemnification and Release</b>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <p className={classes.paragraphText}>
                      <li>
                        You agree to indemnify, defend and hold harmless Beans
                        Foundation DAO LLC and its affiliates, licensors,
                        suppliers and sponsors, and each of their respective
                        directors, officers, members, shareholders, managers,
                        agents, contractors, partners, advisors, employees,
                        licensors, content creators, and suppliers
                        (collectively, the “Beans Indemnified Parties”) from and
                        against any claim, liability, loss, damage (actual and
                        consequential) of any kind or nature, suit, judgment,
                        litigation cost, and attorneys’ fees (collectively,
                        “Liabilities”) arising out of or in any way related to
                        (i) your breach of this Agreement, (ii) your misuse of
                        the Site, Discord, or Social Media Platform (iii) your
                        violation of any intellectual property right or other
                        rights of another party, and/or (iv) your violation of
                        Applicable Laws, rules or regulations in connection with
                        your access to or use of the Site or Discord. This
                        indemnity shall be applicable without regard to the
                        negligence of any party, including any indemnified
                        person, and shall survive any termination of this
                        Agreement.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        To the maximum extent permitted by Applicable Law, you
                        hereby irrevocably and unconditionally release and waive
                        all claims against any Beans Indemnified Parties from
                        any and all Liabilities of every kind and nature,
                        arising out of or in any way connected with your use of
                        the Site or purchase of a Beans NFT. To the extent that
                        you do have any claims against Beans Foundation DAO LLC,
                        you agree that: (i) any and all disputes, claims and
                        causes of action against Beans Foundation DAO LLC
                        arising out of or connected with your use of the Site
                        and/or the Discord shall be resolved individually,
                        without resort to any form of class action; and (ii) any
                        and all claims, judgments and awards shall be limited to
                        actual and documented damages and out-of-pocket costs
                        incurred, but shall in no event include attorneys’ fees
                        or litigation costs (e.g., court filing fees and related
                        costs).
                      </li>
                    </p>
                  </ol>
                </li>
                <li>
                  <b>Termination</b>
                  <p className={classes.paragraphText}>
                    We reserve the right, in our sole and absolute discretion,
                    to refuse, suspend, restrict or terminate your access to the
                    Site, Social Media Platform, and/or the Discord, or any
                    portion thereof, without notice to you and for any reason or
                    no reason. You acknowledge that we have the right, but not
                    the obligation, exercisable in our sole and absolute
                    discretion, to suspend or terminate your access to all or
                    part of the Site and/or our Discord: (i) at the request of
                    law enforcement or other government agencies; (ii) if the
                    Site or Discord are discontinued or materially modified;
                    (iii) upon the occurrence of any technical or security
                    issues or problems; (iv) if you engage in any conduct that
                    we believe, in our sole and absolute discretion, violates
                    any provision of this Agreement or other incorporated
                    agreements or guidelines or violates the rights of Beans or
                    third parties; or (v) upon any breach by you of this
                    Agreement. The exercise of our right to restrict or
                    terminate your access to the Site and/or the Discord,
                    whether or not ultimately determined to be justified, will
                    not, under any circumstances, constitute our breach under
                    this Agreement. Neither the exercise or nor the failure to
                    exercise such right to restrict or terminate your access to
                    the Site and/or our Discord will constitute an election of
                    remedies or limit us in any manner in the enforcement of any
                    other remedies available to us.
                  </p>
                </li>
                <li>
                  <b>Modifications</b>
                  <p className={classes.paragraphText}>
                    We reserve the right to make changes to the Terms, at any
                    time and from time to time, at our sole and absolute
                    discretion. If any changes are made to this Agreement, we
                    will provide you with written notice of such changes by
                    sending you an e-mail, providing a notice on our Site, or
                    updating the date at top of this Agreement. Any changes made
                    to this Agreement will go into effect on the date they are
                    made, and your continued access to the Site, or use after
                    the Terms have been updated with constitute your binding
                    acceptance of these updates. If you do not agree to the
                    revised Terms, you may not access or use our Site and/or our
                    Discord.
                  </p>
                </li>
                <li>
                  <b>Severability</b>
                  <p className={classes.paragraphText}>
                    If any term or provision of this Agreement is deemed
                    invalid, illegal, or unenforceable in any jurisdiction, such
                    invalidity, illegality, or unenforceability shall not affect
                    any other term or provision of this Agreement or invalidate
                    or render unenforceable such term or provision in any other
                    jurisdiction.
                  </p>
                </li>
                <li>
                  <b>Governing Law</b>
                  <p className={classes.paragraphText}>
                    This Agreement and all matters related to it and/or any
                    Beans NFT shall be governed by, construed, and enforced in
                    accordance with the laws of the Marshall Islands, exclusive
                    of conflict or choice of law rules.
                  </p>
                </li>
                <li>
                  <b>Dispute Resolution; Arbitration</b>
                  <p className={classes.paragraphText}>
                    Please read the arbitration agreement in this Section (
                    <b>“Arbitration Agreement”</b>) carefully. It contains
                    procedures for MANDATORY BINDING ARBITRATION AND A CLASS
                    ACTION WAIVER.
                  </p>
                  <p className={classes.paragraphText}>
                    Applicability of Arbitration Agreement. You agree that any
                    dispute, claim, or request for relief relating in any way to
                    your access to, or use of, the Project, to any products sold
                    or distributed through the Project, or to any aspect of your
                    relationship with Beans Foundation DAO, LLC, will be
                    resolved by binding arbitration, rather than in court,
                    except that Beans Foundation DAO, LLC may seek equitable
                    relief in court for infringement or other misuse of
                    intellectual property rights (such as trademarks, trade
                    dress, domain names, trade secrets, copyrights, and
                    patents). This Arbitration Agreement shall apply, without
                    limitation, to all disputes or claims and requests for
                    relief that arose or were asserted before the effective date
                    of this Agreement or any prior version of this Agreement.  
                  </p>
                  <p className={classes.paragraphText}>
                    Arbitration Rules and Forum. Any dispute, controversy or
                    claim arising out of or relating to this Agreement,
                    including the formation, interpretation, breach or
                    termination thereof, including whether the claims asserted
                    are arbitrable, will be referred to and finally determined
                    by arbitration in accordance with the JAMS International
                    Arbitration Rules. The tribunal will consist of a sole
                    arbitrator. The language to be used in the arbitral
                    proceedings will be English. For any claim to be resolved by
                    arbitration, the parties shall conduct the arbitration
                    remotely using secure videoconferencing in which all
                    parties, counsel, witnesses and the arbitrator shall appear
                    remotely for the arbitration proceedings. Judgment upon the
                    award rendered by the arbitrator(s) may be entered by any
                    court having jurisdiction thereof.
                  </p>
                  <p className={classes.paragraphText}>
                    Authority of Arbitrator. The arbitrator shall have exclusive
                    authority to (i) determine the scope and enforceability of
                    this Arbitration Agreement and (ii) resolve any dispute
                    related to the interpretation, applicability, enforceability
                    or formation of this Arbitration Agreement including, but
                    not limited to, any assertion that all or any part of this
                    Arbitration Agreement is void or voidable. The arbitrator
                    will decide the rights and liabilities, if any, of you and
                    us. The arbitration proceeding will not be consolidated with
                    any other matters or joined with any other cases or parties.
                    The arbitrator shall have the authority to grant motions
                    dispositive of all or part of any claim. The arbitrator
                    shall have the authority to award monetary damages and to
                    grant any non-monetary remedy or relief available to an
                    individual under applicable law, the arbitral forum’s rules,
                    and this Agreement (including the Arbitration Agreement).
                    The arbitrator shall issue a written award and statement of
                    decision describing the essential findings and conclusions
                    on which the award is based, including the calculation of
                    any damages awarded. The arbitrator has the same authority
                    to award relief on an individual basis that a judge in a
                    court of law would have. The award of the arbitrator is
                    final and binding upon you and us.  
                  </p>
                  <p className={classes.paragraphText}>
                    Waiver of Jury Trial. YOU AND BEANS FOUNDATION DAO, LLC
                    HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO SUE
                    IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You
                    and we are instead electing that all disputes, claims, or
                    requests for relief shall be resolved by arbitration under
                    this Arbitration Agreement, except as set forth above. An
                    arbitrator can award on an individual basis the same damages
                    and relief as a court and must follow this Agreement as a
                    court would. However, there is no judge or jury in
                    arbitration, and court review of an arbitration award is
                    subject to very limited review. 
                  </p>
                  <p className={classes.paragraphText}>
                    Waiver of Class or Other Non-Individualized Relief. ALL
                    DISPUTES, CLAIMS, AND REQUESTS FOR RELIEF WITHIN THE SCOPE
                    OF THIS ARBITRATION AGREEMENT MUST BE ARBITRATED ON AN
                    INDIVIDUAL BASIS AND NOT ON A CLASS OR COLLECTIVE BASIS,
                    ONLY INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN
                    ONE CUSTOMER OR USER CANNOT BE ARBITRATED OR CONSOLIDATED
                    WITH THOSE OF ANY OTHER CUSTOMER OR USER. If a decision is
                    issued stating that applicable law precludes enforcement of
                    any of this section’s limitations as to a given dispute,
                    claim, or request for relief, then such aspect must be
                    severed from the arbitration and brought into the State or
                    Federal Courts located in the State of New York. All other
                    disputes, claims, or requests for relief shall be
                    arbitrated. 
                  </p>
                  <p className={classes.paragraphText}>
                    30-Day Right to Opt Out. You have the right to opt out of
                    the provisions of this Arbitration Agreement by sending
                    written notice of your decision to opt out to
                    beansdaowtf@gmail.com within thirty (30) days after first
                    becoming subject to this Arbitration Agreement. Your notice
                    must include your name and address, your username (if any),
                    and an unequivocal statement that you want to opt out of
                    this Arbitration Agreement. If you opt out of this
                    Arbitration Agreement, all other parts of this Agreement
                    will continue to apply to you. Opting out of this
                    Arbitration Agreement has no effect on any other arbitration
                    agreements that you may currently have, or may enter in the
                    future, with us.
                  </p>
                  <p className={classes.paragraphText}>
                    Severability. Except as provided in above, if any part or
                    parts of this Arbitration Agreement are found under the law
                    to be invalid or unenforceable, then such specific part or
                    parts shall be of no force and effect and shall be severed
                    and the remainder of the Arbitration Agreement shall
                    continue in full force and effect.
                  </p>
                  <p className={classes.paragraphText}>
                    Survival of Agreement. This Arbitration Agreement will
                    survive the termination of your relationship with us. 
                  </p>
                  <p className={classes.paragraphText}>
                    Modification. Notwithstanding any provision in this
                    Agreement to the contrary, we agree that if we make any
                    future material change to this Arbitration Agreement, you
                    may reject that change within thirty (30) days of such
                    change becoming effective by writing us at
                    beansdaowtf@gmail.com and expressly opting out of this
                    Arbitration Agreement.
                  </p>
                </li>
                <li>
                  <b>Limitation on Time to File Claims</b>
                  <p className={classes.paragraphText}>
                    ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR
                    RELATING TO THESE TERMS OF SERVICE OR THE SITE MUST BE
                    COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION
                    ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS
                    PERMANENTLY BARRED.
                  </p>
                </li>
                <li>
                  <b>Definitions</b>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <p className={classes.paragraphText}>
                      <li>
                        “Affiliate” means, with respect to any specified Person
                        (as defined below), any other Person who, directly or
                        indirectly, controls, is controlled by, or is under
                        common control with such Person, including without
                        limitation any general partner, managing member, officer
                        or director of such Person or any venture capital fund
                        now or hereafter existing that is controlled by one or
                        more general partners or managing members of, or shares
                        the same management company with, such Person.{' '}
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Applicable Law” means any domestic or foreign law,
                        rule, statute, regulation, by-law, order, protocol,
                        code, decree, or other directive, requirement or
                        guideline, published or in force which applies to, or is
                        otherwise intended to govern or regulate, any Person,
                        property, transaction, activity, event or other matter,
                        including any rule, order, judgment, directive or other
                        requirement or guideline issued by any Governmental
                        Authority (as defined below) having jurisdiction over
                        the Beans NFT Project, you, or as otherwise duly
                        enacted, enforceable by law, the common law or equity.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Beans DAO” means the Decentralized Autonomous
                        Organization ("DAO") established to govern, manage, and
                        operate a specific set of services, platforms, or
                        resources, as set forth in the Beans Foundation DAO, LLC
                        Operating Agreement.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Discord” means our Discord server located at
                        https://discord.gg/Cau3fCAjts.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “ETH” means the Ethereum Blockchain utility token that
                        may be used to purchase computational resources to run
                        decentralized applications or perform actions on the
                        Ethereum Blockchain.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Ethereum Address” means the unique public key digital
                        asset identifier that points to an Ethereum-compatible
                        wallet to which ETH may be sent or stored.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Ethereum Blockchain” means the underlying blockchain
                        infrastructure.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Governmental Authority” includes any domestic or
                        foreign federal, provincial or state, municipal, local
                        or other governmental, regulatory, judicial or
                        administrative authority.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “NFT” means any blockchain-tracked, non-fungible token.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Person” means an individual, a partnership, a joint
                        venture, a limited liability company or partnership, a
                        corporation, a trust, an unincorporated organization or
                        a government or any department or any agency or
                        political subdivision thereof.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Privacy Policy” means our privacy policy located{' '}
                        {privacyLink}.
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Project” refers to, in the aggregate, the Beans NFTs,
                        our Site, Discord server, the Beans DAO, and other
                        social media accounts, and any features, functions,
                        services, products, rewards, offers, content, materials
                        or information available on or through our Site.{' '}
                      </li>
                    </p>
                    <p className={classes.paragraphText}>
                      <li>
                        “Site” means the website located at
                        https://www.Beans.wtf.{' '}
                      </li>
                    </p>
                  </ol>
                </li>
              </ol>
            </div>
          </Col>
        </Section>
      </Container>
    </>
  );
};
export default Terms;
