import { Auction } from '../wrappers/beansAuction';
import { AuctionState } from '../state/slices/auction';
import { Address } from 'viem';

export const migratedBeanMetadata: Map<
  number,
  { date: bigint; address: Address; amount: bigint }
> = new Map([
  [0, { date: 1706775275n, address: '0x', amount: 0n }],
  [
    1,
    {
      date: 1706775275n,
      address: '0xf7DaDdb9553D6C0AD80C66C7cFFF281b1D5F35AD',
      amount: 1420000000000000000n,
    },
  ],
  [
    2,
    {
      date: 1706873879n,
      address: '0x4298e663517593284Ad4FE199b21815BD48a9969',
      amount: 250000000000000000n,
    },
  ],
  [3, { date: 1706873879n, address: '0x', amount: 0n }],
  [
    4,
    {
      date: 1706960603n,
      address: '0x7f94e30381aA6657C45833EC7fcE2E493c1888EF',
      amount: 200000000000000000n,
    },
  ],
  [
    5,
    {
      date: 1707047315n,
      address: '0xa5C843850AedFdc5254ec52DD73c99ff2E648a95',
      amount: 170000000000000000n,
    },
  ],
  [6, { date: 1707047315n, address: '0x', amount: 0n }],
  [
    7,
    {
      date: 1707134339n,
      address: '0x90F3146Fa0e9e2056bBE383eCd2Ff5864eAa78c5',
      amount: 200000000000000000n,
    },
  ],
  [
    8,
    {
      date: 1707221387n,
      address: '0x078E88E465f2a430399E319d57543A7A76E97668',
      amount: 300000000000000000n,
    },
  ],
  [9, { date: 1707221387n, address: '0x', amount: 0n }],
  [10, { date: 1707221387n, address: '0x', amount: 0n }],
  [
    11,
    {
      date: 1707308195n,
      address: '0x7f94e30381aa6657c45833ec7fce2e493c1888ef',
      amount: 330000000000000000n,
    },
  ],
  [12, { date: 1707308195n, address: '0x', amount: 0n }],
  [
    13,
    {
      date: 1707400847n,
      address: '0x11027c71A31f0eb416c6A1067C43A61F45574468',
      amount: 130000000000000000n,
    },
  ],
  [
    14,
    {
      date: 1707666875n,
      address: '0x90F3146Fa0e9e2056bBE383eCd2Ff5864eAa78c5',
      amount: 200000000000000000n,
    },
  ],
  [15, { date: 1707666875n, address: '0x', amount: 0n }],
  [
    16,
    {
      date: 1707754499n,
      address: '0x9ba1e70de2917243082404eec8f6152916432d11',
      amount: 100000000000000000n,
    },
  ],
]);

// 0 is auction
// 1 is beanteam
// 2 is og bean
// 3 is migrated
const beanSchedule = new Map([
  [0, 1],
  [1, 3],
  [2, 3],
  [3, 2],
  [4, 3],
  [5, 3],
  [6, 2],
  [7, 3],
  [8, 3],
  [9, 2],
  [10, 1],
  [11, 3],
  [12, 2],
  [13, 3],
  [14, 3],
  [15, 2],
  [16, 3],
  [17, 0],
  [18, 2],
  [19, 0],
  [20, 1],
  [21, 2],
  [22, 0],
  [23, 0],
  [24, 2],
  [25, 0],
  [26, 0],
  [27, 2],
  [28, 0],
  [29, 0],
  [30, 1],
  [31, 0],
  [32, 0],
  [33, 2],
  [34, 0],
  [35, 0],
  [36, 2],
  [37, 0],
  [38, 0],
  [39, 2],
  [40, 1],
  [41, 0],
  [42, 2],
  [43, 0],
  [44, 0],
  [45, 2],
  [46, 0],
  [47, 0],
  [48, 2],
  [49, 0],
  [50, 1],
  [51, 2],
  [52, 0],
  [53, 0],
  [54, 2],
  [55, 0],
  [56, 0],
  [57, 2],
  [58, 0],
  [59, 0],
  [60, 1],
  [61, 0],
  [62, 0],
  [63, 2],
  [64, 0],
  [65, 0],
  [66, 2],
  [67, 0],
  [68, 0],
  [69, 2],
  [70, 1],
  [71, 0],
  [72, 2],
  [73, 0],
  [74, 0],
  [75, 2],
  [76, 0],
  [77, 0],
  [78, 2],
  [79, 0],
  [80, 1],
  [81, 2],
  [82, 0],
  [83, 0],
  [84, 2],
  [85, 0],
  [86, 0],
  [87, 2],
  [88, 0],
  [89, 0],
  [90, 1],
  [91, 0],
  [92, 0],
  [93, 2],
  [94, 0],
  [95, 0],
  [96, 2],
  [97, 0],
  [98, 0],
  [99, 2],
  [100, 1],
  [101, 0],
  [102, 2],
  [103, 0],
  [104, 0],
  [105, 2],
  [106, 0],
  [107, 0],
  [108, 2],
  [109, 0],
  [110, 1],
  [111, 2],
  [112, 0],
  [113, 0],
  [114, 2],
  [115, 0],
  [116, 0],
  [117, 2],
  [118, 0],
  [119, 0],
  [120, 1],
  [121, 0],
  [122, 0],
  [123, 2],
  [124, 0],
  [125, 0],
  [126, 2],
  [127, 0],
  [128, 0],
  [129, 2],
  [130, 1],
  [131, 0],
  [132, 2],
  [133, 0],
  [134, 0],
  [135, 2],
  [136, 0],
  [137, 0],
  [138, 2],
  [139, 0],
  [140, 1],
  [141, 2],
  [142, 0],
  [143, 0],
  [144, 2],
  [145, 0],
  [146, 0],
  [147, 2],
  [148, 0],
  [149, 0],
  [150, 1],
  [151, 0],
  [152, 0],
  [153, 2],
  [154, 0],
  [155, 0],
  [156, 2],
  [157, 0],
  [158, 0],
  [159, 2],
  [160, 1],
  [161, 0],
  [162, 2],
  [163, 0],
  [164, 0],
  [165, 2],
  [166, 0],
  [167, 0],
  [168, 2],
  [169, 0],
  [170, 1],
  [171, 2],
  [172, 0],
  [173, 0],
  [174, 2],
  [175, 0],
  [176, 0],
  [177, 2],
  [178, 0],
  [179, 0],
  [180, 1],
  [181, 0],
  [182, 0],
  [183, 2],
  [184, 0],
  [185, 0],
  [186, 2],
  [187, 0],
  [188, 0],
  [189, 2],
  [190, 1],
  [191, 0],
  [192, 2],
  [193, 0],
  [194, 0],
  [195, 2],
  [196, 0],
  [197, 0],
  [198, 2],
  [199, 0],
  [200, 1],
  [201, 2],
  [202, 0],
  [203, 0],
  [204, 2],
  [205, 0],
  [206, 0],
  [207, 2],
  [208, 0],
  [209, 0],
  [210, 1],
  [211, 0],
  [212, 0],
  [213, 2],
  [214, 0],
  [215, 0],
  [216, 2],
  [217, 0],
  [218, 0],
  [219, 2],
  [220, 1],
  [221, 0],
  [222, 2],
  [223, 0],
  [224, 0],
  [225, 2],
  [226, 0],
  [227, 0],
  [228, 2],
  [229, 0],
  [230, 1],
  [231, 2],
  [232, 0],
  [233, 0],
  [234, 2],
  [235, 0],
  [236, 0],
  [237, 2],
  [238, 0],
  [239, 0],
  [240, 1],
  [241, 0],
  [242, 0],
  [243, 2],
  [244, 0],
  [245, 0],
  [246, 2],
  [247, 0],
  [248, 0],
  [249, 2],
  [250, 1],
  [251, 0],
  [252, 2],
  [253, 0],
  [254, 0],
  [255, 2],
  [256, 0],
  [257, 0],
  [258, 2],
  [259, 0],
  [260, 1],
  [261, 2],
  [262, 0],
  [263, 0],
  [264, 2],
  [265, 0],
  [266, 0],
  [267, 2],
  [268, 0],
  [269, 0],
  [270, 1],
  [271, 0],
  [272, 0],
  [273, 2],
  [274, 0],
  [275, 0],
  [276, 2],
  [277, 0],
  [278, 0],
  [279, 2],
  [280, 1],
  [281, 0],
  [282, 2],
  [290, 1],
  [300, 1],
  [310, 1],
  [320, 1],
  [330, 1],
  [340, 1],
  [350, 1],
  [360, 1],
  [370, 1],
  [380, 1],
  [390, 1],
  [400, 1],
  [410, 1],
  [420, 1],
  [430, 1],
  [440, 1],
  [450, 1],
  [460, 1],
  [470, 1],
  [480, 1],
  [490, 1],
  [500, 1],
  [510, 1],
  [520, 1],
  [530, 1],
  [540, 1],
  [550, 1],
  [560, 1],
  [570, 1],
  [580, 1],
  [590, 1],
  [600, 1],
  [610, 1],
  [620, 1],
  [630, 1],
  [640, 1],
  [650, 1],
  [660, 1],
  [670, 1],
  [680, 1],
  [690, 1],
  [700, 1],
  [710, 1],
  [720, 1],
  [730, 1],
  [740, 1],
  [750, 1],
  [760, 1],
  [770, 1],
  [780, 1],
  [790, 1],
  [800, 1],
  [810, 1],
  [820, 1],
  [830, 1],
  [840, 1],
  [850, 1],
  [860, 1],
  [870, 1],
  [880, 1],
  [890, 1],
  [900, 1],
  [910, 1],
  [920, 1],
  [930, 1],
  [940, 1],
  [950, 1],
  [960, 1],
  [970, 1],
  [980, 1],
  [990, 1],
  [1000, 1],
  [1010, 1],
  [1020, 1],
  [1030, 1],
  [1040, 1],
  [1050, 1],
  [1060, 1],
  [1070, 1],
  [1080, 1],
  [1090, 1],
  [1100, 1],
  [1110, 1],
  [1120, 1],
  [1130, 1],
  [1140, 1],
  [1150, 1],
  [1160, 1],
  [1170, 1],
  [1180, 1],
  [1190, 1],
  [1200, 1],
  [1210, 1],
  [1220, 1],
  [1230, 1],
  [1240, 1],
  [1250, 1],
  [1260, 1],
  [1270, 1],
  [1280, 1],
  [1290, 1],
  [1300, 1],
  [1310, 1],
  [1320, 1],
  [1330, 1],
  [1340, 1],
  [1350, 1],
  [1360, 1],
  [1370, 1],
  [1380, 1],
  [1390, 1],
  [1400, 1],
  [1410, 1],
  [1420, 1],
  [1430, 1],
  [1440, 1],
  [1450, 1],
  [1460, 1],
  [1470, 1],
  [1480, 1],
  [1490, 1],
  [1500, 1],
  [1510, 1],
  [1520, 1],
  [1530, 1],
  [1540, 1],
  [1550, 1],
  [1560, 1],
  [1570, 1],
  [1580, 1],
  [1590, 1],
  [1600, 1],
  [1610, 1],
  [1620, 1],
  [1630, 1],
  [1640, 1],
  [1650, 1],
  [1660, 1],
  [1670, 1],
  [1680, 1],
  [1690, 1],
  [1700, 1],
  [1710, 1],
  [1720, 1],
  [1730, 1],
  [1740, 1],
  [1750, 1],
  [1760, 1],
  [1770, 1],
  [1780, 1],
  [1790, 1],
  [1800, 1],
  [1810, 1],
  [1820, 1],
  [1830, 1],
]);

export const isBeanderBean = (beanId: bigint) => {
  return beanId % 10n === 0n || beanId === 0n;
};

export const getBeanType = (beanId: bigint) => {
  var id = Number(beanId);
  if (!beanSchedule.has(id)) {
    return 0;
  } else {
    return beanSchedule.get(id);
  }
};

export const getBeanMetadata = (beanId: bigint) => {
  var id = Number(beanId);
  if (!migratedBeanMetadata.has(id)) {
    return undefined;
  } else {
    return migratedBeanMetadata.get(id);
  }
};

const emptyBeanderAuction = (onDisplayAuctionId: number): Auction => {
  return {
    amount: 0n,
    bidder: '0x',
    startTime: 0n,
    endTime: 0n,
    beanId: BigInt(onDisplayAuctionId),
    settled: false,
  };
};

const migratedAuction = (
  onDisplayAuctionId: bigint,
  amount: bigint,
  owner: Address,
  endDate: bigint
): Auction => {
  return {
    amount: amount,
    bidder: owner,
    startTime: 0n,
    endTime: endDate,
    beanId: onDisplayAuctionId,
    settled: false,
  };
};

const findAuction = (
  id: bigint,
  auctions: AuctionState[]
): Auction | undefined => {
  return auctions.find((auction) => {
    return auction.activeAuction?.beanId === id;
  })?.activeAuction;
};

export const findPreviousAuctionDate = (
  beanId: bigint,
  auctions: AuctionState[]
): bigint | undefined => {
  let results = auctions.filter(
    (x) =>
      (x.activeAuction !== undefined &&
        x.activeAuction.beanId !== undefined &&
        x.activeAuction?.beanId === beanId) ||
      x.activeAuction?.beanId === beanId - 1n ||
      x.activeAuction?.beanId === beanId - 2n ||
      x.activeAuction?.beanId === beanId - 3n
  );

  function compare(a: AuctionState, b: AuctionState) {
    if (
      a.activeAuction?.beanId === undefined ||
      b.activeAuction?.beanId === undefined
    ) {
      return 0;
    }
    if (a.activeAuction?.beanId < b.activeAuction.beanId) {
      return 1;
    }
    if (a.activeAuction?.beanId > b.activeAuction.beanId) {
      return -1;
    }
    return 0;
  }

  let sorted = results.sort(compare);

  let firstAuctionWithDate = sorted.find((auction) => {
    return auction.activeAuction?.endTime !== undefined;
  });

  if (firstAuctionWithDate) {
    return firstAuctionWithDate.activeAuction?.endTime;
  } else {
    console.log('not found');
    return undefined;
  }
};

/**
 *
 * @param beanId
 * @param pastAuctions
 * @returns empty `Auction` object with `startTime` set to auction after param `beanId`
 */
export const generateEmptyBeanderAuction = (
  beanId: bigint,
  pastAuctions: AuctionState[]
): Auction => {
  const beanderAuction = emptyBeanderAuction(Number(beanId));
  // use beanderAuction.beanId + 1 to get mint time
  const auctionAbove = findAuction(beanId + 1n, pastAuctions);
  const auctionAboveStartTime = auctionAbove && auctionAbove.startTime;
  if (auctionAboveStartTime) beanderAuction.startTime = auctionAboveStartTime;

  return beanderAuction;
};

export const generateMigratedBeanAuction = (
  beanId: bigint,
  amount: bigint,
  owner: Address,
  endDate: bigint,
  pastAuctions: AuctionState[]
): Auction => {
  const beanderAuction = migratedAuction(beanId, amount, owner, endDate);
  // use beanderAuction.beanId + 1 to get mint time
  const auctionAbove = findAuction(beanId + 1n, pastAuctions);
  const auctionAboveStartTime = auctionAbove && auctionAbove.startTime;
  if (auctionAboveStartTime) beanderAuction.startTime = auctionAboveStartTime;

  return beanderAuction;
};
