import classes from './CurrentBid.module.css';
import TruncatedAmount from '../TruncatedAmount';
import { Row, Col } from 'react-bootstrap';
import clsx from 'clsx';

/**
 * Passible to CurrentBid as `currentBid` prop to indicate that
 * the bid amount is not applicable to this auction. (Bean Team Bean)
 */
export const BID_N_A = 'N/A';

/**
 * Special Bid type for not applicable auctions (Bean Team Beans)
 */
type BidNa = typeof BID_N_A;

const CurrentBid: React.FC<{
  currentBid: bigint | BidNa;
  auctionEnded: boolean;
}> = (props) => {
  const { currentBid, auctionEnded } = props;
  const titleContent = auctionEnded ? 'WINNING BID' : 'CURRENT BID';

  return (
    <Row className={clsx(classes.wrapper, classes.container, classes.section)}>
      <Col xs={6} lg={12} className={classes.leftCol}>
        <h4
          style={{
            color: 'var(--beans-color-primary)',
          }}
        >
          {titleContent}
        </h4>
      </Col>
      <Col xs='auto' lg={12}>
        <h2 style={{ color: 'white' }}>
          Ξ{' '}
          {currentBid === BID_N_A ? (
            BID_N_A
          ) : (
            <TruncatedAmount amount={currentBid} />
          )}
        </h2>
      </Col>
    </Row>
  );
};

export default CurrentBid;
