import Section from '../../layout/Section';
import { Col } from 'react-bootstrap';
import classes from './Documentation.module.css';
import Accordion from 'react-bootstrap/Accordion';
import Link from '../Link';

const Documentation = () => {
  const cryptopunksLink = (
    <Link
      text='CryptoPunks'
      url='https://www.larvalabs.com/cryptopunks'
      leavesPage={true}
    />
  );
  const playgroundLink = (
    <Link text='Playground' url='/playground' leavesPage={false} />
  );
  const publicDomainLink = (
    <Link
      text='public domain'
      url='https://creativecommons.org/publicdomain/zero/1.0/'
      leavesPage={true}
    />
  );
  const compoundGovLink = (
    <Link
      text='Compound Governance'
      url='https://compound.finance/governance'
      leavesPage={true}
    />
  );
  return (
    <Section fullWidth={false} className={classes.sectionClass}>
      <Col lg={{ span: 10, offset: 1 }}>
        <div className={classes.headerWrapper}>
          <h1>WTF?</h1>
          <p className={classes.aboutText}>
            Beans are an experimental attempt to improve the formation of
            on-chain avatar communities. While projects such as{' '}
            {cryptopunksLink} have attempted to bootstrap digital community and
            identity, Beans attempt to bootstrap identity, community,
            governance, and a treasury that can be used by the community.
          </p>
          <p className={classes.aboutText} style={{ paddingBottom: '1rem' }}>
            Learn more below, or start creating Beans off-chain using the{' '}
            {playgroundLink}.
          </p>
        </div>
        <Accordion flush>
          <Accordion.Item eventKey='0' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              PROLOGUE
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                Let’s take a minute, clear our minds and do an experiment. Take
                a breath, ready? Let’s zoom out. Let’s open that brain box of
                ours and all the grey matter within, and do a little exploring.
                Let’s look at the world with a grand, all-seeing, objective eye,
                acknowledge the obscure and consider something that gets the
                hairs on the back of our necks standing on end.
              </p>

              <p className={classes.aboutText}>
                We’re here to expand the mind, wider than we’ve ever thought of
                or considered before, and now, when we’re ready and we look back
                down at the place we just left, what do we see? This thing
                called life, this existence we have, and guess what, it’s pretty
                weird, right?
              </p>

              <p className={classes.aboutText}>
                That’s it. We’re in the right place now. There’s no normal here
                as we float in the peculiar space high above this thing we call
                reality. It’s all a little mad, we’re all a little odd. So, now,
                time to come back in. Here, take our hand.
              </p>

              <p className={classes.aboutText}>
                We’re off somewhere different, somewhere a little unorthodox, a
                little psychedelic, a little kaleidoscopic, a little
                mind-bending…But somewhere eerily familiar too.
              </p>

              <p className={classes.aboutText}>
                We’re firing off through the metaverse, into the multiverse and
                across universes galore. Over and around a million-million stars
                and planets, moons and phenomena impossible to grasp or explain,
                and in the wink of an eye we land here, the Beaniverse.
              </p>

              <p className={classes.aboutText}>
                Strange? Maybe. Different? Perhaps. Alien? YES! A collection of
                planets that play home to the Beans, only they’re not really
                beans at all, more like little monsters. A young race doing what
                we’re all trying to do, find our way in the universe,
                questioning wtf is going on.
              </p>

              <p className={classes.aboutText}>
                You think things are bizarre back on Earth? Try intergalactic
                space seeds falling from the sky wreaking havoc across the
                worlds the Beans occupy! Life’s tough, you know. Sometimes you
                catch a break, sometimes not. Sometimes you work your ass off at
                something and have that beautiful discovery, other times you hit
                that wall and end up bouncing backwards rather than striding
                forwards. It’s not much different for the Beans, and often a lot
                more brutal.
              </p>

              <p className={classes.aboutText}>
                The Beans, they’ve not been around long, their worlds are equal
                measures stunning and savage, the fight is on; they are trying
                to understand their place in the grand scheme of things, trying
                to find their way. They eat the space seeds and sometimes they
                get the best of consequences, rising to colossal size and
                strength, and then other times Fortune will play her wicked game
                and the seed will just kill them dead. The point being, life is
                anything but simple around here, just the same as we have it
                back home.
              </p>

              <p className={classes.aboutText}>
                So, why are we here? For what purpose have we zoomed all the way
                out, and all the way back in again? For the Beans, of course!
                We’re here to watch, to play, to engage and to see where this
                new race is going to go, where we’re going to take it.
              </p>

              <p className={classes.aboutText}>
                Each Bean born is individual, just like you and me, traits that
                make them their own, and along the way, we will discover,
                create, find, evolve many others. Some will be good, some bad,
                some amazing, some debilitating. The point is, here, anything is
                possible.
              </p>

              <p className={classes.aboutText}>
                From the birth of a Bean to the world they enter. From the
                intergalactic space seeds that can bring wonder or horror to the
                traits a Bean has, nothing is normal here, but then again, as
                we’ve kinda figured out, there is no normal.
              </p>

              <p className={classes.aboutText}>
                The Beans are discovering and playing within the Beaniverse, and
                now, so are we, so are you. So, come along and into the
                Beaniverse, an unlimited world of creativity and invention,
                where anything is possible and the only limit is how far you can
                zoom out.
              </p>

              <p className={classes.aboutText}>
                Engage with us, play with us, and have fun with us. Let’s build
                dreams with Beans.
              </p>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='1' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              SUMMARY
            </Accordion.Header>
            <Accordion.Body>
              <ul className={classes.aboutList}>
                <li>Beans artwork is in the {publicDomainLink}.</li>
                <li>
                  One Bean is trustlessly auctioned every 24 hours, forever.
                </li>
                <li>
                  100% of Bean auction proceeds are trustlessly sent to the
                  treasury.
                </li>
                <li>Settlement of one auction kicks off the next.</li>
                <li>All Beans are members of BEANSDAO.</li>
                <li>BEANSDAO uses a fork of {compoundGovLink}.</li>
                <li>One Bean is equal to one vote.</li>
                <li>
                  The treasury is controlled exclusively by Beans via
                  governance.
                </li>
                <li>
                  Artwork is generative and stored directly on-chain (not IPFS).
                </li>
                <li>
                  No explicit rules exist for attribute scarcity; all Beans are
                  equally rare.
                </li>
                <li>
                  The Bean Team receive rewards in the form of Beans (10% of
                  supply for first 5 years).
                </li>
                <li>
                  Holders of the original cryptobeanz collection during the
                  snapshot will receive one bean. These beans will be minted
                  every 3rd bean unless it falls on a Bean Team bean mint.
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey='2' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              DAILY AUCTIONS
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                The Beans Auction Contract will act as a self-sufficient Bean
                generation and distribution mechanism, auctioning one Bean every
                24 hours, forever. 100% of auction proceeds (ETH) are
                automatically deposited in the BEANSDAO treasury, where they are
                governed by Bean owners.
              </p>

              <p className={classes.aboutText}>
                Each time an auction is settled, the settlement transaction will
                also cause a new Bean to be minted and a new 24 hour auction to
                begin.{' '}
              </p>
              <p className={classes.aboutText}>
                While settlement is most heavily incentivized for the winning
                bidder, it can be triggered by anyone, allowing the system to
                trustlessly auction Beans as long as Ethereum is operational and
                there are interested bidders.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='3' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              BEANSDAO
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                BEANSDAO utilizes a fork of {compoundGovLink} and is the main
                governing body of the Beans ecosystem. The BEANSDAO treasury
                receives 100% of ETH proceeds from daily Bean auctions. Each
                Bean is an irrevocable member of BEANSDAO and entitled to one
                vote in all governance matters. Bean votes are non-transferable
                (if you sell your Bean the vote goes with it) but delegatable,
                which means you can assign your vote to someone else as long as
                you own your Bean.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='4' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              GOVERNANCE
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                In addition to the precautions taken by Compound Governance, the
                Bean Team have given themselves a special veto right to ensure
                that no malicious proposals can be passed while the Bean supply
                is low. This veto right will only be used if an obviously
                harmful governance proposal has been passed, and is intended as
                a last resort.
              </p>
              <p className={classes.aboutText}>
                The Bean Team will proveably revoke this veto right when they
                deem it safe to do so. This decision will be based on a healthy
                Bean distribution and a community that is engaged in the
                governance process.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='5' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              BEAN TRAITS
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                Beans are generated randomly based Ethereum block hashes. There
                are no 'if' statements or other rules governing Bean trait
                scarcity, which makes all Beans equally rare. As of this
                writing, Beans are made up of:
              </p>
              <ul className={classes.aboutList}>
                <li>Class 1 (18)</li>
                <li>Class 2 (18)</li>
                <li>Gear (86)</li>
                <li>Helmet (126)</li>
                <li>Size (2)</li>
                <li>Vibe (2)</li>
              </ul>
              <p className={classes.aboutText}>
                You can experiment with off-chain Bean generation at the{' '}
                {playgroundLink}.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='6' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              ON-CHAIN ARTWORK
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                Beans are stored directly on Ethereum and do not utilize
                pointers to other networks such as IPFS. This is possible
                because Bean parts are compressed and stored on-chain using
                base64 encoding of svg code.
              </p>

              <p className={classes.aboutText}>
                Each asset is broken into small svg code and can be called by an
                id number that is generated in the Bean Seeder Contract. Once
                all the code is collected from the ids it is combined and then
                base64 encoded.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='7' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              BEAN SEEDER CONTRACT
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                The Bean Seeder contract works with the Beans Descriptor
                contract. The Beans Descriptor is the contract that manages the
                assets and where the DAO can add future trait contract upgrades.
                The seeder calls the descriptor to randomly generate which
                assets each bean should have. Additionally, it can be locked by
                the BEANSDAO to prevent any future updates. Currently, Bean
                traits are determined using pseudo-random number generation:
              </p>
              <br />
              {/* UPDATE HERE */}
              <code>0x9Bd5027acB5969c368690E6047c0A51909CD5a60</code>
              <br />
              <br />
              <p className={classes.aboutText}>
                Trait generation is not truly random. Traits can be predicted
                when minting a Bean on the pending block.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='8' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              BEAN TEAM'S REWARD
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                'Bean Team' is the group of four builders that initiated Beans.
                Here is the Bean Team:
              </p>
              <ul>
                <li>
                  <Link
                    text='@jackmaschka'
                    url='https://twitter.com/jackmaschka'
                    leavesPage={true}
                  />
                </li>
                <li>
                  <Link
                    text='@_connievan'
                    url='https://twitter.com/_connievan'
                    leavesPage={true}
                  />
                </li>
                <li>
                  <Link
                    text='@0xOddrey'
                    url='https://twitter.com/0xOddrey'
                    leavesPage={true}
                  />
                </li>
              </ul>
              <p className={classes.aboutText}>
                Because 100% of Bean auction proceeds are sent to BEANSDAO, The
                Bean Team have chosen to compensate themselves with Beans. Every
                10th Bean for the first 5 years of the project (Bean ids #0,
                #10, #20, #30 and so on) will be automatically sent to the Bean
                Teams's multisig to be vested and shared among the founding
                members of the project.
              </p>
              <p className={classes.aboutText}>
                Bean Team distributions don't interfere with the cadence of 24
                hour auctions. Beans are sent directly to the Bean Teams's
                Multisig, and auctions continue on schedule with the next
                available Bean ID.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey='9' className={classes.accordionItem}>
            <Accordion.Header className={classes.accordionHeader}>
              KEY CONTRIBUTORS
            </Accordion.Header>
            <Accordion.Body>
              <p className={classes.aboutText}>
                These are the amazing people that have helped make this project
                happen.
              </p>
              <ul>
                <li>
                  <Link
                    text='Awheck'
                    url='https://twitter.com/joshuasobel'
                    leavesPage={true}
                  />{' '}
                  Community Manager
                </li>
                <li>
                  <Link
                    text='Offedge'
                    url='https://twitter.com/OffEdge_'
                    leavesPage={true}
                  />{' '}
                  Community Manager
                </li>
                <li>
                  <Link
                    text='Galbraith'
                    url='https://twitter.com/ricgalbraith'
                    leavesPage={true}
                  />{' '}
                  Narrative Designer
                </li>
                <li>
                  <Link
                    text='Plutonium'
                    url='https://twitter.com/plutoniumfitzg'
                    leavesPage={true}
                  />{' '}
                  UI/UX Design
                </li>
                <li>
                  <Link
                    text='Mads'
                    url='https://twitter.com/mads'
                    leavesPage={true}
                  />{' '}
                  UI/UX Design
                </li>
                <li>
                  <Link
                    text='Adam'
                    url='https://twitter.com/Rhynotic'
                    leavesPage={true}
                  />{' '}
                  Discord Security (Vulcan)
                </li>
                <li>
                  <Link
                    text='Mercy'
                    url='https://twitter.com/mercyisdrawing'
                    leavesPage={true}
                  />{' '}
                  Artist
                </li>
                <li>
                  <Link
                    text='Tyler Wergin'
                    url='https://twitter.com/TylerWergin'
                    leavesPage={true}
                  />{' '}
                  Artist
                </li>
                <li>
                  <Link
                    text='PSNY'
                    url='https://twitter.com/psny83'
                    leavesPage={true}
                  />{' '}
                  Artist
                </li>
                <li>
                  <Link
                    text='IAmRyland'
                    url='https://twitter.com/RylandAldrich'
                    leavesPage={true}
                  />{' '}
                  Community Manager
                </li>
                <li>
                  <Link
                    text='Mkolvr'
                    url='https://twitter.com/mkolvr'
                    leavesPage={true}
                  />{' '}
                  Solidity Developer
                </li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Section>
  );
};
export default Documentation;
