import { Col, Row } from 'react-bootstrap';
import AuctionActivityWrapper from '../AuctionActivityWrapper';
import AuctionNavigation from '../AuctionNavigation';
import AuctionActivityBeanTitle from '../AuctionActivityBeanTitle';
import AuctionTitleAndNavWrapper from '../AuctionTitleAndNavWrapper';
import { Link } from 'react-router-dom';
import beanContentClasses from './BeanderBeanContent.module.css';
import auctionBidClasses from '../AuctionActivity/BidHistory.module.css';
import bidBtnClasses from '../BidHistoryBtn/BidHistoryBtn.module.css';
import auctionActivityClasses from '../AuctionActivity/AuctionActivity.module.css';
import CurrentBid, { BID_N_A } from '../CurrentBid';
import Winner from '../Winner';

const BeanderBeanContent: React.FC<{
  mintTimestamp: bigint;
  beanId: bigint;
  isFirstAuction: boolean;
  isLastAuction: boolean;
  onPrevAuctionClick: () => void;
  onNextAuctionClick: () => void;
}> = (props) => {
  const {
    mintTimestamp,
    beanId,
    isFirstAuction,
    isLastAuction,
    onPrevAuctionClick,
    onNextAuctionClick,
  } = props;

  return (
    <AuctionActivityWrapper>
      <div className={auctionActivityClasses.informationRow}>
        <Row className={auctionActivityClasses.activityRow}>
          <AuctionTitleAndNavWrapper>
            <AuctionNavigation
              isFirstAuction={isFirstAuction}
              isLastAuction={isLastAuction}
              onNextAuctionClick={onNextAuctionClick}
              onPrevAuctionClick={onPrevAuctionClick}
              startTime={mintTimestamp}
            />
          </AuctionTitleAndNavWrapper>
          <Col lg={12}>
            <AuctionActivityBeanTitle beanId={beanId} />
          </Col>
        </Row>
        <Row className={auctionActivityClasses.activityRow}>
          <Col lg={4} className={auctionActivityClasses.currentBidCol}>
            <CurrentBid currentBid={BID_N_A} auctionEnded={true} />
          </Col>
          <Col
            lg={5}
            className={`${auctionActivityClasses.currentBidCol} ${beanContentClasses.currentBidCol} ${auctionActivityClasses.auctionTimerCol}`}
          >
            <div className={auctionActivityClasses.section}>
              <Winner winner={'0x'} isBeanders={true} />
            </div>
          </Col>
        </Row>
      </div>
      <Row className={auctionActivityClasses.activityRow}>
        <Col lg={12}>
          <ul className={auctionBidClasses.bidCollection}>
            <li
              className={`${auctionBidClasses.bidRowCool} ${beanContentClasses.bidRow}`}
            >
              All Beans auction proceeds are sent to the{' '}
              <Link to='/vote' className={beanContentClasses.link}>
                BEANSDAO
              </Link>
              . For this reason, we, the project's founders (‘Bean Team’) have
              chosen to compensate ourselves with Beans. Every 10th Bean for the
              first 5 years of the project will be sent to our multisig (5/10),
              where it will be vested and distributed to individual founders
              within the Bean Team.
            </li>
          </ul>
          <div className={bidBtnClasses.bidHistoryWrapperCool}></div>
        </Col>
      </Row>
    </AuctionActivityWrapper>
  );
};
export default BeanderBeanContent;
