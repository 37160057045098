import { Row, Col } from 'react-bootstrap';
import { useAppSelector } from '../../hooks';
import classes from './Winner.module.css';
import clsx from 'clsx';
import { Address } from 'viem';
import { Name } from '@coinbase/onchainkit';

interface WinnerProps {
  winner: Address;
  isBeanders?: boolean;
  isOg?: boolean;
  isBurned?: boolean;
  isMigrated?: boolean;
}

const Winner: React.FC<WinnerProps> = (props) => {
  const { winner, isBeanders, isOg, isBurned, isMigrated } = props;
  const activeAccount = useAppSelector((state) => state.account.activeAccount);

  const isWinnerYou =
    activeAccount !== undefined &&
    activeAccount.toLocaleLowerCase() === winner.toLocaleLowerCase();

  const nonBeanderBeanContent = isWinnerYou ? (
    <div
      className={classes.winnerContent}
      style={{
        color: 'var(--brand-cool-dark-text)',
      }}
    >
      YOU
    </div>
  ) : (
    <Name address={winner} className='uppercase' />
  );

  const beanderBeanContent = (
    <div className={classes.beanName}>
      {isBurned && (
        <div>
          <span>BURNED</span>
        </div>
      )}
      {isOg && (
        <div>
          <span>SNAPSHOT</span>
        </div>
      )}
      {isBeanders && <span>BEANTEAM.ETH</span>}
      {isMigrated && <span>MIGRATED</span>}
    </div>
  );

  return (
    <>
      <Row className={clsx(classes.wrapper, classes.section)}>
        <Col xs={6} lg={12}>
          <h4
            style={{
              color: 'var(--beans-color-primary)',
            }}
          >
            WINNER
          </h4>
        </Col>
        <Col xs='auto' lg={12}>
          <h2
            // className={classes.winnerContent}
            style={{
              color: 'white',
            }}
          >
            {isBeanders || isOg || isBurned || isMigrated
              ? beanderBeanContent
              : nonBeanderBeanContent}
          </h2>
        </Col>
      </Row>
      {/* {isWinnerYou && isMobile && (
        <Row>
          <Link to="/verify" className={classes.verifyLink}>
            <Button bsPrefix="verify-btn" className={classes.verifyButton}>VERIFY</Button>
          </Link>
        </Row>
      )} */}
    </>
  );
};

export default Winner;
