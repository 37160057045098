import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { setActiveAccount } from './state/slices/account';
import { Route, Routes } from 'react-router-dom';
import { setAlertModal } from './state/slices/application';
import classes from './App.module.css';
import './css/globals.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AlertModal from './components/Modal';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import AuctionPage from './pages/Auction';
import GovernancePage from './pages/Governance';
import CreateProposalPage from './pages/CreateProposal';
import VotePage from './pages/Vote';
import PrivacyPage from './pages/Privacy';
import TermsPage from './pages/Terms';
import OperatingPage from './pages/Operating';
import NotFoundPage from './pages/NotFound';
import Playground from './pages/Playground';
import VerifyPage from './pages/Verify';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { useAccount } from 'wagmi';
import dotenv from 'dotenv';
import ScrollToTop from './components/ScrollToTop';

dotenv.config();

function App() {
  const { address } = useAccount();
  const dispatch = useAppDispatch();
  dayjs.extend(relativeTime);

  useEffect(() => {
    // Local account array updated
    dispatch(setActiveAccount(address));
  }, [address, dispatch]);

  const stateColorStart = useAppSelector(
    (state) => state.application.colorStart
  );
  const stateColorStop = useAppSelector((state) => state.application.colorStop);

  document.documentElement.style.setProperty(
    '--beans-color-primary',
    stateColorStart
  );
  document.documentElement.style.setProperty(
    '--beans-color-secondary',
    stateColorStop
  );

  const alertModal = useAppSelector((state) => state.application.alertModal);

  return (
    <div className={`${classes.wrapper}`}>
      {alertModal.show && (
        <AlertModal
          title={alertModal.title}
          content={<p>{alertModal.message}</p>}
          onDismiss={() =>
            dispatch(setAlertModal({ ...alertModal, show: false }))
          }
        />
      )}
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path='/' index element={<AuctionPage />} />
        <Route path='/bean/:id' element={<AuctionPage />} />
        <Route path='/sign' element={<VerifyPage />} />
        <Route path='/verify' element={<VerifyPage />} />
        <Route path='/create-proposal' element={<CreateProposalPage />} />
        <Route path='/vote' element={<GovernancePage />} />
        <Route path='/vote/:id' element={<VotePage />} />
        <Route path='/playground' element={<Playground />} />
        <Route path='/privacy' element={<PrivacyPage />} />
        <Route path='/terms' element={<TermsPage />} />
        <Route path='/operating' element={<OperatingPage />} />
        <Route path='/*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
