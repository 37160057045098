import { Auction } from "../../wrappers/beansAuction";
import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  useCallback,
} from "react";
import classes from "./Bid.module.css";
import { Spinner, InputGroup, FormControl, Button, Col } from "react-bootstrap";
import { useAppDispatch } from "../../hooks";
import { AlertModal, setAlertModal } from "../../state/slices/application";
import SettleManuallyBtn from "../SettleManuallyBtn";
import AgreementModal from "../AgreementModal";
import { useAccount } from "wagmi";
import {
  useReadBeansAuctionHouseMinBidIncrementPercentage,
  useWriteBeansAuctionHouseCreateBid,
  useWriteBeansAuctionHouseSettleCurrentAndCreateNewAuction,
} from "../../generated/wagmiGenerated";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { formatEther, parseEther } from "viem";
import { CHAIN_CONFIG } from "../../config";
import { useSwitchChain } from "wagmi";
import RequireCorrectChainButtonContainer from "../RequireCorrectChainButtonContainer";

const computeMinimumNextBid = (
  currentBid?: bigint,
  minBidIncPercentage?: bigint
): bigint => {
  return minBidIncPercentage === undefined || currentBid === undefined
    ? 0n
    : currentBid + (currentBid * minBidIncPercentage) / 100n;
};

const minBidEth = (minBid: bigint): string => {
  if (minBid === 0n) {
    return "0.01";
  }

  const eth = Number(formatEther(minBid));
  const roundedEth = Math.ceil(eth * 100) / 100;

  return roundedEth.toString();
};

const currentBid = (bidInputRef: React.RefObject<HTMLInputElement>) => {
  if (!bidInputRef.current || !bidInputRef.current.value) {
    return 0n;
  }
  return parseEther(bidInputRef.current.value);
};

const Bid: React.FC<{
  auction: Auction;
  auctionEnded: boolean;
}> = (props) => {
  const [showAgreementModal, setShowAgreementModal] = useState(false);
  let { auction, auctionEnded } = props;

  const bidInputRef = useRef<HTMLInputElement>(null);

  const [bidInput, setBidInput] = useState("");
  const [readAgreement, setReadAgreement] = useState(false);
  const [bidButtonContent, setBidButtonContent] = useState({
    loading: false,
    content: auctionEnded ? "SETTLE" : "PLACE BID",
  });

  const showAgreementHandler = () => {
    setShowAgreementModal(true);
  };
  const dismissAgreementHandler = () => {
    setShowAgreementModal(false);
  };
  const setReadAgreementState = (isChecked: boolean) => {
    setReadAgreement(isChecked);
  };

  const dispatch = useAppDispatch();
  const setModal = useCallback(
    (modal: AlertModal) => dispatch(setAlertModal(modal)),
    [dispatch]
  );

  const { data: minBidIncPercentage } =
    useReadBeansAuctionHouseMinBidIncrementPercentage({});
  const minBid = computeMinimumNextBid(
    auction && auction.amount,
    BigInt(minBidIncPercentage ?? "0")
  );

  console.log("MIN BID", auction.amount, minBidIncPercentage, minBid);

  const { address } = useAccount();

  const {
    writeContract: createBid,
    status: createBidStatus,
    error: createBidError,
  } = useWriteBeansAuctionHouseCreateBid();

  const {
    writeContract: settleAuction,
    status: settleAuctionStatus,
    error: settleAuctionError,
  } = useWriteBeansAuctionHouseSettleCurrentAndCreateNewAuction();

  const bidInputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;

    // disable more than 2 digits after decimal point
    if (input.includes(".") && event.target.value.split(".")[1].length > 2) {
      return;
    }

    setBidInput(event.target.value);
  };

  const placeBidHandler = async () => {
    if (!readAgreement) {
      showAgreementHandler();
    }

    if (
      !auction ||
      !bidInputRef.current ||
      !bidInputRef.current.value ||
      !readAgreement
    ) {
      return;
    }

    if (currentBid(bidInputRef) < minBid) {
      setModal({
        show: true,
        title: "INSUFFICIENT BID AMOUNT",
        message: `Please place a bid higher than or equal to the minimum bid amount of ${minBidEth(
          minBid
        )} ETH.`,
      });
      setBidInput(minBidEth(minBid));
      return;
    }

    const value = parseEther(bidInputRef.current.value.toString());

    createBid({
      value: value,
      args: [auction.beanId],
      chainId: CHAIN_CONFIG.chain.id,
    });

    // reset agreement
    setReadAgreement(false);
  };

  const settleAuctionHandler = async () => {
    settleAuction({ chainId: CHAIN_CONFIG.chain.id });
  };

  const clearBidInput = () => {
    if (bidInputRef.current) {
      bidInputRef.current.value = "";
    }
  };

  // successful bid using redux store state
  useEffect(() => {
    if (!address) return;

    // tx state is mining
    const isMiningUserTx = createBidStatus === "pending";
    // allows user to rebid against themselves so long as it is not the same tx
    const isCorrectTx = currentBid(bidInputRef) === auction.amount;
    if (isMiningUserTx && auction.bidder === address && isCorrectTx) {
      // createBidStatus = "Success";
      setModal({
        title: "SUCCESS",
        message: `Bid was placed successfully!`,
        show: true,
      });
      setBidButtonContent({ loading: false, content: "PLACE BID" });
      clearBidInput();
    }
  }, [auction, createBidStatus, address, setModal]);

  // placing bid transaction state hook
  useEffect(() => {
    if (!auctionEnded) {
      switch (createBidStatus) {
        case "idle":
          setBidButtonContent({
            loading: false,
            content: "PLACE BID",
          });
          break;
        case "pending":
          setBidButtonContent({ loading: true, content: "" });
          break;
        case "success":
          setBidButtonContent({ loading: false, content: "Bid" });
          break;
        case "error":
          const userReject = createBidError?.message.includes("User rejected");
          setModal({
            title: "Transaction Failed",
            message: userReject
              ? "User rejected request"
              : createBidError?.message ?? "Please try again.",
            show: true,
          });
          setBidButtonContent({ loading: false, content: "Bid" });

          break;
      }
    }
  }, [createBidStatus, createBidError, auctionEnded, setModal]);

  // settle auction transaction state hook
  useEffect(() => {
    if (auctionEnded) {
      switch (settleAuctionStatus) {
        case "idle":
          setBidButtonContent({
            loading: false,
            content: "Settle Auction",
          });
          break;
        case "pending":
          setBidButtonContent({ loading: true, content: "" });
          break;
        case "success":
          setModal({
            title: "Success",
            message: `Settled auction successfully!`,
            show: true,
          });
          setBidButtonContent({
            loading: false,
            content: "Settle Auction",
          });
          break;
        case "error":
          const userReject =
            settleAuctionError?.message.includes("User rejected");
          setModal({
            title: "Transaction Failed",
            message: userReject
              ? "User rejected request"
              : settleAuctionError?.message ?? "Please try again.",
            show: true,
          });
          setBidButtonContent({
            loading: false,
            content: "Settle Auction",
          });
          break;
      }
    }
  }, [settleAuctionStatus, settleAuctionError, auctionEnded, setModal]);

  if (!auction) return null;

  const isDisabled =
    createBidStatus === "pending" ||
    settleAuctionStatus === "pending" ||
    bidInput === "" ||
    Number(bidInput) === 0;

  const minBidCopy = `Ξ ${minBidEth(minBid)} OR MORE`;

  return (
    <>
      {showAgreementModal && (
        <AgreementModal
          isRead={readAgreement}
          setReadAgreementState={setReadAgreementState}
          onDismiss={() => dismissAgreementHandler()}
        />
      )}
      <InputGroup>
        {!auctionEnded && (
          <>
            <span className={classes.customPlaceholderBidAmt}>
              {!auctionEnded && !bidInput ? minBidCopy : ""}
            </span>
            <FormControl
              className={classes.bidInput}
              type='number'
              min='0'
              onChange={bidInputHandler}
              ref={bidInputRef}
              value={bidInput}
            />
          </>
        )}
        <RequireCorrectChainButtonContainer>
          {!auctionEnded ? (
            <Button
              bsPrefix='bid-btn'
              className={
                auctionEnded ? classes.bidBtnAuctionEnded : classes.bidBtn
              }
              onClick={auctionEnded ? settleAuctionHandler : placeBidHandler}
              disabled={isDisabled}
            >
              {bidButtonContent.loading ? (
                <Spinner animation='border' size='sm' />
              ) : (
                bidButtonContent.content
              )}
            </Button>
          ) : (
            <>
              <Col lg={12}>
                <SettleManuallyBtn
                  settleAuctionHandler={settleAuctionHandler}
                  auction={auction}
                />
              </Col>
            </>
          )}
        </RequireCorrectChainButtonContainer>
      </InputGroup>
    </>
  );
};
export default Bid;
