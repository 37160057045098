import { useQuery } from '@apollo/client';
import React from 'react';
import { Image } from 'react-bootstrap';
import _LinkIcon from '../../assets/icons/Link.svg';
import { beanQuery } from '../../wrappers/subgraph';
import _HeartIcon from '../../assets/icons/Heart.svg';
import classes from './BeanInfoRowHolder.module.css';

import { CHAIN_CONFIG } from '../../config';
import { buildEtherscanAddressLink } from '../../utils/etherscan';
import { Name } from '@coinbase/onchainkit';

interface BeanInfoRowHolderProps {
  beanId: bigint;
}

const BeanInfoRowHolder: React.FC<BeanInfoRowHolderProps> = (props) => {
  const { beanId } = props;
  const { loading, error, data } = useQuery(beanQuery(beanId.toString()));
  const etherscanURL = buildEtherscanAddressLink(data && data.bean.owner.id);

  if (loading) {
    return (
      <div className={classes.beanHolderInfoContainer}>
        <span className={classes.beanHolderLoading}>Loading...</span>
      </div>
    );
  } else if (error) {
    return <div>Failed to fetch bean info</div>;
  }

  return (
    <div className='flex flex-row items-center justify-start gap-1'>
      <Image src={_HeartIcon} className='pr-1' />
      HELD BY{' '}
      <a
        className={classes.beanHolderEtherscanLinkCool + ' flex flex-row'}
        href={etherscanURL}
        target={'_blank'}
        rel='noreferrer'
      >
        {data.bean.owner.id.toLowerCase() ===
        CHAIN_CONFIG.addresses.beansAuctionHouseProxy.toLowerCase() ? (
          'BEANS AUCTION HOUSE'
        ) : (
          <Name address={data && data.bean.owner.id} className='uppercase' />
        )}
        <Image src={_LinkIcon} />
      </a>
    </div>
  );
};

export default BeanInfoRowHolder;
