import { useEffect, useMemo, useState } from 'react';
import * as R from 'ramda';
import {
  beansDaoConfig,
  useReadBeansDaoGetReceipt,
  useReadBeansDaoProposalCount,
  useReadBeansDaoProposalThreshold,
} from '../generated/wagmiGenerated';
import {
  Abi,
  Address,
  Hex,
  decodeAbiParameters,
  formatEther,
  parseAbiItem,
} from 'viem';
import { useAccount, useClient, useReadContracts } from 'wagmi';
import { getBlock, getContractEvents } from 'viem/actions';

export enum Vote {
  AGAINST = 0,
  FOR = 1,
  ABSTAIN = 2,
}

export enum ProposalState {
  UNDETERMINED = -1,
  PENDING,
  ACTIVE,
  CANCELED,
  DEFEATED,
  SUCCEEDED,
  QUEUED,
  EXPIRED,
  EXECUTED,
  VETOED,
}

interface ProposalDetail {
  target: string;
  value: string;
  functionSig: string;
  callData: string;
}

export interface Proposal {
  id: bigint;
  title: string;
  description: string;
  status: ProposalState;
  forCount: bigint;
  againstCount: bigint;
  abstainCount: bigint;
  createdBlock: bigint;
  startBlock: bigint;
  endBlock: bigint;
  eta: Date | undefined;
  proposer: Address | undefined;
  proposalThreshold: bigint;
  quorumVotes: bigint;
  details: ProposalDetail[];
  transactionHash: Hex;
}

interface ProposalData {
  data: Proposal[];
  loading: boolean;
}

export interface ProposalTransaction {
  address: Address;
  value: bigint;
  signature: string;
  calldata: Hex;
}

export const useHasVotedOnProposal = (
  proposalId: bigint | undefined
): boolean => {
  const { address } = useAccount();
  const { data: receipt } = useReadBeansDaoGetReceipt({
    args: [proposalId !== undefined ? proposalId : 0n, address ?? '0x'],
    query: {
      enabled: proposalId !== undefined && address !== undefined,
    },
  });

  return receipt?.hasVoted ?? false;
};

export const useProposalCount = (): bigint | undefined => {
  const { data: count } = useReadBeansDaoProposalCount({});
  return count;
};

export const useProposalThreshold = (): bigint | undefined => {
  const { data: threshold } = useReadBeansDaoProposalThreshold();
  return threshold;
};

const useVotingDelay = (): bigint | undefined => {
  const { data: count } = useReadBeansDaoProposalCount({});
  return count;
};

const countToIndices = (count: bigint | undefined) => {
  return count !== undefined
    ? Array(Number(count))
        .fill(0n)
        .map((_, i) => BigInt(i) + 1n)
    : [];
};

const useFormattedProposalCreatedLogs = () => {
  const [logs, setLogs] = useState<any>(undefined);
  const client = useClient();

  useEffect(() => {
    async function fetch() {
      if (!client) {
        return;
      }

      const block = await getBlock(client);
      const logs = await getContractEvents(client, {
        ...beansDaoConfig,
        eventName: 'ProposalCreated',
        fromBlock: 0n,
        toBlock: block.number,
      });

      setLogs(
        logs?.map((log) => ({
          description: log.args.description,
          transactionHash: log.transactionHash,
          details: log.args.targets?.map((target: string, i: number) => {
            const signature = log.args.signatures?.[i];
            const value = log.args.values?.[i];
            const [name, types] = signature!
              .substr(0, signature!.length - 1)
              ?.split('(');
            if (!name || !types) {
              return {
                target,
                functionSig:
                  name === ''
                    ? 'transfer'
                    : name === undefined
                      ? 'unknown'
                      : name,
                callData: types
                  ? types
                  : value
                    ? `${formatEther(value)} ETH`
                    : '',
              };
            }
            const calldata = log.args.calldatas?.[i];
            const abiFunction = signature
              ? parseAbiItem(`function ${signature}`)
              : undefined;
            const decoded =
              calldata && abiFunction?.type == 'function'
                ? decodeAbiParameters(abiFunction.inputs, calldata)
                : undefined;
            return {
              target,
              functionSig: name,
              callData: decoded?.join(', '),
              value:
                value && value > 0
                  ? `{ value: ${formatEther(value)} ETH }`
                  : '',
            };
          }),
        }))
      );
    }

    fetch();
  }, [client, setLogs]);

  return logs;
};

export const useAllProposals = (): ProposalData => {
  const proposalCount = useProposalCount();
  const votingDelay = useVotingDelay();

  const govProposalIndexes = useMemo(() => {
    return countToIndices(proposalCount);
  }, [proposalCount]);

  // typing is messed up here...
  const { data: proposalResponses } = useReadContracts({
    contracts: govProposalIndexes.map((index) => ({
      abi: beansDaoConfig.abi as Abi,
      address: beansDaoConfig.address,
      functionName: 'proposals',
      args: [index],
    })),
    allowFailure: true,
  });

  // typing is messed up here...
  const { data: proposalStates } = useReadContracts({
    contracts: govProposalIndexes.map((index) => ({
      abi: beansDaoConfig.abi as Abi,
      address: beansDaoConfig.address,
      functionName: 'state',
      args: [index],
    })),
    allowFailure: true,
  });

  const formattedLogs = useFormattedProposalCreatedLogs();

  // Early return until events are fetched
  return useMemo(() => {
    const logs = formattedLogs ?? [];
    if (
      proposalResponses === undefined ||
      (proposalResponses.length && !logs.length)
    ) {
      return { data: [], loading: true };
    }

    const hashRegex = /^\s*#{1,6}\s+([^\n]+)/;
    const equalTitleRegex = /^\s*([^\n]+)\n(={3,25}|-{3,25})/;

    /**
     * Extract a markdown title from a proposal body that uses the `# Title` format
     * Returns null if no title found.
     */
    const extractHashTitle = (body: string) => body.match(hashRegex);
    /**
     * Extract a markdown title from a proposal body that uses the `Title\n===` format.
     * Returns null if no title found.
     */
    const extractEqualTitle = (body: string) => body.match(equalTitleRegex);

    /**
     * Extract title from a proposal's body/description. Returns null if no title found in the first line.
     * @param body proposal body
     */
    const extractTitle = (body: string | undefined): string | null => {
      if (!body) return null;
      const hashResult = extractHashTitle(body);
      const equalResult = extractEqualTitle(body);
      return hashResult ? hashResult[1] : equalResult ? equalResult[1] : null;
    };

    const removeBold = (text: string | null): string | null =>
      text ? text.replace(/\*\*/g, '') : text;
    const removeItalics = (text: string | null): string | null =>
      text ? text.replace(/__/g, '') : text;

    const removeMarkdownStyle = R.compose(removeBold, removeItalics);

    return {
      data: proposalResponses
        .filter((response) => response.error === undefined)
        .map((response, i) => {
          // Weird problem with type inference here...
          const [
            id,
            proposer,
            proposalThreshold,
            quorumVotes,
            eta,
            startBlock,
            endBlock,
            forVotes,
            againstVotes,
            abstainVotes,
          ] = response.result as [
            bigint, // id
            Address, // proposer
            bigint, // proposalThreshold
            bigint, // quorumVotes
            bigint, // eta
            bigint, // startBlock
            bigint, // endBlock
            bigint, // forVotes
            bigint, // againstVotes
            bigint, // abstainVotes
            boolean, // canceled: unused
            boolean, // vetoed: unused
            boolean, // executed: unused
          ];
          const description = logs[i]?.description?.replace(/\\n/g, '\n');
          return {
            id,
            title:
              R.pipe(extractTitle, removeMarkdownStyle)(description) ??
              'Untitled',
            description: description ?? 'No description.',
            proposer,
            status:
              (proposalStates?.[i]?.result as ProposalState) ??
              ProposalState.UNDETERMINED,
            proposalThreshold,
            quorumVotes,
            forCount: forVotes,
            againstCount: againstVotes,
            abstainCount: abstainVotes,
            createdBlock: startBlock - (votingDelay ?? 0n),
            startBlock,
            endBlock,
            eta: new Date(Number(eta * 1000n)),
            details: logs[i]?.details,
            transactionHash: logs[i]?.transactionHash,
          } as Proposal;
        }),
      loading: false,
    };
  }, [formattedLogs, proposalResponses, proposalStates, votingDelay]);
};

export const useProposal = (id: bigint): Proposal | undefined => {
  const { data } = useAllProposals();
  return data?.find((p) => p.id === id);
};
