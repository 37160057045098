import { Address, Chain } from 'viem';
import { base, baseSepolia } from 'viem/chains';
import dotenv from 'dotenv';

dotenv.config();

interface FallbackUrl {
  main: string;
  fallback?: string;
}

interface ChainConfig {
  chain: Chain;
  rpcHttpUrl: FallbackUrl;
  rpcWebSocketUrl: FallbackUrl;
  scannerBaseUrl: string;
  etherscanApiBaseUrl: string;
  subgraphApiUrl: string;
  addresses: {
    beansToken: Address;
    beansSeeder: Address;
    beansDescriptor: Address;
    nftDescriptor: Address;
    beansAuctionHouse: Address;
    beansAuctionHouseProxy: Address;
    beansAuctionHouseProxyAdmin: Address;
    beansDaoExecutor: Address;
    beansDAOProxy: Address;
    beansDAOLogicV1: Address;
  };
}

const CHAIN_CONFIGS: Record<number, ChainConfig> = {
  [base.id]: {
    chain: base,
    rpcHttpUrl: {
      main: `https://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY!}`,
      // main: 'http://192.168.1.64:8525',
      fallback: 'https://mainnet.base.org',
    },
    rpcWebSocketUrl: {
      main: `wss://base-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY!}`,
      // main: 'ws://192.168.1.64:8526',
      fallback: 'wss://base.gateway.tenderly.co',
    },
    scannerBaseUrl: 'https://basescan.org/',
    etherscanApiBaseUrl: 'https://api.basescan.org/',
    subgraphApiUrl:
      'https://api.studio.thegraph.com/query/66992/beansbase/v0.0.1',
    addresses: {
      beansToken: '0x65EB64E86b71f8Be76b2aedacE781209Db25879D',
      beansSeeder: '0xAcD2Fd98ee3089D7bC733dd7347Ad184E8822A6A',
      beansDescriptor: '0xf519b61DDE4b9855d3764B5A199033dc346C328E',
      nftDescriptor: '0x0000000000000000000000000000000000000000',
      beansAuctionHouse: '0xf467698f68eeAC00D9C6644d6394d534CDA12283',
      beansAuctionHouseProxy: '0xE56a5C5761467888ad95E43a5B172A631C15E376',
      beansAuctionHouseProxyAdmin: '0x02e84F7a16673738b225C14d254B1f20A925c211',
      beansDaoExecutor: '0x5988ffcDE33ED0D488BB06c84505a5Fd05d23A27',
      beansDAOProxy: '0x6fd4c9305AFeA8dF6Fa6039F7F8037D34025E338',
      beansDAOLogicV1: '0x216bE52bD7Dc0E079628947Ccfd2f2F0aD4EC155',
    },
  },
  [baseSepolia.id]: {
    chain: baseSepolia,
    rpcHttpUrl: {
      main: `https://base-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY!}`,
      fallback: 'https://sepolia.base.org',
    },
    rpcWebSocketUrl: {
      main: `wss://base-sepolia.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_API_KEY!}`,
      fallback: 'wss://base-sepolia-rpc.publicnode.com',
    },
    scannerBaseUrl: 'https://sepolia.basescan.org/',
    etherscanApiBaseUrl: 'https://api-sepolia.basescan.org/',
    subgraphApiUrl:
      'https://api.studio.thegraph.com/proxy/66151/basebeans/v0.0.1',
    addresses: {
      beansToken: '0x2E17c4fEA70738Ee38538ce787e7b50C81140507',
      beansSeeder: '0x810240d8090c41DeC73e97dF3b05a67706f410ED',
      beansDescriptor: '0x649d65Bff075e029c686cBBc27Ae361dfEe51E8D',
      nftDescriptor: '0x0000000000000000000000000000000000000000',
      beansAuctionHouse: '0xEa269b7cdbc79344b2f38ad28E0F562B5fB703FC',
      beansAuctionHouseProxy: '0x6bA12FCfEF5ae34C3199ab71c0d8e2C56e5099ff',
      beansAuctionHouseProxyAdmin: '0xD22936B10c2A9059cB4592471B31280c49A89Ce8',
      beansDaoExecutor: '0x0a4C195f057db400B00E78167FEfCA8Ea605bEc5',
      beansDAOProxy: '0x4a8877291f1D791d9d49d558826F714AAA5Df312',
      beansDAOLogicV1: '0x59b47de2ab5Ce0833f1ee0eDE84a2beB1166e08D',
    },
  },
};

export const CHAIN_CONFIG =
  CHAIN_CONFIGS[process.env.REACT_APP_CHAIN_ID! as any];
