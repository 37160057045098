import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import classes from './AuctionActivityDateHeadline.module.css';

dayjs.extend(utc);

const AuctionActivityDateHeadline: React.FC<{ startTime: bigint }> = (
  props
) => {
  const { startTime } = props;
  const auctionStartTimeUTC = dayjs(Number(startTime.toString()) * 1000)
    .utc()
    .format('MMMM DD, YYYY')
    .toUpperCase();
  return (
    <div className={classes.wrapper}>
      <h4
        className={classes.date}
        style={{ color: 'var(--beans-color-primary)' }}
      >{`${auctionStartTimeUTC}`}</h4>
    </div>
  );
};

export default AuctionActivityDateHeadline;
