import React from 'react';
import { Col } from 'react-bootstrap';
import classes from './BeanInfoCard.module.css';
import BeanInfoRowBirthday from '../BeanInfoRowBirthday';
import BeanInfoRowHolder from '../BeanInfoRowHolder';
import BeanInfoRowButton from '../BeanInfoRowButton';
import { useAppSelector } from '../../hooks';
import { CHAIN_CONFIG } from '../../config';
import { buildEtherscanAddressLink } from '../../utils/etherscan';

interface BeanInfoCardProps {
  beanId: bigint;
  bidHistoryOnClickHandler: () => void;
}

const BeanInfoCard: React.FC<BeanInfoCardProps> = (props) => {
  const { beanId, bidHistoryOnClickHandler } = props;
  const etherscanBaseURL = buildEtherscanAddressLink(
    CHAIN_CONFIG.addresses.beansToken
  );
  const etherscanButtonClickHandler = () =>
    window.open(`${etherscanBaseURL}/${beanId}`, '_blank');
  const lastAuctionBeanId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionBeanId
  );

  return (
    <>
      <Col lg={12} className={classes.beanInfoRow}>
        <BeanInfoRowBirthday beanId={beanId} />
      </Col>
      <Col lg={12} className={classes.beanInfoRow}>
        <BeanInfoRowHolder beanId={beanId} />
      </Col>
      <Col lg={12} className={classes.beanInfoRow}>
        <BeanInfoRowButton
          btnText={
            lastAuctionBeanId === Number(beanId.toString())
              ? 'BIDS'
              : 'BID HISTORY'
          }
          onClickHandler={bidHistoryOnClickHandler}
        />
        <BeanInfoRowButton
          btnText={'ETHERSCAN'}
          onClickHandler={etherscanButtonClickHandler}
        />
      </Col>
    </>
  );
};

export default BeanInfoCard;
