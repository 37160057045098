import { Col, Alert, Button } from 'react-bootstrap';
import Section from '../../layout/Section';
import {
  ProposalState,
  ProposalTransaction,
  useProposal,
  useProposalCount,
  useProposalThreshold,
} from '../../wrappers/beansDao';
import { useUserVotes } from '../../wrappers/beanToken';
import classes from './CreateProposal.module.css';
import { Link } from 'react-router-dom';
import { AlertModal, setAlertModal } from '../../state/slices/application';
import ProposalEditor from '../../components/ProposalEditor';
import CreateProposalButton from '../../components/CreateProposalButton';
import ProposalTransactions from '../../components/ProposalTransactions';
import ProposalTransactionFormModal from '../../components/ProposalTransactionFormModal';
import { withStepProgress } from 'react-stepperz';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { useWriteBeansDaoPropose } from '../../generated/wagmiGenerated';
import { useAccount } from 'wagmi';
import { CHAIN_CONFIG } from '../../config';
import RequireCorrectChainButtonContainer from '../../components/RequireCorrectChainButtonContainer';

const CreateProposalPage = () => {
  const { address } = useAccount();

  const latestProposalId = useProposalCount();
  const latestProposal = useProposal(latestProposalId ?? 0n);
  const availableVotes = useUserVotes();
  const proposalThreshold = useProposalThreshold();

  const {
    writeContract: propose,
    status: proposeStatus,
    error: proposeError,
  } = useWriteBeansDaoPropose({});

  const [proposalTransactions, setProposalTransactions] = useState<
    ProposalTransaction[]
  >([]);
  const [titleValue, setTitleValue] = useState('');
  const [bodyValue, setBodyValue] = useState('');

  const handleAddProposalAction = useCallback(
    (transaction: ProposalTransaction) => {
      if (!transaction.address.startsWith('0x')) {
        transaction.address = `0x${transaction.address}`;
      }
      if (!transaction.calldata.startsWith('0x')) {
        transaction.calldata = `0x${transaction.calldata}`;
      }
      setProposalTransactions([...proposalTransactions, transaction]);
      setShowTransactionFormModal(false);
    },
    [proposalTransactions]
  );

  const handleRemoveProposalAction = useCallback(
    (index: number) => {
      setProposalTransactions(
        proposalTransactions.filter((_, i) => i !== index)
      );
    },
    [proposalTransactions]
  );

  const handleTitleInput = useCallback(
    (title: string) => {
      setTitleValue(title);
    },
    [setTitleValue]
  );

  const handleBodyInput = useCallback(
    (body: string) => {
      setBodyValue(body);
    },
    [setBodyValue]
  );

  const isFormInvalid = useMemo(
    () => !proposalTransactions.length || titleValue === '' || bodyValue === '',
    [proposalTransactions, titleValue, bodyValue]
  );

  const hasEnoughVote = Boolean(
    availableVotes &&
      proposalThreshold !== undefined &&
      availableVotes > proposalThreshold
  );

  const handleCreateProposal = async () => {
    if (!proposalTransactions?.length) return;

    await propose({
      args: [
        proposalTransactions.map(({ address }) => address), // Targets
        proposalTransactions.map(({ value }) => value), // Values
        proposalTransactions.map(({ signature }) => signature), // Signatures
        proposalTransactions.map(({ calldata }) => calldata), // Calldatas
        `# ${titleValue}\n\n${bodyValue}`, // Description
      ],
      chainId: CHAIN_CONFIG.chain.id,
    });
  };

  const [showTransactionFormModal, setShowTransactionFormModal] =
    useState(false);
  const [isProposePending, setProposePending] = useState(false);

  const dispatch = useAppDispatch();
  const setModal = useCallback(
    (modal: AlertModal) => dispatch(setAlertModal(modal)),
    [dispatch]
  );

  useEffect(() => {
    switch (proposeStatus) {
      case 'idle':
        setProposePending(false);
        break;
      case 'pending':
        setProposePending(true);
        break;
      case 'success':
        setModal({
          title: 'Success',
          message: 'Proposal Created!',
          show: true,
        });
        setProposePending(false);
        break;
      case 'error':
        const userReject = proposeError?.message.includes('User rejected');
        setModal({
          title: 'Transaction Failed',
          message: userReject
            ? 'User rejected request'
            : proposeError?.message ?? 'Please try again.',
          show: true,
        });
        setProposePending(false);
        break;
    }
  }, [proposeStatus, proposeError, setModal]);

  return (
    <Section fullWidth={false} className={classes.createProposalPage}>
      <ProposalTransactionFormModal
        show={showTransactionFormModal}
        onHide={() => setShowTransactionFormModal(false)}
        onProposalTransactionAdded={handleAddProposalAction}
      />
      <Col lg={{ span: 8, offset: 2 }}>
        <Link to='/vote'>← ALL PROPOSALS</Link>
      </Col>
      <Col lg={{ span: 8, offset: 2 }} className={classes.createProposalForm}>
        <h3 className={classes.heading}>CREATE PROPOSAL</h3>
        <Alert variant='secondary' className={classes.voterIneligibleAlert}>
          <b>
            <span>Tip</span>
          </b>
          : Add one or more transactions and describe your proposal for the
          community. The proposal cannot modified after submission, so please
          verify all information before submitting. The voting period will begin
          after 2 1/3 days and last for 3 days.
        </Alert>
        <div className='d-grid'>
          <Button
            className={classes.addTransactionButton}
            variant='dark'
            onClick={() => setShowTransactionFormModal(true)}
          >
            ADD TRANSACTION
          </Button>
        </div>
        <ProposalTransactions
          proposalTransactions={proposalTransactions}
          onRemoveProposalTransaction={handleRemoveProposalAction}
        />
        <ProposalEditor
          title={titleValue}
          body={bodyValue}
          onTitleInput={handleTitleInput}
          onBodyInput={handleBodyInput}
        />
        <RequireCorrectChainButtonContainer>
          <CreateProposalButton
            className={classes.createProposalButton}
            isLoading={isProposePending}
            proposalThreshold={proposalThreshold}
            hasActiveOrPendingProposal={
              (latestProposal?.status === ProposalState.ACTIVE ||
                latestProposal?.status === ProposalState.PENDING) &&
              latestProposal.proposer === address
            }
            hasEnoughVote={hasEnoughVote}
            isFormInvalid={isFormInvalid}
            handleCreateProposal={handleCreateProposal}
          />
        </RequireCorrectChainButtonContainer>
      </Col>
    </Section>
  );
};

export default withStepProgress(CreateProposalPage);
