import Modal from '../Modal';
import BidHistory from '../BidHistory';

const BidHistoryModal: React.FC<{ onDismiss: () => void; beanId: bigint }> = (
  props
) => {
  const { onDismiss, beanId } = props;
  const wallets = <BidHistory auctionId={beanId} max={9999} />;
  return (
    <Modal
      title={'BEAN #' + beanId.toString() + ' BID HISTORY'}
      content={wallets}
      onDismiss={onDismiss}
    />
  );
};
export default BidHistoryModal;
