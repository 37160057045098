import { Col } from 'react-bootstrap';
import { StandaloneBeanWithSeed } from '../StandaloneBean';
import AuctionActivity from '../AuctionActivity';
import { Row, Container } from 'react-bootstrap';
import { setStateBackgroundColor } from '../../state/slices/application';
import { Auction as IAuction } from '../../wrappers/beansAuction';
import classes from './Auction.module.css';
import { IBeanSeed } from '../../wrappers/beanToken';
import BeanderBeanContent from '../BeanderBeanContent';
import OGBeanContent from '../OGBeanContent';
import MigratedBeanContent from '../MigratedBeanContent';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getBeanType } from '../../utils/beanderBean';
import {
  setNextOnDisplayAuctionBeanId,
  setPrevOnDisplayAuctionBeanId,
} from '../../state/slices/onDisplayAuction';
import { beige, grey } from '../../utils/beanBgColors';

interface AuctionProps {
  auction?: IAuction;
}

const Auction: React.FC<AuctionProps> = (props) => {
  const { auction: currentAuction } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  let stateBgColor = useAppSelector(
    (state) => state.application.stateBackgroundColor
  );
  const lastBeanId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionBeanId
  );

  const loadedBeanHandler = (seed: IBeanSeed) => {
    dispatch(setStateBackgroundColor(seed.background === 0 ? grey : beige));
  };

  const prevAuctionHandler = () => {
    dispatch(setPrevOnDisplayAuctionBeanId());
    currentAuction && navigate(`/bean/${currentAuction.beanId - 1n}`);
  };
  const nextAuctionHandler = () => {
    dispatch(setNextOnDisplayAuctionBeanId());
    currentAuction && navigate(`/bean/${currentAuction.beanId + 1n}`);
  };

  const beanContent = currentAuction && (
    <div className={classes.beanWrapper}>
      <StandaloneBeanWithSeed
        beanId={currentAuction.beanId}
        onLoadSeed={loadedBeanHandler}
        shouldLinkToProfile={false}
      />
    </div>
  );

  const loadingBean = (
    <div className={classes.beanWrapper}>{/* <LoadingBean /> */}</div>
  );

  const currentAuctionActivityContent = currentAuction && lastBeanId && (
    <AuctionActivity
      auction={currentAuction}
      isFirstAuction={currentAuction.beanId === 0n}
      isLastAuction={currentAuction.beanId === BigInt(lastBeanId)}
      onPrevAuctionClick={prevAuctionHandler}
      onNextAuctionClick={nextAuctionHandler}
      displayGraphDepComps={true}
    />
  );
  const beanderBeanContent = currentAuction && lastBeanId && (
    <BeanderBeanContent
      mintTimestamp={currentAuction.startTime}
      beanId={currentAuction.beanId}
      isFirstAuction={currentAuction.beanId === 0n}
      isLastAuction={currentAuction.beanId === BigInt(lastBeanId)}
      onPrevAuctionClick={prevAuctionHandler}
      onNextAuctionClick={nextAuctionHandler}
    />
  );

  const ogBeanContent = currentAuction && lastBeanId && (
    <OGBeanContent
      mintTimestamp={currentAuction.startTime}
      beanId={currentAuction.beanId}
      isFirstAuction={currentAuction.beanId === 0n}
      isLastAuction={currentAuction.beanId === BigInt(lastBeanId)}
      onPrevAuctionClick={prevAuctionHandler}
      onNextAuctionClick={nextAuctionHandler}
    />
  );

  const migratedBeanContent = currentAuction && lastBeanId && (
    <MigratedBeanContent
      auction={currentAuction}
      isFirstAuction={currentAuction.beanId === 0n}
      isLastAuction={currentAuction.beanId === BigInt(lastBeanId)}
      onPrevAuctionClick={prevAuctionHandler}
      onNextAuctionClick={nextAuctionHandler}
    />
  );
  return (
    <div style={{ backgroundColor: stateBgColor }} className={classes.wrapper}>
      <Container>
        <Row>
          <Col lg={{ span: 6 }} className={classes.beanContentCol}>
            {currentAuction ? beanContent : loadingBean}
          </Col>
          <Col lg={{ span: 6 }} className={classes.auctionActivityCol}>
            {/* {currentAuction &&
              (isBeanderBean(currentAuction.beanId)
                ? beanderBeanContent
                : currentAuctionActivityContent)} */}
            {(() => {
              if (currentAuction) {
                // beanteam
                if (getBeanType(currentAuction.beanId) === 1) {
                  return beanderBeanContent;
                  // og bean
                } else if (getBeanType(currentAuction.beanId) === 2) {
                  return ogBeanContent;
                } else if (getBeanType(currentAuction.beanId) === 3) {
                  return migratedBeanContent;
                } else {
                  return currentAuctionActivityContent;
                }
              }
            })()}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Auction;
