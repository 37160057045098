import React from 'react';
import { formatEther } from 'viem';

const TruncatedAmount: React.FC<{ amount: bigint }> = (props) => {
  const { amount } = props;

  const eth = Number(formatEther(amount)).toFixed(2);
  return <>{`${eth}`}</>;
};
export default TruncatedAmount;
