import { Form, Button } from 'react-bootstrap';
import Link from '../Link';
import Modal from '../Modal';
import classes from './AgreementModal.module.css';
import React, { useState } from 'react';

const AgreementModal: React.FC<{
  onDismiss: () => void;
  setReadAgreementState: any;
  isRead: boolean;
}> = (props) => {
  const { onDismiss, setReadAgreementState, isRead } = props;

  const privacyLink = (
    <Link text='Privacy Policy' url='/privacy' leavesPage={false} />
  );
  const tosLink = (
    <Link text='Terms of Service' url='/terms' leavesPage={false} />
  );
  const operatingLink = (
    <Link text='Operating Agreement' url='/operating' leavesPage={false} />
  );

  const [showContinueBtn, setShowContinueBtn] = useState(isRead);

  const showContinueButton = (checked: boolean) => {
    setShowContinueBtn(checked);
  };

  const body = (
    <div>
      <Form>
        <div className='d-flex'>
          <Form.Check
            type='checkbox'
            id={`default-checkbox`}
            checked={isRead}
            onChange={(e) => {
              setReadAgreementState(e.target.checked);
              showContinueButton(e.target.checked);
            }}
          />
          <Form.Text className={classes.formTextColor}>
            By checking this box, I acknowledge that I have read and agreed to
            the {tosLink}, {privacyLink}, and {operatingLink}
          </Form.Text>
        </div>
        {showContinueBtn && (
          <div className='d-flex'>
            <Button
              onClick={() => {
                onDismiss();
              }}
              className={classes.continueBtn}
            >
              CONTINUE
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
  return (
    <Modal title={'BEANS AGREEMENT'} content={body} onDismiss={onDismiss} />
  );
};
export default AgreementModal;
