import classes from './Bean.module.css';
import React from 'react';
import Image from 'react-bootstrap/Image';
import Lottie from 'lottie-react';
import beansLoadingAnimation from '../../assets/lottie/loading_bean.json';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';

export const LoadingBean = () => {
  return (
    <div className={classes.imgWrapper}>
      <Lottie
        animationData={beansLoadingAnimation}
        loop={true}
        autoplay={true}
      />
    </div>
  );
};

function renderPopper(props: any) {
  const size = props[1].value;
  const vibe = props[2].value;
  const class1 = props[3].value;
  const class2 = props[4].value;
  const helmet = props[5].value;
  const gear = props[6].value;
  return (
    <Popover className={classes.popover} id='transaction-details-popover-2'>
      <Popover.Header className={classes.popoverheader} as='h3'>
        BEAN ATTRIBUTES
      </Popover.Header>
      <Popover.Body className={classes.popoverbody}>
        <Row>
          <Col>
            <b>Class 1</b>
          </Col>
          <Col>{class1}</Col>
        </Row>
        <Row>
          <Col>
            <b>Class 2</b>
          </Col>
          <Col>{class2}</Col>
        </Row>
        <Row>
          <Col>
            <b>Size</b>
          </Col>
          <Col>{size}</Col>
        </Row>
        <Row>
          <Col>
            <b>Gear</b>
          </Col>
          <Col>{gear}</Col>
        </Row>
        <Row>
          <Col>
            <b>Helmet</b>
          </Col>
          <Col>{helmet}</Col>
        </Row>
        <Row>
          <Col>
            <b>Vibe</b>
          </Col>
          <Col>{vibe}</Col>
        </Row>
      </Popover.Body>
    </Popover>
  );
}

const Bean: React.FC<{
  imgPath: string;
  alt: string;
  className?: string;
  wrapperClassName?: string;
  attributes?: Array<any>;
}> = (props) => {
  const { imgPath, alt, className, wrapperClassName, attributes } = props;
  if (imgPath) {
    if (attributes) {
      return (
        <div className={`${classes.imgWrapper} ${wrapperClassName}`}>
          <OverlayTrigger
            trigger={['hover', 'focus']}
            placement='auto'
            overlay={renderPopper(attributes)}
          >
            <Image
              className={`${classes.img} ${className}`}
              src={imgPath}
              alt={alt}
              fluid
            />
          </OverlayTrigger>
        </div>
      );
    } else {
      return (
        <div className={`${classes.imgWrapper} ${wrapperClassName}`}>
          <Image
            className={`${classes.img} ${className}`}
            src={imgPath}
            alt={alt}
            fluid
          />
        </div>
      );
    }
  } else {
    return (
      <div>
        <Lottie
          className={classes.lottie}
          animationData={beansLoadingAnimation}
          loop={true}
          autoplay={true}
        />
      </div>
    );
  }
};

export default Bean;
