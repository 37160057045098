import { ReactNode, useMemo } from 'react';
import { useAccount } from 'wagmi';
import { useChainModal, useConnectModal } from '@rainbow-me/rainbowkit';
import { CHAIN_CONFIG } from '../config';

interface RequireCorrectChainButtonContainerProps {
  children: ReactNode;
}

export default function RequireCorrectChainButtonContainer({
  children,
}: RequireCorrectChainButtonContainerProps) {
  const { isConnected, chainId } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { openChainModal } = useChainModal();

  const [notConnected, wrongNetwork] = useMemo(() => {
    return [!isConnected, chainId != CHAIN_CONFIG.chain.id];
  }, [isConnected, chainId]);

  return (
    <div
      onClick={() => {
        if (notConnected) {
          openConnectModal?.();
        } else if (wrongNetwork) {
          openChainModal?.();
        }
      }}
      className='cursor-pointer'
    >
      <div
        className={
          notConnected || wrongNetwork
            ? 'pointer-events-none'
            : 'pointer-events-auto'
        }
      >
        {children}
      </div>
    </div>
  );
}
