import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Name } from '@coinbase/onchainkit';
import { Address } from 'viem';

export default function ConnectWalletButton() {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        mounted,
      }) => {
        const ready = mounted;
        const connected = ready && account && chain;

        return (
          <div
            className='flex items-center justify-center text-[12px] transition-all hover:scale-[1.03] active:scale-[0.97]'
            style={{ fontFamily: "'Druk Wide, sans-serif'" }}
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    onClick={openConnectModal}
                    className='border-1 rounded-xl border-white px-3 py-2 text-white'
                  >
                    CONNECT
                  </button>
                );
              }

              if (chain.unsupported) {
                return (
                  <button
                    onClick={openChainModal}
                    className='flex flex-row items-center justify-center gap-2 rounded-xl bg-red-500 px-3 py-2 text-white'
                  >
                    WRONG NETWORK
                    <img
                      src='/icons/chevron-down-white.svg'
                      style={{ stroke: 'white' }}
                      alt=''
                    />
                  </button>
                );
              }

              return (
                <button
                  onClick={openAccountModal}
                  className='flex flex-row items-center justify-center gap-2 rounded-xl bg-white px-3 py-2 uppercase'
                >
                  <Name address={account.address as Address} />
                  <img src='/icons/chevron-down-black.svg' alt='' />
                </button>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
