import { CHAIN_CONFIG } from '../config';

export enum ExternalURL {
  discord,
  twitter,
  notion,
  discourse,
  opensea,
  operating,
  privacy,
  terms,
  farcaster,
}

export const externalURL = (externalURL: ExternalURL) => {
  let baseurl = 'https://beans.wtf/';
  if (
    Number(CHAIN_CONFIG.chain.id) !== 1 ||
    Number(CHAIN_CONFIG.chain.id) !== 8453
  ) {
    baseurl = 'https://test.webapp-1dv.pages.dev/';
  }

  switch (externalURL) {
    case ExternalURL.discord:
      return 'https://discord.gg/Cau3fCAjts';
    case ExternalURL.twitter:
      return 'https://twitter.com/beansdaowtf';
    case ExternalURL.opensea:
      return 'https://opensea.io/collection/beansdaowtf-1';
    case ExternalURL.farcaster:
      return 'https://warpcast.com/~/channel/beans';
    case ExternalURL.operating:
      return baseurl + 'operating.docx';
    case ExternalURL.privacy:
      return baseurl + 'privacy.docx';
    case ExternalURL.terms:
      return baseurl + 'terms.docx';
  }
};
