import classes from './Link.module.css';
import { Link as RouterLink } from 'react-router-dom';

const Link: React.FC<{ text: string; url: string; leavesPage: boolean }> = (
  props
) => {
  const { text, url, leavesPage } = props;
  return leavesPage ? (
    <a className={classes.link} href={url} target='_blank' rel='noreferrer'>
      {text}
    </a>
  ) : (
    <RouterLink
      className={classes.link}
      to={url}
      target='_self'
      rel='noreferrer'
    >
      {text}
    </RouterLink>
  );
};
export default Link;
