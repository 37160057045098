import { Container, Row, Col } from 'react-bootstrap';
import Section from '../../layout/Section';
import classes from './Operating.module.css';
import React from 'react';

const Terms: React.FC = () => {
  return (
    <>
      <Container fluid='lg' className='privacyContainer'>
        <Row className='justify-content-center'>
          <Col lg={8} className={classes.headerRow}>
            <h1>OPERATING AGREEMENT</h1>
            <p>Last Updated: January 15, 2024</p>
            <p>LIMITED LIABILITY COMPANY AGREEMENT</p>
            <p>(ALGORITHMICALLY MANAGED)</p>
            <p>OPERATING AGREEMENT OF BEANS FOUNDATION DAO LLC</p>
          </Col>
        </Row>
        <Section fullWidth={false} className={classes.sectionClass}>
          <Col lg={{ span: 10, offset: 1 }}>
            <div className={classes.headerWrapper}>
              <p className={classes.paragraphText}>
                This Operating Agreement (“the Agreement”) of Beans Foundation
                DAO LLC, a non-profit limited liability company (the “DAO”)
                incorporated as per the laws of Republic of the Marshall Islands
                pursuant to the Limited Liability Company Act of 1996 of the
                Republic of the Marshall Islands (as amended from time to time,
                the “LLC Act”) and the Marshall Islands Non-Profit Entities
                (Amendment) Act of 2021 (as amended from time to time the
                “Non-Profit Act”, collectively with the LLC Act, the “Acts”), by
                and among the DAO and the persons executing this Agreement
                (individually “Initiating Member” and collectively “Initiating
                Members”, as further defined below), causing the filing of the
                certificate of formation of the DAO (the “Certificate of
                Formation”), with the Republic of the Marshall Islands Registrar
                of Corporations effective as of July 10, 2023 (the “Effective
                Date”).
              </p>
              <p className={classes.paragraphText}>
                NOW, THEREFORE, for and in consideration of mutual covenants
                contained and intending to be legally bound hereby, the parties
                agree as follows:
              </p>
              <p className={classes.operatingCenteredParagraph}>ARTICLE I</p>
              <p className={classes.operatingCenteredParagraph}>DEFINITIONS</p>
              <p className={classes.paragraphText}>
                <b>"Contribution”</b> means the total amount of cash and cash
                equivalents and the monetary value of any property contributed
                to the DAO by such Initiating Member, Founding Member, or
                Member.
              </p>
              <p className={classes.paragraphText}>
                <b>“Control”</b> means lawful possession of any Membership NFT,
                as provided for in the Acts or this Agreement.
              </p>
              <p className={classes.paragraphText}>
                <b>“DAO Management Contract”</b> means the Smart Contract that
                governs the ordinary and usual decisions concerning affairs of
                the DAO and where Member proposals are submitted to and votes
                are counted.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“DAO Official action”</b> means any decision on, or proposal,
                consideration, enactment, defeat, or making of any rule,
                regulation, proceeding or policy action or nonaction by, or made
                on behalf of the DAO as authorized by the DAO via On-Chain
                Election.
              </p>
              <p className={classes.paragraphText}>
                <b>“Digital Asset”</b> means representations of economic,
                proprietary or access rights that are stored in a computer
                readable format.
              </p>
              <p className={classes.paragraphText}>
                <b>“DIP”</b> means "DAO Improvement Proposal." DIPs are
                proposals which Members publish to the Forum, and upon community
                discussion, may be formally voted on in On-Chain Elections in
                accordance with this Agreement.
              </p>
              <p className={classes.paragraphText}>
                <b>“Election”</b> refers to either an On-Chain Election or
                Snapshot Election. Snapshot Elections may only be used for
                initial sentiment polls of Members, and only proposals that
                successfully pass the polling stage are put up for an On-Chain
                Election.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Ethereum Blockchain”</b> means the “Open Blockchain,”
                consisting of the underlying software, Ethereum Network, and
                protocols all necessary for the successful creation,
                functionality, and immutable transactional record of all Smart
                Contracts and NFTs.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Founding Member”</b> means the member designated by the
                Initiating Members.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Forum”</b> means the publicly available web application
                which provides for an official community discussion and
                commentary regarding the proposals made in the DIPs.
              </p>
              <p className={classes.paragraphText}>
                <b>“Initiating Member”</b> means any designated member of the
                DAO who is afforded all rights and benefits as provided for by
                this Agreement. An Initiating Member must hold an Initiating
                Member NFT.
              </p>
              <p className={classes.paragraphText}>
                <b>“Initiating and Founding Member Benefit”</b> refers to the
                reward of Membership NFTs provided to Initiating Members and the
                Founding Member. Every tenth Membership NFT auctioned via
                beans.wtf will be distributed to the Initiating Members holding
                an Initiating Member NFT and the Founding Member on an
                alternating basis, for the five year period commencing on the
                date of the auction of the first Membership NFT.
              </p>
              <p className={classes.paragraphText}>
                <b>“Initiating Member NFT”</b> means any one of the specific
                one-of-three NFTs minted by the Initiating Members.
              </p>
              <p className={classes.paragraphText}>
                <b>“Gnosis Safe”</b> is the Smart Contract Ethereum Account used
                by the DAO for its Treasury, requiring a Multi-Signature Smart
                Contract in order to access the Digital Assets held by the DAO,
                effectuate transaction, and/or interact with the Ethereum
                Network.
              </p>
              <p className={classes.paragraphText}>
                <b>“Governance Rights”</b> means the governance rights
                established by this Agreement and the Acts, including those
                rights set forth in Article V.6.
              </p>
              <p className={classes.paragraphText}>
                <b>“Member”</b> means any member of the DAO who is afforded all
                rights and benefits, including Governance Rights, as provided
                for by the Acts and this Agreement. A Member must hold a
                Membership NFT and consent to this Agreement by executing a
                transaction via such Member’s wallet (via an Election or another
                governance tool) or consent to this Agreement in accordance with
                Article IV contained herein.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Membership”</b> refers to the right of being a Member of the
                DAO under this agreement.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Member Interest”</b> means the voting right conferred by
                virtue of Possession and Control of a Membership NFT. Each
                Membership NFT represents one unit of voting interest.
              </p>
              <p className={classes.paragraphText}>
                <b>“Member(ship) NFT”</b> means specifically any one of the NFTs
                minted by the Members which are algorithmically governed by the
                Membership Smart Contract.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Multi-Signature Smart Contract”</b> means the system of
                access and/or controls relating to the DAO’s Digital Assets for
                the purposes of preventing unauthorized transactions, in which
                three or more Private Keys are required to conduct a
                transaction.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“On-Chain Election”</b> means the process where individual
                votes are submitted as transactions, and such votes are tallied
                and recorded on the blockchain. Any Member voting in an On-Chain
                Election must pay a transaction fee for each vote.
              </p>
              <p className={classes.paragraphText}>
                <b>“Possession”</b> means the ability to exclude others from the
                use of property, and includes use of a private key, a
                multi-signature Smart Contract exclusive to the secured party, a
                Smart Contract, or any similar analogue.
              </p>
              <p className={classes.paragraphText}>
                <b>“Royalties”</b> means the percentage of fees automatically
                distributed to the Treasury upon any sales of NFTs originally
                minted by the DAO.
              </p>
              <p className={classes.paragraphText}>
                <b>“Secondary Market”</b> means any secondary market for the
                buying, selling or trading of NFTs in which a Membership NFT may
                be reasonably acquired (for example, OpenSea.io).
              </p>
              <p className={classes.paragraphText}>
                <b>“Smart Contract”</b> means an automated transaction which is
                comprised of code, script or programming language that executes
                the terms of an agreement and which may include taking custody
                of and transferring an asset, administrating membership interest
                votes with respect to a decentralized autonomous organization or
                issuing executable instructions for these actions, based on the
                occurrence or nonoccurrence of specified conditions.
              </p>
              <p className={classes.paragraphText}>
                <b>“Snapshot Election”</b> means the act of Members connecting
                the open-sourced Snapshot Application with a web3 enabled
                Ethereum Account in Possession and Control of a Membership NFT
                in order to cast a vote on any DIP which is stored in the IPFS
                decentralized storage system. Voting power is calculated at the
                “snapshot” of the DIP which occurs on a specific block number of
                the Ethereum Blockchain which is predetermined.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Treasury”</b> means the Ethereum Account/Ethereum Address
                that is a Gnosis Safe and requires a Multi-Signature Smart
                Contract, with the DAO in Control and Possession of such
                Treasury; any Contributions or Royalties are automatically
                deposited into this Ethereum Account/Address.{' '}
              </p>
              <p className={classes.paragraphText}>
                <b>“Wallets”</b> are identified unique electronic “addresses”
                beginning with 0x that are run verified, recorded, and
                self-executed through a decentralized system using cryptography
                on the Ethereum Network.{' '}
              </p>
              <p className={classes.operatingCenteredParagraph}>ARTICLE II</p>
              <p className={classes.operatingCenteredParagraph}>ORGANIZATION</p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Organization. </b> The Members, by execution of this
                    Agreement hereby agree to organize the DAO as a Marshall
                    Islands nonprofit limited liability company pursuant to the
                    provisions of the Act, Non-Profit Act and upon the terms and
                    conditions set forth in this Agreement.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Registered Office; Registered Agent.</b> The registered
                    office of the DAO in the Marshall Islands shall be the
                    initial registered office designated in the Certificate of
                    Formation or such other office (which need not be a place of
                    business of the DAO) as the Members may designate from time
                    to time in the manner provided by law. The registered agent
                    of the DAO in the Marshall Islands shall be the initial
                    registered agent designated in the Certificate of Formation
                    or such other person as the Members may designate from time
                    to time in the manner provided by law.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Principal Office. </b> The Principal Office of the DAO
                    shall be at the principal office designated in the
                    Certificate of Formation or at such other location as the
                    Members may designate from time to time, which need not be
                    in the Marshall Islands.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Term. </b> The DAO commenced on the Effective Date and
                    shall continue in existence until dissolved pursuant to
                    Article XII of this Agreement.
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE III</p>
              <p className={classes.operatingCenteredParagraph}>
                PURPOSES AND POWERS
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Nonprofit Character. </b> The DAO shall be a non-profit
                    entity within the meaning of the Non-Profit Act.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Purposes of the DAO. </b> The DAO is organized
                    exclusively for charitable and educational purposes and not
                    for profit. Specifically, the DAO was established for the
                    purpose and mission of exploring decentralized governance
                    mechanisms, exploring methods to use blockchain technology
                    to promote social goods, supporting the arts and artistic
                    innovation in various forms, and proliferating the “Bean”
                    character featured in the Membership NFTs (the “Mission”).
                  </p>{' '}
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Authority of the DAO. </b> The DAO shall have and
                    exercise all of the powers conferred by law on non-profit
                    entities.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>No Private Inurement. </b> No part of its assets, income
                    or earnings shall inure to the benefit of, or be
                    distributable to, any Member, officer, employee, or any
                    private individual, except that reasonable compensation may
                    be paid for services rendered to or for the DAO effecting
                    one or more of its objects and purposes or for reimbursement
                    of expenses incurred on behalf of the DAO. No Member,
                    officer or employee of the DAO, or any private individual,
                    shall be entitled to share in the distribution of any of the
                    DAO’s assets on dissolution of the DAO.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Limitation of DAO Activities. </b> The DAO shall never be
                    operated for the primary purpose of carrying on a trade or
                    business for profit. No part of the activities of the DAO
                    shall include (i) carrying on propaganda, (ii) attempting in
                    any manner to influence legislation, except that Members of
                    the DAO and personnel of the DAO may testify or make other
                    appropriate communications where formally requested to do so
                    by a legislative body or a committee or a member thereof in
                    matters concerning legislation relating to the public
                    purposes of the DAO or public appropriations to programs and
                    activities of the DAO, or (iii) participating or intervening
                    in (including the publication or distribution of
                    statements), or contributing to, any political campaign on
                    behalf of any candidate for public office.{' '}
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE IV</p>
              <p className={classes.operatingCenteredParagraph}>MEMBERS</p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Member Eligibility. </b> All Members shall at all times
                    own their Membership Interests in their own right.
                    Membership shall be limited to those individuals or entities
                    that are holders of a Membership NFT. The DAO is an
                    algorithmically managed decentralized autonomous
                    organization.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Classes of Membership. </b>
                    <ol className={classes.paragraphListNumbered} type='a'>
                      <li>
                        <p className={classes.paragraphText}>
                          Members shall have voting rights equal to their
                          Membership Interests on all matters requiring a vote.{' '}
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraphText}>
                          Initiating Members shall have the additional rights
                          and duties set forth in this Agreement and shall
                          receive the Initiating and Founding Member Benefit.{' '}
                        </p>
                      </li>
                      <li>
                        <p className={classes.paragraphText}>
                          The Founding Member shall receive the Initiating and
                          Founding Member Benefit.
                        </p>
                      </li>
                    </ol>
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Levels of Membership. </b> Future levels of Membership
                    may be established by the DAO, provided any such
                    establishment adheres to and is governed by the mechanism
                    and procedures provided for by the Acts and this Agreement.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>No Partnership or Joint Venture. </b> The Members intend
                    that the DAO shall not be a partnership or joint venture,
                    and that no Member shall be a partner or joint venturer with
                    respect to the activities of the DAO and this Agreement
                    shall not be construed to suggest otherwise.
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE V</p>
              <p className={classes.operatingCenteredParagraph}>
                RIGHTS AND DUTIES OF MEMBERS
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Algorithmic Management. </b> All Members who have not
                    Dissociated shall be entitled to vote on decisions
                    concerning the affairs of the DAO submitted to a vote of the
                    Members via On-Chain Election. On-Chain Elections shall be
                    decided via a vote of the Members and shall require approval
                    or consent of at least 51% of the voting Membership
                    Interests participating in the applicable On-Chain Election.
                    Members collectively holding more than two percent (2%) of
                    the Membership Interest in the DAO must vote and participate
                    in an On-Chain Election in order to constitute a “Quorum”.
                    If a Quorum has not voted in an On-Chain Election, such
                    On-Chain Election will be terminated and the results of any
                    votes cast will be null and void, and shall have no effect.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Liability of Members. </b> Subject to Article <b>V.3</b>,
                    no Member(s), Initiating Member(s), or the Founding Member
                    will be obligated personally for any debt, obligation, or
                    liability of the DAO, other Members, or Initiating
                    Member(s), whether arising in contract, tort, or otherwise,
                    solely by reason of being or acting as a Member, Initiating
                    Member, or Founding Member. The failure of the DAO to
                    observe any formalities or requirements relating to the
                    exercise of its powers or management of its business or
                    affairs under the Agreement or the Acts shall not be grounds
                    for imposing personal liability on the Members, Initiating
                    Members, or Founding Member for liabilities of the DAO. In
                    the event the Initiating Members exercise their rights under
                    Article <b>V.11</b> of this agreement, no Initiating Members
                    shall be liable for any such liabilities of the DAO that may
                    occur as a result of the Initiating Members’ use of the
                    Article <b>V.11</b> right.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Miscellaneous Obligations. </b> It shall be the duty of
                    each Member to act at all times consistently and in
                    compliance with all and each of the provisions of this
                    Agreement and with all policies, rules and decisions of the
                    DAO adopted in accordance with any of the provisions of this
                    Agreement.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Authority and Lack of Agency. </b> Except as otherwise
                    provided in this Agreement, no individual Members shall have
                    the authority or power to solely act for or on behalf of the
                    DAO or otherwise operate in an agency capacity – either
                    explicitly or implicitly. This includes, but is not limited
                    to, conducting any acts that would be binding on the DAO or
                    would result in the DAO incurring any expenditures, debts,
                    liabilities or obligations not already approved by the DAO.
                    Notwithstanding the foregoing, the Initiating Members are
                    hereby granted the authority and power to act on behalf of
                    the DAO within the ordinary course of business, provided
                    withdrawals from the Treasury are prohibited absent an
                    On-Chain Election. Such acts of the Initiating Members in
                    the ordinary course of business include, but are not limited
                    to, authorizing actions and expenses related to the website,
                    conducting administrative duties, handling managerial duties
                    such as tax or legal matters, and other activities that the
                    Initiating Members deem ordinary and necessary.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Standards of Conduct for Members. </b> No member shall
                    have any fiduciary duty to the DAO or any other Member
                    except that of the implied contractual covenant of good
                    faith and fair dealing.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Governance Rights. </b> Any Person or Ethereum Account
                    having Possession or Control of any Membership NFT has the
                    right to partake in community discussions, propose
                    independent DIPs, vote in Elections, and join in all other
                    reasonable governance activities essential to the
                    functioning of the DAO as a decentralized and democratic
                    legal entity.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Reimbursement. </b> The DAO is not liable for reimbursing
                    Members for any and all direct out-of-pocket expenses
                    reasonably incurred when conducting operations connected
                    with DAO activities of any sort. This includes any
                    transactional charges incurred while interacting in any
                    manner with the Ethereum Network, notably, for fees related
                    to gas or failed transactions, or any loss stemming from an
                    outside attack or hack. Notwithstanding the forgoing, any
                    Member(s) acting pursuant to a DAO Official Action, approved
                    via Election, shall be entitled to reimbursement of
                    reasonable expenses incurred in connection such DAO Official
                    Action(s) in accordance with this Agreement (upon providing
                    written documentation of such reasonably incurred expenses).
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Future Rights or Duties. </b> As the DAO evolves and
                    refines its activities, any new services or projects will be
                    assigned as future Governance Rights as appropriate.
                    Furthermore, any reasonable governance standards or
                    procedures may be established by the DAO through a On-Chain
                    Election (or other future DAO approved governance protocol)
                    which may impose or confer rights and/or duties on Members,
                    provided implementation is executed in accordance with the
                    Acts and this Agreement.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Guilds. </b> The DAO may authorize the establishment of
                    organizations of Members tasked with carrying out specific
                    functions related to DAO activities (“Guilds”).{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Bounties. </b> The DAO may authorize or solicit its
                    Members to perform bounties as necessary.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Emergency Veto Mechanism. </b> In order to protect the
                    integrity of the DAO, the DAO’s purpose, and its Members,
                    the Initiating Members have the power to veto any proposals
                    that introduces an existential risk to the DAO (“Emergency
                    Veto”), including (but not necessarily limited to) proposals
                    that relate to the following “Violation Actions”:
                  </p>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <li>
                      <p className={classes.paragraphText}>
                        Unequally withdraw the Treasury for personal gain;
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Bribe Members to facilitate withdraws of the Treasury
                        for personal gain;
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Attempt to alter the DAO’s voting procedures for the
                        purpose of acquiring a voting majority;
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Make upgrades to smart contracts without undergoing an
                        audit;
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        May jeopardize the security of the DAO, Treasury or
                        Members;{' '}
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        May jeopardize the DAO’s status as a not-for-profit
                        entity;{' '}
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Take any action inconsistent with the DAO’s Mission;
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Further or promote any unethical or illegal activities;
                        or
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        Attempt to amend this Agreement in a manner in
                        furtherance of any of the above items.{' '}
                      </p>
                    </li>
                  </ol>
                  <p className={classes.paragraphText}>
                    The Initiating Members are required to make their
                    determination of whether to invoke this provision in the
                    best and most reasonable interest of the DAO, the DAO’s
                    purpose, and its Members. Initiating Members may elect to
                    enact an Emergency Veto by majority vote of the Initiating
                    Members.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Expulsion of Initiating Member </b> An Initiating
                    Member may be expelled from the DAO upon a unanimous vote of
                    the other Initiating Members for Cause. “Cause” for
                    expulsion exists if the Initiating Member has materially
                    breached this Agreement, is unable to perform the Initiating
                    Member's material obligations under this Agreement, if the
                    other Initiating Members has reasonable belief that such
                    Initiating Member has violated federal or state law, rules,
                    and regulations or the Initiating Member is under
                    investigation by the federal, state, and/or local
                    authorities, or has engaged in any Violation Action. If an
                    Initiating Member is expelled, that Initiating Member
                    forfeits any and all rights granted under this Agreement. An
                    Initiating Member's expulsion from the DAO will be effective
                    upon the Initiating Member's receipt of written notice of
                    the expulsion. All Initiating Members hereby agree to fully
                    cooperate with these expulsion and forfeiture proceedings,
                    including, but not limited to, transference of any
                    Initiating Member NFT, Membership NFT, applicable private
                    keys, or wallets containing any of the aforementioned items.
                    In the event an Initiating Member is expelled in accordance
                    with this provision, this Operating Agreement must be
                    restated and revised to reflect such, and the remaining
                    Initiating Members shall work in good faith to create
                    revised terms related to potential future Initiating Member
                    expulsion.{' '}
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE VI</p>
              <p className={classes.operatingCenteredParagraph}>
                CONSENT, NOTICES, REGULATORY INFORMATION
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Consent to This Agreement. </b> The DAO is an
                    algorithmically managed decentralized autonomous
                    organization as described in Article V. Any person, entity,
                    decentralized autonomous organization, or other legally
                    recognized person is deemed to have consented to this
                    agreement upon (i) signing and approving this Agreement (ii)
                    acquiring a Member NFT via beans.wtf, a Secondary Market,
                    transfer or other means to hold in his/her/its Wallet after
                    the Effective Date of this Agreement; (iii) otherwise
                    Signing for this Agreement with his/her/its Wallet after the
                    Effective Date; (iv) or participating in a subsequent mint.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>
                      Notice of Restriction on Duties and Transfers. The rights
                      of members in a decentralized autonomous organization may
                      differ materially from the rights of members in other
                      limited liability companies. The underlying smart
                      contracts, articles of organization and operating
                      agreement, if applicable, of a decentralized autonomous
                      organization may define, reduce or eliminate fiduciary
                      duties and may restrict transfer of ownership interests,
                      withdrawal or resignation from the decentralized
                      autonomous organization, return of capital contributions
                      and dissolution of the decentralized autonomous
                      organization.
                    </b>{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Not Registered with Securities Regulators. </b> Neither
                    this Agreement, nor any Membership in the DAO are intended
                    to constitute an investment of any kind, nor are they meant
                    to represent an offer and sale of any form of an investment
                    instrument as understood within the context of the
                    securities laws of any jurisdiction. Nothing referred to in
                    this Agreement, nor the Agreement itself, has been
                    registered, approved, or disapproved by any securities
                    authority.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Members Bear Risks. </b> The Members referred to in this
                    Agreement should be aware that they bear any risks involved
                    in becoming a Member of the DAO. Members also bear the risks
                    involved with holding any Membership NFT, if any, for as
                    long as they hold a Membership NFT. In no circumstance shall
                    a Member be deemed to be personally liable for any damages
                    which may stem from their involvement in DAO related
                    activities, unless such liability arises from the gross
                    negligence or willful misconduct of the Member, as described
                    below in greater detail in Section <b>VI.2</b> and{' '}
                    <b>XIV.5.</b>
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE VII</p>
              <p className={classes.operatingCenteredParagraph}>
                INTEGRATION AND UTILIZATION OF SMART CONTRACTS
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Smart Contract Transactions. </b> All transactions
                    involving the DAO’s Smart Contracts will be verified by the
                    Ethereum Network and recorded on the Ethereum Blockchain.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Treasury. </b> The DAO shall maintain a publicly viewable
                    Treasury where all deposits, withdrawals, holdings, and
                    other types of transactions involving the DAO’s Digital
                    Assets will be recorded on the Ethereum Blockchain.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Gnosis Safe and Multi-Signature Agreement. </b> The DAO
                    shall require, throughout its existence, that three
                    Initiating Members hold the private keys of a
                    Multi-Signature Smart Contract Ethereum Account, in order to
                    ensure that any transactions of Digital Assets held in the
                    Treasury made by the DAO will require the approval of a
                    majority of Initiating Members. The Digital Assets that are
                    in the Control and Possession of the DAO are currently
                    stored using the Gnosis Safe platform.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Forks and Upgrades. </b> The DAO recognizes the Proof of
                    Stake Ethereum Blockchain generally recognized. The DAO may
                    decide to recognize other blockchains or bridges in
                    accordance with the Governance Rights afforded in this
                    Agreement. Thus, for example, if a fork, merge, or other
                    event purports to result in duplicate Member NFTs, only the
                    non-fungible token recorded on the blockchain designated by
                    the DAO will be eligible to receive the benefit Membership.
                    Any rights purportedly granted hereunder to the owner of a
                    non-fungible token recorded on a blockchain not designated
                    by the DAO is void ab initio.
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE VIII</p>
              <p className={classes.operatingCenteredParagraph}>
                ACCOUNTING AND RECORDS
              </p>
              <p className={classes.paragraphText}>
                The Members shall maintain the following records at the
                Principal Office or in the smart contracts referenced in this
                Agreement:{' '}
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    A current list of name and last known address of each
                    Member, or (ii) Digital Ledger identifier of each Member,
                    former Member and other holder of a Membership Interest.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    A copy of the Certificate of Formation and all amendments
                    thereto, together with executed copies of any powers of
                    attorney pursuant to which the Certificate of Formation has
                    been executed.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    A copy of this Agreement including all amendments thereto.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    Any accounting records and financial statements of the DAO.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    Minutes of all meetings, or records of all actions taken
                    without a meeting by, the Members.{' '}
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE IX</p>
              <p className={classes.operatingCenteredParagraph}>
                CONTRIBUTIONS AND CAPITAL ACCOUNTS
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Initial Contributions.</b> On or before the date of
                    filing of the Certificate of Formation with the registrar of
                    corporations, each Initial Member has made a sufficient
                    Capital Contribution to the Company for the Company to begin
                    its operations. No interest shall accrue on any Capital
                    Contribution and no Member shall have the right to withdraw
                    or be repaid any Capital Contribution except as provided in
                    the Agreement.{' '}
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE X</p>
              <p className={classes.operatingCenteredParagraph}>
                DISSOCIATION OF A MEMBER AND TRANSFER OF MEMBERSHIP
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Membership Withdrawal or Dissociation</b> Any Member has
                    the power to dissociate as a member of the DAO at any time,
                    rightfully or wrongfully, by withdrawing as a Member of the
                    DAO by express will. Members may not have the DAO dissolved
                    for a failure to return any Contribution of any Member, if
                    applicable. Upon withdrawal, that Member forfeits all
                    interests in the DAO, including any governance or economic
                    rights.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    A Member shall cease to be a Member (“Dissociate”) upon the
                    happening of any of the following events (each, a
                    “Dissociation Event”):{' '}
                  </p>
                  <ol className={classes.paragraphListNumbered} type='a'>
                    <li>
                      <p className={classes.paragraphText}>
                        The retirement or withdrawal of a Member in accordance
                        with Section <b>X.1</b>; or
                      </p>
                    </li>
                    <li>
                      <p className={classes.paragraphText}>
                        The Member ceasing to hold a Membership Interest.{' '}
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Wrongful Dissociation. </b> A Member’s dissociation is
                    wrongful only if the dissociation: (i) is in breach of an
                    express provision of this Agreement; or (ii) occurs in
                    violation of the Acts.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Transferability. </b> All interests provided for within
                    this Agreement and afforded to Members of the DAO, including
                    the ability to participate in the activities of the DAO and
                    assert Governance Rights, are transferred to the subsequent
                    holder in Control and Possession of the private keys of an
                    Ethereum Account who purchases or otherwise obtains a
                    Membership NFT. The previous Ethereum Account in possession
                    of the Membership NFT, provided the Ethereum Account holds
                    no other Membership NFT, forfeits all rights and benefits
                    upon transfer, and will no longer be able to interact with
                    the connected Web 3 applications used by the DAO for
                    governance purposes.{' '}
                  </p>
                </li>
              </ol>
              <p className={classes.operatingCenteredParagraph}>ARTICLE XI</p>
              <p className={classes.operatingCenteredParagraph}>
                ADMISSION OF ADDITIONAL MEMBERS
              </p>
              <p className={classes.paragraphText}>
                Any new Members may only join by acquiring a Membership NFT.
              </p>
              <p className={classes.operatingCenteredParagraph}>ARTICLE XII</p>
              <p className={classes.operatingCenteredParagraph}>DISSOLUTION</p>
              <p className={classes.paragraphText}>
                The DAO shall be dissolved and wound up (in accordance with Part
                V of the Non-Profit Entities Act, 2020).{' '}
              </p>
              <p className={classes.operatingCenteredParagraph}>ARTICLE XIII</p>
              <p className={classes.operatingCenteredParagraph}>AMENDMENT</p>
              <p className={classes.paragraphText}>
                The Agreement may be amended or modified from time to time
                exclusively via a written instrument adopted by the Members and
                executed by the majority of Membership Interests.
              </p>
              <p className={classes.operatingCenteredParagraph}>ARTICLE XIV</p>
              <p className={classes.operatingCenteredParagraph}>
                MISCELLANEOUS PROVISIONS
              </p>
              <ol className={classes.paragraphListNumbered}>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Entire Agreement. </b> This Agreement represents the
                    entire agreement among all the Members, Initiating Members,
                    the Founding Member, and the DAO.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Governing Law. </b> This Agreement is governed by and
                    shall be construed in accordance with the laws of the
                    Republic of the Marshall Islands without regard to the
                    conflicts of law principles thereof.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Separability of Provisions. </b> Each provision of this
                    Agreement shall be considered separable, and if for any
                    reason any provision or provisions herein are determined to
                    be invalid, unenforceable or illegal under any existing or
                    future law, such invalidity, unenforceability or illegality
                    shall not impair the operation of or affect those portions
                    of this Agreement that are valid, enforceable and legal.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Indemnification. </b> Subject to such standards and
                    restrictions, if any, as are set forth in this Agreement,
                    the DAO shall have the power to, indemnify and hold harmless
                    any Initiating Member, Founding Member, Member, or other
                    person from and against any and all claims and demands
                    whatsoever.{' '}
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Indemnity for Acting in Official Capacities. </b>
                    So long as any Member(s), Initiating Member(s), or Founding
                    Member is acting solely within the scope of authority as
                    granted by a DAO Official Action or this Agreement (each a
                    "Authorized Member" and collectively the “Authorized
                    Members”), the DAO shall indemnify such Authorized Member(s)
                    from and against any and all losses, claims, damages,
                    liabilities, expenses (including legal fees and expenses),
                    judgments, fines, settlements and other amounts arising from
                    any and all claims, demands, actions, suits or proceedings,
                    civil, criminal, administrative or investigative, in which
                    the Authorized Member may be involved, or threatened to be
                    involved, as a party or otherwise by reason of the fact that
                    such Authorized Member is acting pursuant to a DAO Official
                    Action, which relates to or arises out of the DAO, its
                    assets, commercial activities, or affairs, if in each of the
                    foregoing cases. Notwithstanding the forgoing set forth in
                    this Section <b>XIV.5</b> indemnity will be limited to the
                    extent not caused by the gross negligence or willful
                    misconduct of such Authorized Member(s), for which, such
                    Authorized Member(s) remain solely liable and will expressly
                    indemnify the DAO in the event of such gross negligence or
                    willful misconduct, in addition to any action outside the
                    scope of a DAO Official Action.
                  </p>
                </li>
                <li>
                  <p className={classes.paragraphText}>
                    <b>Taxation Status. </b> The DAO has elected to be taxed as
                    a non-profit limited liability company pursuant to the
                    Internal Revenue Code. The Initiating Members will have the
                    power to select a representative to handle and make filings
                    related to all tax matters (the “Tax Representative”).{' '}
                  </p>
                </li>
              </ol>
            </div>
          </Col>
        </Section>
      </Container>
    </>
  );
};
export default Terms;
