import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OnDisplayAuctionState {
  lastAuctionBeanId: number | undefined;
  onDisplayAuctionBeanId: number | undefined;
}

const initialState: OnDisplayAuctionState = {
  lastAuctionBeanId: undefined,
  onDisplayAuctionBeanId: undefined,
};

const onDisplayAuction = createSlice({
  name: 'onDisplayAuction',
  initialState: initialState,
  reducers: {
    setLastAuctionBeanId: (state, action: PayloadAction<number>) => {
      state.lastAuctionBeanId = action.payload;
    },
    setOnDisplayAuctionBeanId: (state, action: PayloadAction<number>) => {
      state.onDisplayAuctionBeanId = action.payload;
    },
    setPrevOnDisplayAuctionBeanId: (state) => {
      if (!state.onDisplayAuctionBeanId) return;
      if (state.onDisplayAuctionBeanId === 0) return;
      state.onDisplayAuctionBeanId = state.onDisplayAuctionBeanId - 1;
    },
    setNextOnDisplayAuctionBeanId: (state) => {
      if (state.onDisplayAuctionBeanId === undefined) return;
      if (state.lastAuctionBeanId === state.onDisplayAuctionBeanId) return;
      state.onDisplayAuctionBeanId = state.onDisplayAuctionBeanId + 1;
    },
  },
});

export const {
  setLastAuctionBeanId,
  setOnDisplayAuctionBeanId,
  setPrevOnDisplayAuctionBeanId,
  setNextOnDisplayAuctionBeanId,
} = onDisplayAuction.actions;

export default onDisplayAuction.reducer;
