import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import account from './state/slices/account';
import application from './state/slices/application';
import logs from './state/slices/logs';
import auction, {
  reduxSafeAuction,
  reduxSafeNewAuction,
  reduxSafeBid,
  setActiveAuction,
  setAuctionExtended,
  setAuctionSettled,
  setFullAuction,
} from './state/slices/auction';
import onDisplayAuction, {
  setLastAuctionBeanId,
  setOnDisplayAuctionBeanId,
} from './state/slices/onDisplayAuction';
import { ApolloProvider, useQuery } from '@apollo/client';
import { clientFactory, latestAuctionsQuery } from './wrappers/subgraph';
import { useEffect } from 'react';
import pastAuctions, { addPastAuctions } from './state/slices/pastAuctions';
import LogsUpdater from './state/updaters/logs';
import { CHAIN_CONFIG } from './config';
import { useAppDispatch, useAppSelector } from './hooks';
import { appendBid } from './state/slices/auction';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { Provider } from 'react-redux';
import { beanPath } from './utils/history';
import { push } from 'redux-first-history';
import {
  getBlock,
  getContractEvents,
  readContract,
  watchContractEvent,
} from 'viem/actions';
import WalletProvider, { publicWsClient } from './providers/walletProvider';
import { beansAuctionHouseConfig } from './generated/wagmiGenerated';
import { Address, Log } from 'viem';
import { Auction } from './wrappers/beansAuction';
import { createReduxHistoryContext } from 'redux-first-history';
import { configureStore } from '@reduxjs/toolkit';
import { HistoryRouter as Router } from 'redux-first-history/rr6';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({
    history: createBrowserHistory(),
  });

export const store = configureStore({
  reducer: combineReducers({
    router: routerReducer,
    account,
    application,
    auction,
    logs,
    pastAuctions,
    onDisplayAuction,
  }),
  middleware: (getDefaultMiddleware: any) =>
    getDefaultMiddleware().concat(routerMiddleware),
});

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const client = clientFactory(CHAIN_CONFIG.subgraphApiUrl);

const Updaters = () => {
  return (
    <>
      <LogsUpdater />
    </>
  );
};

const BLOCKS_PER_DAY = 43200n;

const ChainSubscriber: React.FC = () => {
  const dispatch = useAppDispatch();

  const loadState = async () => {
    const processBidFilter = async (
      beanId: bigint,
      sender: Address,
      value: bigint,
      extended: boolean,
      log: Log
    ) => {
      const block = await getBlock(publicWsClient, {
        blockNumber: log.blockNumber ?? undefined,
      });
      dispatch(
        appendBid(
          reduxSafeBid({
            beanId,
            sender,
            value,
            extended,
            transactionHash: log.transactionHash ?? '0x',
            timestamp: block.timestamp,
          })
        )
      );
    };
    const processAuctionCreated = (
      beanId: bigint,
      startTime: bigint,
      endTime: bigint
    ) => {
      dispatch(
        setActiveAuction(
          reduxSafeNewAuction({
            beanId,
            startTime,
            endTime,
            settled: false,
          })
        )
      );
      const beanIdNumber = Number(beanId);
      dispatch(push(beanPath(beanIdNumber)) as any);
      dispatch(setOnDisplayAuctionBeanId(beanIdNumber));
      dispatch(setLastAuctionBeanId(beanIdNumber));
    };
    const processAuctionExtended = (beanId: bigint, endTime: bigint) => {
      dispatch(setAuctionExtended({ beanId, endTime }));
    };
    const processAuctionSettled = (
      beanId: bigint,
      winner: Address,
      amount: bigint
    ) => {
      dispatch(setAuctionSettled({ beanId, amount, winner }));
    };

    // Fetch the current auction
    const currentAuctionData = await readContract(publicWsClient, {
      ...beansAuctionHouseConfig,
      functionName: 'auction',
    });
    const currentAuction: Auction = {
      beanId: currentAuctionData[0],
      amount: currentAuctionData[1],
      startTime: currentAuctionData[2],
      endTime: currentAuctionData[3],
      bidder: currentAuctionData[4],
      settled: currentAuctionData[5],
    };
    dispatch(setFullAuction(reduxSafeAuction(currentAuction)));
    dispatch(setLastAuctionBeanId(Number(currentAuction.beanId)));

    // Fetch the previous 24hours of  bids
    const currentBlock = await getBlock(publicWsClient);
    const previousBids = await getContractEvents(publicWsClient, {
      ...beansAuctionHouseConfig,
      eventName: 'AuctionBid',
      fromBlock: currentBlock.number - BLOCKS_PER_DAY * 4n, // Hack to look back 4 days if it was sitting not settled
      toBlock: currentBlock.number,
    });
    for (let log of previousBids) {
      processBidFilter(
        log.args.beanId!,
        log.args.sender!,
        log.args.value!,
        log.args.extended!,
        log
      );
    }

    watchContractEvent(publicWsClient, {
      ...beansAuctionHouseConfig,
      eventName: 'AuctionBid',
      onLogs: (logs) =>
        logs.forEach((log) => {
          processBidFilter(
            log.args.beanId!,
            log.args.sender!,
            log.args.value!,
            log.args.extended!,
            log
          );
        }),
    });

    watchContractEvent(publicWsClient, {
      ...beansAuctionHouseConfig,
      eventName: 'AuctionCreated',
      onLogs: (logs) =>
        logs.forEach((log) => {
          processAuctionCreated(
            log.args.beanId!,
            log.args.startTime!,
            log.args.endTime!
          );
        }),
    });

    watchContractEvent(publicWsClient, {
      ...beansAuctionHouseConfig,
      eventName: 'AuctionExtended',
      onLogs: (logs) =>
        logs.forEach((log) => {
          processAuctionExtended(log.args.beanId!, log.args.endTime!);
        }),
    });

    watchContractEvent(publicWsClient, {
      ...beansAuctionHouseConfig,
      eventName: 'AuctionSettled',
      onLogs: (logs) =>
        logs.forEach((log) => {
          processAuctionSettled(
            log.args.beanId!,
            log.args.winner!,
            log.args.amount!
          );
        }),
    });
  };
  loadState();

  return <></>;
};

const PastAuctions: React.FC = () => {
  const latestAuctionId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionBeanId
  );
  const { data } = useQuery(latestAuctionsQuery());
  const dispatch = useAppDispatch();

  useEffect(() => {
    data && dispatch(addPastAuctions({ data }));
  }, [data, latestAuctionId, dispatch]);

  return <></>;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <Router history={history}>
      <WalletProvider>
        <ChainSubscriber />
        <React.StrictMode>
          <ApolloProvider client={client}>
            <PastAuctions />
            <App />
            <Updaters />
          </ApolloProvider>
        </React.StrictMode>
      </WalletProvider>
    </Router>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
