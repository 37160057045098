import { Button, Modal, Spinner } from 'react-bootstrap';
import classes from './VoteModal.module.css';
import { Vote } from '../../wrappers/beansDao';
import clsx from 'clsx';
import RequireCorrectChainButtonContainer from '../RequireCorrectChainButtonContainer';

interface VoteModalProps {
  show: boolean;
  onHide: () => void;
  onVote: () => void;
  isLoading: boolean;
  proposalId: bigint | undefined;
  availableVotes: number | undefined;
  vote: Vote | undefined;
}

const voteActionText = (
  vote: Vote | undefined,
  proposalId: bigint | undefined
) => {
  switch (vote) {
    case Vote.FOR:
      return `Vote For Proposal ${proposalId}`;
    case Vote.AGAINST:
      return `Vote Against Proposal ${proposalId}`;
    case Vote.ABSTAIN:
      return `Vote to Abstain on Proposal ${proposalId}`;
  }
};

const VoteModal = ({
  show,
  onHide,
  onVote,
  proposalId,
  availableVotes,
  vote,
  isLoading,
}: VoteModalProps) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={classes.voteModal}
      centered
    >
      <Modal.Header closeButton className={classes.modalHeader}>
        <Modal.Title className={classes.modalTitleWrapper}>
          {voteActionText(vote, proposalId)}
        </Modal.Title>
      </Modal.Header>
      {/* className={clsx(classes.wrapper, classes.container, classes.section)} */}
      <Modal.Body
        className={clsx(classes.modalBody, 'd-grid gap-2 text-center')}
      >
        <p className={classes.voteModalText}>
          {availableVotes &&
            `${availableVotes} ${availableVotes > 1 ? 'Votes' : 'Vote'}`}{' '}
          Available
        </p>
        <RequireCorrectChainButtonContainer>
          <Button onClick={onVote} className={classes.voteButton}>
            {isLoading ? (
              <Spinner
                animation='border'
                className={classes.spinner}
                size='sm'
              />
            ) : (
              voteActionText(vote, proposalId)
            )}
          </Button>
        </RequireCorrectChainButtonContainer>
      </Modal.Body>
    </Modal>
  );
};
export default VoteModal;
