import { ChangeEvent, useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { useStepProgress, Step, StepProgressBar } from 'react-stepperz';
import {
  buildEtherscanAddressLink,
  buildEtherscanApiQuery,
} from '../../utils/etherscan';
import { ProposalTransaction } from '../../wrappers/beansDao';
import classes from './ProposalTransactionFormModal.module.css';
import 'bs-custom-file-input';
import 'react-stepperz/dist/index.css';
import clsx from 'clsx';
import {
  Abi,
  AbiFunction,
  Address,
  encodeAbiParameters,
  getAbiItem,
  isAddress,
  parseEther,
  toFunctionSignature,
} from 'viem';
import { useMemo } from 'react';

interface ProposalTransactionFormModalProps {
  show: boolean;
  onHide: () => void;
  onProposalTransactionAdded: (transaction: ProposalTransaction) => void;
}

const ProposalTransactionFormModal = ({
  show,
  onHide,
  onProposalTransactionAdded,
}: ProposalTransactionFormModalProps) => {
  const [address, setAddress] = useState('');
  const [abi, setABI] = useState<Abi>();
  const [value, setValue] = useState('');
  const [func, setFunction] = useState('');
  const [args, setArguments] = useState<string[]>([]);

  const [isABIUploadValid, setABIUploadValid] = useState<boolean>();
  const [abiFileName, setABIFileName] = useState<string | undefined>('');

  const abiFunctions: AbiFunction[] | undefined = useMemo(() => {
    return abi
      ? (abi.filter(
          (entry) => entry.type === 'function'
        ) as any as AbiFunction[])
      : undefined;
  }, [abi]);

  const abiFunction: AbiFunction | undefined = useMemo(() => {
    return abiFunctions && func
      ? getAbiItem({ abi: abiFunctions, name: func })
      : undefined;
  }, [abiFunctions, func]);

  const addressValidator = (s: string) => {
    if (!isAddress(s)) {
      return false;
    }
    // To avoid blocking stepper progress, do not `await`
    populateABIIfExists(s);
    return true;
  };

  const valueValidator = (v: string) => !v || !isNaN(parseFloat(v));

  const argumentsValidator = (a: string[]) => {
    if (!func) {
      return true;
    }

    try {
      if (abiFunction) {
        encodeAbiParameters(abiFunction.inputs, args); // Will throw here if invalid
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  };

  const setArgument = (index: number, value: string) => {
    const values = [...args];
    values[index] = value;
    setArguments(values);
  };

  let abiErrorTimeout: NodeJS.Timeout;
  const setABIInvalid = () => {
    setABIUploadValid(false);
    setABIFileName(undefined);
    abiErrorTimeout = setTimeout(() => {
      setABIUploadValid(undefined);
    }, 5_000);
  };

  const validateAndSetABI = (file: File | undefined) => {
    if (abiErrorTimeout) {
      clearTimeout(abiErrorTimeout);
    }
    if (!file) {
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const abi = e?.target?.result?.toString() ?? '';
        setABI(JSON.parse(abi) as Abi);
        setABIUploadValid(true);
        setABIFileName(file.name);
      } catch (e) {
        setABIInvalid();
      }
    };
    reader.readAsText(file);
  };

  const getContractInformation = async (address: string) => {
    const response = await fetch(buildEtherscanApiQuery(address));
    const json = await response.json();
    return json?.result?.[0];
  };

  const getABI = async (address: string) => {
    let info = await getContractInformation(address);
    if (info?.Proxy === '1' && isAddress(info?.Implementation)) {
      info = await getContractInformation(info.Implementation);
    }
    return info.ABI;
  };

  const populateABIIfExists = async (address: string) => {
    if (abiErrorTimeout) {
      clearTimeout(abiErrorTimeout);
    }

    try {
      const result = await getABI(address);
      setABI(JSON.parse(result) as Abi);
      setABIUploadValid(true);
      setABIFileName('etherscan-abi-download.json');
    } catch {
      setABIUploadValid(undefined);
      setABIFileName(undefined);
    }
  };

  const stepForwardOrCallback = () => {
    if (currentStep !== steps.length - 1) {
      return stepForward();
    }
    onProposalTransactionAdded({
      address: address as Address,
      value: value ? parseEther(value) : 0n,
      signature: abiFunction ? toFunctionSignature(abiFunction) : '',
      calldata: abiFunction
        ? encodeAbiParameters(abiFunction.inputs, args)
        : '0x',
    });
    clearState();
  };

  const steps = [
    {
      label: 'Address',
      name: 'address',
      validator: () => addressValidator(address),
    },
    {
      label: 'Value',
      name: 'value',
      validator: () => valueValidator(value),
    },
    {
      label: 'Function',
      name: 'function',
    },
    {
      label: 'Arguments',
      name: 'arguments',
      validator: () => argumentsValidator(args),
    },
    {
      label: 'Summary',
      name: 'summary',
    },
  ];

  const { stepForward, stepBackwards, currentStep } = useStepProgress({
    steps,
    startingStep: 0,
  });

  const clearState = () => {
    setAddress('');
    setABI(undefined);
    setValue('');
    setFunction('');
    setArguments([]);
    setABIUploadValid(undefined);
    setABIFileName(undefined);

    for (let i = currentStep; i > 0; i--) {
      stepBackwards();
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide();
        // clearState();
      }}
      dialogClassName={classes.transactionFormModal}
      centered
    >
      <Modal.Header className={classes.modalHeader} closeButton>
        <Modal.Title>ADD A PROPOSAL TRANSACTION</Modal.Title>
      </Modal.Header>
      <Modal.Body className={classes.modalBody}>
        <StepProgressBar className={classes.stepProgressBar} steps={steps} />
        <Step step={0}>
          <label className={classes.formLabel} htmlFor='callee-address'>
            ADDRESS (CALLEE OR RECIPIENT)
          </label>
          <FormControl
            className={classes.proposalInput}
            value={address}
            type='text'
            id='callee-address'
            onChange={(e) => setAddress(e.target.value)}
          />
        </Step>
        <Step step={1}>
          <label className={classes.formLabel} htmlFor='eth-value'>
            VALUE IN ETH (OPTIONAL)
          </label>
          <FormControl
            className={classes.proposalInput}
            value={value}
            id='eth-value'
            onChange={(e) => setValue(e.target.value)}
          />
        </Step>
        <Step step={2}>
          <label className={classes.formLabel} htmlFor='function'>
            FUNCTION (OPTIONAL)
          </label>
          <FormControl
            className={classes.proposalInput}
            value={func}
            as='select'
            id='function'
            onChange={(e) => setFunction(e.target.value)}
          >
            <option className='text-muted'>SELECT CONTRACT FUNCTION</option>
            {abiFunctions &&
              abiFunctions.map((func) => (
                <option value={func.name}>{func.name}</option>
              ))}
          </FormControl>
          <label
            className={classes.formLabel}
            style={{ marginTop: '1rem' }}
            htmlFor='import-abi'
          >
            {abiFileName === 'etherscan-abi-download.json'
              ? abiFileName
              : 'ABI'}
          </label>
          <Form.Control
            className={classes.proposalInput}
            type='file'
            id='import-abi'
            accept='application/JSON'
            isValid={isABIUploadValid}
            isInvalid={isABIUploadValid === false}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              validateAndSetABI(e.target.files?.[0])
            }
          />
        </Step>
        <Step step={3}>
          {abiFunction?.inputs?.length ? (
            <FormGroup as={Row}>
              {abiFunction.inputs.map((input, i) => (
                <>
                  <FormLabel className={classes.formLabel} column sm='3'>
                    {input.name}
                  </FormLabel>
                  <Col sm='9'>
                    <InputGroup className='mb-2'>
                      <InputGroup.Text
                        className={classes.inputGroupTextArguments}
                      >
                        {input.type}
                      </InputGroup.Text>
                      <FormControl
                        className={classes.proposalInputArguments}
                        value={args[i] ?? ''}
                        onChange={(e) => setArgument(i, e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </>
              ))}
            </FormGroup>
          ) : (
            <span className={classes.formLabel}>NO ARGUMENTS REQUIRED</span>
          )}
        </Step>
        <Step step={4}>
          <Row>
            <Col sm='3'>
              <b className={classes.formLabel}>ADDRESS</b>
            </Col>
            <Col sm='9' className={clsx(classes.formValue, 'text-break')}>
              <a
                href={buildEtherscanAddressLink(address)}
                target='_blank'
                rel='noreferrer'
              >
                {address}
              </a>
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <b className={classes.formLabel}>VALUE</b>
            </Col>
            <Col className={classes.formValue} sm='9'>
              {value ? `${value} ETH` : 'None'}
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <b className={classes.formLabel}>FUNCTION</b>
            </Col>
            <Col sm='9' className={clsx(classes.formValue, 'text-break')}>
              {func || 'None'}
            </Col>
          </Row>
          <Row>
            <Col sm='3'>
              <b className={classes.formLabel}>ARGUMENTS</b>
            </Col>
            <Col sm='9'>
              <hr />
            </Col>
            <Col sm='9'>{abiFunction?.inputs?.length ? '' : 'None'}</Col>
          </Row>
          {abiFunction?.inputs.map((input, i) => (
            <Row key={i}>
              <Col sm='3' className={classes.functionName}>
                {i + 1}. {input.name}
              </Col>
              <Col sm='9' className='text-break'>
                {args[i]}
              </Col>
            </Row>
          ))}
        </Step>
        <div className='d-flex justify-content-between align-items-center pt-3'>
          <Button
            className={classes.stepButton}
            onClick={stepBackwards}
            disabled={currentStep === 0}
          >
            BACK
          </Button>
          <Button
            onClick={stepForwardOrCallback}
            className={classes.stepButton}
          >
            {currentStep !== steps.length - 1 ? 'NEXT' : 'ADD TRANSACTION'}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default ProposalTransactionFormModal;
