import { Button, Spinner } from 'react-bootstrap';
import classes from './CreateProposalButton.module.css';

const CreateProposalButton = ({
  className,
  isLoading,
  proposalThreshold,
  hasActiveOrPendingProposal,
  hasEnoughVote,
  isFormInvalid,
  handleCreateProposal,
}: {
  className?: string;
  isLoading: boolean;
  proposalThreshold?: bigint;
  hasActiveOrPendingProposal: boolean;
  hasEnoughVote: boolean;
  isFormInvalid: boolean;
  handleCreateProposal: () => void;
}) => {
  const buttonText = () => {
    if (hasActiveOrPendingProposal) {
      return 'YOU ALREADY HAVE AN ACTIVE OR PENDING PROPOSAL';
    }
    if (!hasEnoughVote) {
      if (proposalThreshold) {
        return `YOU MUST HAVE ${proposalThreshold + 1n} VOTES TO SUBMIT A PROPOSAL`;
      }
      return "YOU DON'T HAVE ENOUGH VOTES TO SUBMIT A PROPOSAL";
    }
    if (isFormInvalid) {
      return 'ADD A TRANSACTION AND FILL OUT THE FORM TO CREATE A PROPOSAL';
    }
    return 'CLICK TO CREATE PROPOSAL';
  };

  return (
    <div className='d-grid gap-2'>
      <span className={classes.warningText}> {buttonText()} </span>
      <Button
        className={className}
        disabled={isFormInvalid || hasActiveOrPendingProposal || !hasEnoughVote}
        onClick={handleCreateProposal}
      >
        {isLoading ? (
          <Spinner className={classes.spinner} animation='border' size='sm' />
        ) : (
          'CREATE PROPOSAL'
        )}
      </Button>
    </div>
  );
};
export default CreateProposalButton;
