import { useAppSelector } from '../hooks';
import {
  generateEmptyBeanderAuction,
  getBeanType,
  generateMigratedBeanAuction,
  getBeanMetadata,
  findPreviousAuctionDate,
} from '../utils/beanderBean';
import { Bid, BidEvent } from '../utils/types';
import { Auction } from './beansAuction';

const deserializeAuction = (reduxSafeAuction: Auction): Auction => {
  return {
    amount: reduxSafeAuction.amount,
    bidder: reduxSafeAuction.bidder,
    startTime: reduxSafeAuction.startTime,
    endTime: reduxSafeAuction.endTime,
    beanId: reduxSafeAuction.beanId,
    settled: false,
  };
};

const deserializeBid = (reduxSafeBid: BidEvent): Bid => {
  return {
    beanId: reduxSafeBid.beanId,
    sender: reduxSafeBid.sender,
    value: reduxSafeBid.value,
    extended: reduxSafeBid.extended,
    transactionHash: reduxSafeBid.transactionHash,
    timestamp: reduxSafeBid.timestamp,
  };
};
const deserializeBids = (reduxSafeBids: BidEvent[]): Bid[] => {
  return reduxSafeBids
    .map((bid) => deserializeBid(bid))
    .sort((a: Bid, b: Bid) => {
      return Number(b.timestamp - a.timestamp);
    });
};

const useOnDisplayAuction = (): Auction | undefined => {
  const lastAuctionBeanId = useAppSelector(
    (state) => state.auction.activeAuction?.beanId
  );
  const onDisplayAuctionBeanId = useAppSelector(
    (state) => state.onDisplayAuction.onDisplayAuctionBeanId
  );
  const currentAuction = useAppSelector((state) => state.auction.activeAuction);
  const pastAuctions = useAppSelector(
    (state) => state.pastAuctions.pastAuctions
  );

  if (
    onDisplayAuctionBeanId === undefined ||
    lastAuctionBeanId === undefined ||
    currentAuction === undefined ||
    !pastAuctions
  )
    return undefined;

  // current auction
  if (BigInt(onDisplayAuctionBeanId) === lastAuctionBeanId) {
    return currentAuction;
  } else {
    // beander auction
    const beanType = getBeanType(BigInt(onDisplayAuctionBeanId));
    if (beanType === 1 || beanType === 2) {
      let emptyBeanderAuction = generateEmptyBeanderAuction(
        BigInt(onDisplayAuctionBeanId),
        pastAuctions
      );

      if (emptyBeanderAuction?.endTime === 0n && onDisplayAuctionBeanId < 17) {
        const beanMetadata = getBeanMetadata(BigInt(onDisplayAuctionBeanId));
        const date = beanMetadata?.date;
        emptyBeanderAuction.endTime = date ?? 0n;
        emptyBeanderAuction.startTime = date ?? 0n;
      } else if (emptyBeanderAuction?.endTime === 0n) {
        let prevAuctionDate = findPreviousAuctionDate(
          BigInt(onDisplayAuctionBeanId),
          pastAuctions
        );
        if (prevAuctionDate) {
          emptyBeanderAuction.endTime = prevAuctionDate;
          emptyBeanderAuction.startTime = prevAuctionDate;
        }
      }

      return deserializeAuction(emptyBeanderAuction);
    } else if (beanType === 3) {
      var metaData = getBeanMetadata(BigInt(onDisplayAuctionBeanId));
      const date = metaData?.date ?? 0n;
      const address = metaData?.address ?? '0x';
      const amount = metaData?.amount ?? 0n;
      const emptyBeanderAuction = generateMigratedBeanAuction(
        BigInt(onDisplayAuctionBeanId),
        amount,
        address,
        date,
        pastAuctions
      );

      return emptyBeanderAuction;
    } else {
      // past auction
      const reduxSafeAuction: Auction | undefined = pastAuctions.find(
        (auction) => {
          const beanId = auction.activeAuction && auction.activeAuction.beanId;
          return beanId && Number(beanId) === onDisplayAuctionBeanId;
        }
      )?.activeAuction;

      return reduxSafeAuction ? reduxSafeAuction : undefined;
    }
  }
};

export const useAuctionBids = (auctionBeanId: bigint): Bid[] | undefined => {
  const lastAuctionBeanId = useAppSelector(
    (state) => state.onDisplayAuction.lastAuctionBeanId
  );
  const lastAuctionBids = useAppSelector((state) => state.auction.bids);
  const pastAuctions = useAppSelector(
    (state) => state.pastAuctions.pastAuctions
  );

  // auction requested is active auction
  if (lastAuctionBeanId === Number(auctionBeanId.toString())) {
    return deserializeBids(lastAuctionBids);
  } else {
    // find bids for past auction requested
    const bidEvents: BidEvent[] | undefined = pastAuctions.find((auction) => {
      const beanId = auction.activeAuction && auction.activeAuction.beanId;
      return beanId && beanId === auctionBeanId;
    })?.bids;

    return bidEvents && deserializeBids(bidEvents);
  }
};

export default useOnDisplayAuction;
